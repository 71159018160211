import React, {useRef, useState} from 'react';
import {SketchPicker} from 'react-color';
import {InputField} from '../inputField';
import * as S from './styled';
import {useOutsideClick} from '../../../hooks';

export const ColorPicker = props => {
  const {color, onChange, withInput} = props;
  const [showColorModal, setShowColorModal] = useState(false);
  const colorRef = useRef(null);
  useOutsideClick(colorRef, setShowColorModal);
  return (
    <S.ColorPickerWrapper>
      <S.ColorPickerStyled
        onClick={() => setShowColorModal(true)}
        color={color || 'white'}
      />
      {showColorModal && (
        <S.ColorPickerModalStyled ref={colorRef}>
          <SketchPicker
            color={color}
            onChangeComplete={(value, event) => {
              onChange(value.hex);
              if (event?.type === 'click') {
                setShowColorModal(false);
              }
            }}
          />
        </S.ColorPickerModalStyled>
      )}
      {withInput && (
        <InputField
          value={color}
          onChangeHandler={e => onChange(e.target.value)}
        />
      )}
    </S.ColorPickerWrapper>
  );
};
