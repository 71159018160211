import {FunctionComponent, useState} from 'react';
import {SwitchTypes} from './services';
import * as S from './styled';

const Switch: FunctionComponent<SwitchTypes> = ({
  isChecked,
  onChange,
  fieldName,
}) => {
  const [isCheck, setIsCheck] = useState(isChecked);

  return (
    <S.ButtonWrapper>
      <S.ToggleButtonCover>
        <S.ButtonCover>
          <S.Button $active={isCheck}>
            <S.Checkbox
              type="checkbox"
              name={fieldName}
              checked={isCheck}
              onChange={() => {
                onChange(isCheck ? '' : !isChecked);
                setIsCheck(prevState => !prevState);
              }}
            />
            <S.Knobs>{isCheck ? 'Yes' : 'No'}</S.Knobs>
          </S.Button>
        </S.ButtonCover>
      </S.ToggleButtonCover>
    </S.ButtonWrapper>
  );
};

export default Switch;
