import React, {useState} from 'react';
import AutoComplete from './AutoComplete/AutoComplete';
import * as S from './styled';

export const SearchField = props => {
  const {
    onClick,
    onChange,
    onKeyPress,
    clicked,
    startingSearch,
    searchTargetValue,
    searchRef,
    searchResults,
    onChangeText,
    onSubmitEditing,
    style,
    noSelectStatuses,
    onIconClick,
    onSearchResultClick,
    onBlur,
    value,
    placeholder,
  } = props;
  const [searchVal, setSearchVal] = useState(searchTargetValue);
  const autoComplete =
    !clicked && startingSearch && searchResults ? (
      <AutoComplete
        data={searchResults}
        show={startingSearch}
        searchTargetValue={searchTargetValue}
        onSearchResultClick={onSearchResultClick}
      />
    ) : null;

  return (
    <S.SearchWrapper>
      <div className="search-field-wrapper" ref={searchRef} style={style}>
        <input
          placeholder={placeholder || null}
          style={noSelectStatuses && {width: '100%'}}
          onChange={event => {
            if (onChange) onChange(event);
            if (onChangeText) onChangeText(event.target.value);
          }}
          onClick={() => {
            if (onClick) onClick();
          }}
          onKeyPress={event => {
            if (onKeyPress) onKeyPress(event);
            if (onSubmitEditing) {
              if (event.key === 'Enter') {
                onSubmitEditing(event.target.value);
                setSearchVal(event.target.value);
              }
            }
            if (onBlur) {
              if (event.key === 'Enter') onBlur(event);
              setSearchVal(event.target.value);
            }
          }}
          onBlur={event => {
            if (searchVal !== event.target.value) {
              onBlur(event);
              setSearchVal(event.target.value);
            }
          }}
          type="text"
          autoComplete="off"
          name="search"
          value={value}
        />
        <span className="icon-search-wr" onClick={() => onIconClick()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="19.975"
            viewBox="0 0 20 19.975">
            <path
              id="Path_541"
              d="M357.407-55.505,353.413-59.5a8.939,8.939,0,0,0,2.161-5.829,8.992,8.992,0,0,0-8.982-8.982,8.992,8.992,0,0,0-8.982,8.982,8.993,8.993,0,0,0,8.982,8.982,8.936,8.936,0,0,0,5.848-2.179l3.994,3.994a.706.706,0,0,0,.974,0A.69.69,0,0,0,357.407-55.505Zm-18.42-9.823a7.613,7.613,0,0,1,7.6-7.6,7.613,7.613,0,0,1,7.6,7.6,7.613,7.613,0,0,1-7.6,7.6A7.613,7.613,0,0,1,338.987-65.329Zm7.6-5.7a5.7,5.7,0,0,0-5.7,5.7,5.7,5.7,0,0,0,5.7,5.7,5.7,5.7,0,0,0,5.7-5.7A5.7,5.7,0,0,0,346.591-71.025Zm0,11.048a5.358,5.358,0,0,1-5.352-5.352,5.358,5.358,0,0,1,5.352-5.352,5.358,5.358,0,0,1,5.352,5.352A5.358,5.358,0,0,1,346.591-59.977Z"
              transform="translate(-337.609 74.311)"
              fill={process.env.REACT_APP_THEME_PRIMARY_COLOR}
            />
          </svg>
        </span>
        {autoComplete}
      </div>
    </S.SearchWrapper>
  );
};
