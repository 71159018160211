import styled from 'styled-components';

export const Header = styled.header`
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  box-shadow: 0 3px 6px #00000029;
  background: ${props => props.theme.whiteColor};
  height: auto;
  padding: 4px 27px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const BackgroundIconUser = styled.div`
  background-color: rgb(48, 127, 235);
  width: 32px;
  height: 32px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-weight: 600;
`;

export const Close = styled.div`
  color: ${props => props.theme.primaryColor};
  transform: rotate(180deg);
  padding: 0 1%;
  position: relative;
  height: 100%;
  display: flex;
  padding-left: 31px;
  &:after {
    content: '';
    width: 1px;
    height: 219%;
    position: absolute;
    top: -12px;
    left: 0.4px;
    background-color: #00000029;
  }

  svg.icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`;

export const HeaderLogo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 20px;
  width: fit-content;
  img {
    object-fit: cover;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  h3 {
    font-weight: ${props => props.theme.fontWeightBold};
    margin: 0;
    padding: 0;
    font-size: 16px;
    color: ${props => props.theme.blackColor};
    cursor: pointer;
    display: ${props => props.$settingsActive && 'none'};
  }
`;

export const HeaderOptionBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  justify-content: flex-end;
  position: relative;
`;

export const HeaderSearchWrapper = styled.div`
  width: 100%;
  max-width: 510px;
  .search-field-wrapper {
    display: flex;
    align-items: center;
    max-width: 230px;
    width: 27vw;
    height: 28px;
    margin-right: 0;
    background: #f1f2f3;
    input {
      background: #f1f2f3;
      padding: 0;
      padding-left: ${props => props.theme.spaceLarge};
      height: 100%;
      width: 100%;
      ::placeholder {
        //padding-left: ${props => props.theme.spaceLarge};
      }
    }
    .icon-search-wr {
      top: 4px;
      right: 0;
      left: 10px;
      height: 18px;
      width: 22px;
      svg {
        height: 15px;
      }
    }
  }
`;

export const HeaderProfile = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
  margin-left: 30px;
  cursor: pointer;
  width: 100%;
  justify-content: space-between;
  img {
    border-radius: 100%;
    width: 32px;
    height: 32px;
  }
  h3 {
    font-size: 14px;
    font-weight: 600;
    color: ${props => props.theme.blackColor};
    padding: 0;
    margin: 0;
    white-space: nowrap;
  }
`;

export const HeaderMenuItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 0;
  padding-left: ${props => props.theme.spaceSmall};
  border-top: 1px solid #e1e3e5;
  color: ${props => props.theme.spaceSmall};
  cursor: pointer;
  column-gap: ${props => props.theme.spaceSmall};
  img {
    height: 15px;
  }
  h4 {
    margin: 0;
    padding: 0;
    font-size: ${props => props.theme.fontSizeExtraSmall};
    font-weight: ${props => props.theme.fontWeightSemiBold};
    color: ${props => props.theme.blackColor};
  }
`;

export const HeaderProfileMenu = styled.div`
  position: absolute;
  top: 54px;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid #e1e3e5;
  background: ${props => props.theme.backgroundColor};
  visibility: ${props => (props.$active ? 'visible' : 'hidden')};
  opacity: ${props => (props.$active ? 1 : 0)};
  min-width: 163px;
  height: auto;
  transition: 0.4s;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  ${HeaderMenuItem}:first-child {
    border-top: 0;
  }
`;

export const HeaderProfileEvents = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  column-gap: 18px;
  padding-left: ${props => props.theme.spaceMedium};
  align-items: center;

  svg {
    path {
      fill: ${props => props.theme.primaryColor};
    }
    fill: ${props => props.theme.primaryColor};
    cursor: pointer;
    transform: scale(0.8);
  }
`;

export const HeaderProfileNotificationItem = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${props => props.theme.spaceSmall};
  border-top: 1px solid #e1e3e5;
  padding: 10px;
  div:first-child {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: ${props => props.theme.primaryFontSize};
    color: ${props => props.theme.primaryColor};
    font-weight: ${props => props.theme.fontWeightSemiBold};
    span {
      color: #9e9ea6;
      font-size: ${props => props.theme.fontSizeExtraSmall};
      font-weight: ${props => props.theme.fontWeightLight};
    }
  }
`;

export const HeaderProfileNotification = styled.div`
  position: absolute;
  opacity: ${props => (props.$active ? 1 : 0)};
  visibility: ${props => (props.$active ? 'visible' : 'hidden')};
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
  transition: 0.4s;
  top: 44px;
  left: 0px;
  width: 240px;
  height: auto;
  background: ${props => props.theme.whiteColor};
  border: 1px solid #e1e3e5;
  border-radius: 4px;
  ${HeaderProfileNotificationItem}:first-child {
    border-top: none;
  }
`;
