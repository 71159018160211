import {createToken} from '../../../api/authApi';

export const retrieveToken = (data, onResult) => {
  const {email, password} = data;

  const authorization = {
    username: email,
    password,
  };
  createToken(authorization).then(response => {
    const {access_token, error, message} = response;
    if (access_token) {
      onResult(true);
    }
    if (error) {
      onResult(false);
      const errorMessage =
        error === 'invalid_grant' ? 'Invalid username or password' : message;
      onResult(false, errorMessage);
    }
  });
};
