import styled from 'styled-components';

export const Root = styled.div`
    .dialog-wr {
        z-index: 99;
    }

    .dialog-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
    }

    .dialog-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0 auto 30px;
    }

    .dialog-window {
        color: #6f6f6f;
        border-radius: 5px;
        background-color: #fff;
        -webkit-box-shadow: 0 0 14px -1px rgba(0, 0, 0, 0.43);
        -moz-box-shadow: 0 0 14px -1px rgba(0, 0, 0, 0.43);
        box-shadow: 0 0 14px -1px rgba(0, 0, 0, 0.43);
    }

    .dialog-titleText {
        font-size: 24px;
        font-weight: ${props => props.theme.fontWeightRegular};
        color: black;
    }

    .dialog-scrollableContent::-webkit-scrollbar {
        display: none;
    }

    .content {
        max-height: 700px;
    }

    .dialog-scrollableContent {
        overflow-y: auto;
    }

    .dialog-scrollableContent::-webkit-scrollbar {
        width: 3px;
    }

    .dialog-scrollableContent::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    .dialog-scrollableContent::-webkit-scrollbar-thumb {
        background-color: #e30b13;
        outline: #e30b13;
    }

    .dialog-cross {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 20px;
        right: 15px;
        cursor: pointer;
    }
    .dialog-cross:before {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 2px;
        height: 16px;
        transform: translate(-50%, -50%) rotate(-45deg);
        background-color: ${props => props.theme.primaryColor};
        content: '';
    }
    .dialog-cross:after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 2px;
        height: 16px;
        transform: translate(-50%, -50%) rotate(45deg);
        background-color: ${props => props.theme.primaryColor};
        content: '';
    }
    .dialog-title-container {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        padding: 15px 20px 15px 20px;
        position: relative;
        border: 1px solid ${props => props.theme.borderColor};
    }
`;
