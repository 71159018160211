import ApiV2 from '../ApiV2';

export const getTemplates = async (payload: any): Promise<any> => {
  const response = await ApiV2.cp.notification.templates.get(payload);
  return response?.data;
};

export const createTemplate = async (payload: any): Promise<any> => {
  const response = await ApiV2.cp.notification.templates.post(payload);
  return response?.data;
};

export const updateTemplate = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.notification.templates.patch(id, data);
  return response?.data;
};
export const getTemplate = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.notification.templates.get(id);
  return response?.data;
};

export const deleteTemplate = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.notification.templates.delete(id);
  return response?.data;
};
