import ApiV2 from '../ApiV2';
export const getBillingInvoices = async (payload: any): Promise<any> => {
  const response = await ApiV2.cp.billing.invoices.get(payload);
  return response?.data;
};
export const createBillingInvoice = async (payload: any): Promise<any> => {
  const response = await ApiV2.cp.billing.invoices.post(payload);
  return response?.data;
};
export const updateBillingInvoice = async (
  id: number,
  payload: any,
): Promise<any> => {
  const response = await ApiV2.cp.billing.invoices.patch(id, payload);
  return response?.data;
};
export const getBillingInvoice = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.billing.invoices.get(id);
  return response?.data;
};
export const deleteBillingInvoice = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.billing.invoices.delete(id);
  return response?.data;
};
export const finalizeInvoice = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.billing.invoices.finalize.post(id);
  return response?.data;
};
export const getPaymentMethods = async (payload: any): Promise<any> => {
  const response = await ApiV2.cp.billing.paymentMethods.get(payload);
  return response?.data;
};
export const getPaymentMethod = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.billing.paymentMethods.get(id);
  return response?.data;
};
export const updatePaymentMethod = async (
  id: number,
  payload: any,
): Promise<any> => {
  const response = await ApiV2.cp.billing.paymentMethods.patch(id, payload);
  return response?.data;
};
export const getPayments = async (payload: any): Promise<any> => {
  const response = await ApiV2.cp.billing.payments.get(payload);
  return response?.data;
};
export const getPayment = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.billing.payments.get(id);
  return response?.data;
};
export const getTransactions = async (payload: any): Promise<any> => {
  const response = await ApiV2.cp.billing.transactions.get(payload);
  return response?.data;
};
export const getTransaction = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.billing.transactions.get(id);
  return response?.data;
};
export const getInvoiceItemTypes = async (data: any): Promise<any> => {
  const response = await ApiV2.cp.billing.invoiceItemTypes.get(data);
  return response?.data;
};
export const getInvoiceItemType = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.billing.invoiceItemTypes.get(id);
  return response?.data;
};
export const createInvoiceItemType = async (data: any): Promise<any> => {
  const response = await ApiV2.cp.billing.invoiceItemTypes.post(data);
  return response?.data;
};
export const updateInvoiceItemType = async (
  id: number,
  data: any,
): Promise<any> => {
  const response = await ApiV2.cp.billing.invoiceItemTypes.patch(id, data);
  return response?.data;
};
export const deleteInvoiceItemType = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.billing.invoiceItemTypes.delete(id);
  return response?.data;
};
export const getAdditionals = async (data: any): Promise<any> => {
  const response = await ApiV2.cp.billing.additionals.get(data);
  return response?.data;
};
export const getAdditional = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.billing.additionals.get(id);
  return response?.data;
};
export const updateAdditional = async (id: number, data: any): Promise<any> => {
  const response = await ApiV2.cp.billing.additionals.patch(id, data);
  return response?.data;
};
export const deleteAdditional = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.billing.additionals.delete(id);
  return response?.data;
};
export const createAdditional = async (data: any): Promise<any> => {
  const response = await ApiV2.cp.billing.additionals.post(data);
  return response?.data;
};

export const getAdministrations = async (): Promise<any> => {
  const response = await ApiV2.cp.client.administrations.get();
  return response?.data;
};
export const importAdministration = async (data: any): Promise<any> => {
  const response = await ApiV2.cp.client.administrations.import.post(
    data || {},
  );
  return response?.data;
};
export const importCostCenters = async (data: any): Promise<any> => {
  const response = await ApiV2.cp.billing.costCenters.import.post(data || {});
  return response?.data;
};
export const getCostCenters = async (): Promise<any> => {
  const response = await ApiV2.cp.billing.costCenters.get();
  return response?.data;
};
