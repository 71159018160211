import styled from 'styled-components';

export const Root = styled.div`
  z-index: 98;
  padding: 10px 30px 10px 75px;
  background: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  border-radius: 42px 0 0 42px;
  font-size: 16px;
  color: #6f6f6f;
  height: auto;
  width: 100%;
  max-width: 550px;
  svg {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    font-size: 30px;
    line-height: 1;
  }
`;
