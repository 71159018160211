import {allParams} from '../constants/params';

export const getTemplateRequest = (settings: any, file: any) => {
  const properties = {
    width: settings?.width,
    height: settings.height,
  };

  const formData = new FormData();
  formData.append('file', file);
  formData.append('name', settings?.name);
  formData.append('type', 'canvas');
  // formData.append('shop_id', shopId);
  formData.append('properties', JSON.stringify(properties));
  // @ts-ignore
  formData.append('is_customizable', Number(settings?.isCustomizable));
  for (let i = 0; i < settings?.tags?.length; i++) {
    const tag = settings?.tags[i];
    formData.append(
      `tags[${i}][tag_id]`,
      JSON.stringify(tag?.tag_id || tag?.id),
    );
  }
  return formData;
};

export const getNewTemplateRequest = (settings: any) => {
  const properties = {
    width: settings?.width,
    height: settings.height,
  };
  const formData = new FormData();
  formData.append('name', settings?.name);
  formData.append('type', 'canvas');
  // formData.append('shop_id', shopId);
  formData.append('properties', JSON.stringify(properties));
  return formData;
};

export const getLayerRequest = (
  layer: {name: string; order: string},
  data: any,
) => {
  const formData = new FormData();
  formData.append('name', layer?.name);
  formData.append('order', layer?.order);
  formData.append('data', data);
  return formData;
};

export const getTextParameters = (key: string) => {
  let parametersArray: any[] = [];
  if (allParams?.length) {
    // @ts-ignore
    parametersArray = allParams.find((i: {name: string}) => i?.name === key)
      ?.values;
    //   .map((i: any) => {
    //   return {name: i, value: i};
    // });
  }

  return parametersArray;
};

export const initialTemplateSettings = () => ({
  name: `Template-${new Date().getTime()}`,
  width: 3779.52,
  height: 5669.29,
  tags: [],
  sizeUnit: 'cm',
  isCustomizable: false,
  useBackgroundImageSize: false,
});

export const getPreviewDataUrl = (
  ref: any,
  settings: {width: number; height: number},
  scale: number,
  pixelRatio: number,
) => {
  return ref.toDataURL({
    pixelRatio: pixelRatio,
    x: ref.getPosition().x * scale,
    y: ref.getPosition().y * scale,
    width: settings?.width * scale,
    height: settings?.height * scale,
  });
};
