import styled from 'styled-components';

export const Root = styled.div`
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px 0;
    border-top: 1px solid ${props => props.theme.borderColor};

    &-copyright {
      @include greyText;
      color: ${props => props.theme.footerTextColor};
      margin-bottom: 0;
      font-weight: bold;
    }
    &-linksWrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    &-linkWrapper {
      padding: 0 10px;
      border-right: 1px solid ${props => props.theme.primaryColor};

      &:last-child {
        border-right: none;
      }
    }

    &-link {
      display: block;
      text-decoration: none;
      color: ${props => props.theme.primaryColor};
      line-height: 20px;
      font-size: 14px;
      transition: all 0.3s;
      &:hover {
        color: ${props => props.theme.primaryColor};
        text-decoration: none;
      }
      &:active {
        outline: none;
      }
    }
  }
`;
