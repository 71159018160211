import translation from './translations/nl_NL.json';
// import login from '../domains/Login/locales/nl_NL.json';
// import crm from '../domains/CRM/locales/nl_NL.json';
// import event from '../domains/Events/locales/nl_NL.json';
// import eventV2 from '../domains/EventsV2/locales/nl_NL.json';
// import profile from '../domains/Profile/locales/nl_NL.json';
// import venues from '../domains/Venues/locales/nl_NL.json';
// import venuesV2 from '../domains/VenuesV2/locales/nl_NL.json';
// import products from '../domains/Products/locales/nl_NL.json';
// import shops from '../domains/Shops/locales/nl_NL.json';
// import scanner from '../domains/Scanner/locales/nl_NL.json';
// import finance from '../domains/Finance/locales/nl_NL.json';
// import support from '../domains/Support/locales/nl_NL.json';
// import common from '../components/locales/nl_NL.json';

export default {
  translation,
  // login,
  // crm,
  // event,
  // profile,
  // venues,
  // products,
  // shops,
  // scanner,
  // finance,
  // support,
  // venuesV2,
  // common,
  // eventV2
};
