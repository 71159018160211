import styled from 'styled-components';

export const TablePaginationWrapper = styled.div`
    .table-pagination-wrapper {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        .table-pagination {
            display: inline-flex;
            margin: 0;
          cursor: pointer;
            .page-btn {
                display: block;
                &.active .page-link {
                    color: #ffffff;
                    background-color: ${props => props.theme.primaryColor};
                }
                &.prev-button {
                    height: 36px;
                    &.disabled {
                        display: none;
                    }
                    .page-link {
                        border-radius: 5px 0 0 5px;
                    }
                }
                &.next-button {
                    height: 36px;
                    &.disabled {
                        display: none;
                    }
                    .page-link {
                        border-radius: 0 5px 5px 0;
                    }
                }
                .page-link,
                .break-link {
                    background-color: #ffffff;
                    position: relative;
                    display: block;
                    padding: 5px 13px;
                    margin-left: -1px;
                    line-height: 1.5;
                    color: ${props => props.theme.primaryColor};
                    //border: 1px solid $primaryColor;
                    border: none;
                    outline: none;
                    box-shadow: none;
                    transition: all 0.4s;
                    text-decoration: none;
                    font-weight: bold;
                    font-size: 16px;
                    &:hover {
                        color: #ffffff;
                        background-color: ${props => props.theme.primaryColor};
                    }
                    &:before {
                        display: none;
                    }
                    span {
                        display: flex;
                        align-items: center;
                    }
                }
                .break-link {
                    cursor: pointer;
                    padding: 5px 11px;
                }
            }
        }
    }
`;
