import styled from 'styled-components';
export const Root = styled.div`
  .textarea-field {
    width: 100%;
  }

  .textarea-field textarea {
    width: 100%;
    height: auto;
    resize: none;
    font-size: 16px;
    border: 1px solid #e6e6e6;
    color: #9D9D9D;
    padding: 16px 15px;
  }
`
