import Api2 from './ApiV2';

const tokenKey = 'token';
const maxRefreshAttempts = 5;

const setToken = value => {
  // eslint-disable-next-line no-undef
  localStorage.setItem(tokenKey, JSON.stringify(value));
};

export const getToken = () => {
  // eslint-disable-next-line no-undef
  const data = localStorage.getItem(tokenKey);
  return data ? JSON.parse(data) : null;
};

export const grantTypes = {
  password: 'password',
  refreshToken: 'refresh_token',
};

export const clientData = {
  client_id: process.env.REACT_APP_DATA_CLIENT_ID,
  client_secret: process.env.REACT_APP_DATA_CLIENT_SECRET,
};

export const createToken = async data => {
  const response = await Api2.cp.auth.token.post({
    password: data.password,
    email: data.username,
  });

  const {access_token} = response;
  if (access_token) {
    setToken(response);
  }
  return response;
};

const createTokenByRefreshToken = async attempt => {
  const data = {
    refresh_token: getToken().refresh_token,
  };
  await Api2.cp.auth.token.refresh.post(data).then(response => {
    const tokenData = response?.data?.data;
    if (response.status !== 200) {
      /* Logout */
      if (attempt === maxRefreshAttempts - 1) {
        // eslint-disable-next-line no-undef
        localStorage.removeItem('token');
      }
    } else if (tokenData) {
      setToken(tokenData);
    }
    return response;
  });
};

export const getAuthorizationHeader = () => {
  const {token_type, access_token} = getToken() || {};
  const result = {
    Authorization: `${token_type} ${access_token}`,
  };
  return result;
};

const isAuthenticated = status => {
  const {message} = status || {};
  const messageCondition = Boolean(message === 'Unauthenticated.');
  return !messageCondition;
};

export const apiCallWithRefresh = async (instance, method, p1, p2, p3, p4) => {
  let attempt = 0;

  while (attempt < maxRefreshAttempts) {
    attempt++;
    instance.api.header = getAuthorizationHeader();
    // eslint-disable-next-line no-await-in-loop
    const response = await instance.api[method](p1, p2, p3, p4);
    const tokenObj = getToken();
    if (!isAuthenticated(response?.status) && tokenObj?.refresh_token) {
      // eslint-disable-next-line no-await-in-loop
      await createTokenByRefreshToken(attempt);
    } else if (!tokenObj) {
      //return true;
      // eslint-disable-next-line no-undef
      window.location.href = '/';
    } else {
      return response;
    }
  }
};

export const fetchWithAuth = async (url, init = {}) => {
  let attempt = 0;
  while (attempt < maxRefreshAttempts) {
    attempt++;
    init.headers = {
      'Content-type': 'application/json',
      Accept: 'application/json',
      ...init.headers,
      ...getAuthorizationHeader(),
    };
    // eslint-disable-next-line no-await-in-loop
    const response = await fetch(url, init).then(response => response.json());
    const tokenObj = getToken();
    if (!isAuthenticated(response?.status) && tokenObj?.refresh_token) {
      // eslint-disable-next-line no-await-in-loop
      await createTokenByRefreshToken(attempt);
    } else if (!tokenObj) {
      //return true;
      // eslint-disable-next-line no-undef
      window.location.href = '/';
    } else {
      return response;
    }
  }
};
