import React from 'react';
import {
  AuthForm,
  AuthLogo,
  AuthWindow,
  AuthWindowImg,
  AuthWindowImgWrapper,
  AuthWrapper,
} from '../styled';
import {images} from '../../../layout/theme/images';

export const LayoutAuthPages = props => {
  const {children} = props;
  return (
    <AuthWrapper>
      <img src={images.background} alt="Login" />
      <AuthWindow>
        <AuthWindowImgWrapper>
          <AuthWindowImg src={images.background} alt="Login" />
        </AuthWindowImgWrapper>
        <AuthForm>
          <AuthLogo src={images.logo} alt="Login" />
          {children}
        </AuthForm>
      </AuthWindow>
    </AuthWrapper>
  );
};
LayoutAuthPages.propTypes = {};
