import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {addBuilderCases, asyncThunkHelper} from '../utils';
import {
  createTemplate,
  deleteTemplate,
  getTemplate,
  getTemplates,
  updateTemplate,
} from '../../api/helpers/notification';

export const fetchTemplate = createAsyncThunk(
  'fetchTemplate',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: null}),
      default: async () => await getTemplate(payload?.id),
      create: async () => await createTemplate(payload?.data),
      update: async () => await updateTemplate(payload?.id, payload?.data),
      delete: async () => await deleteTemplate(payload?.id),
    }),
);

export const fetchTemplates = createAsyncThunk(
  'fetchTemplates',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: []}),
      default: async () => await getTemplates(payload?.data),
    }),
);

export const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    templates: {data: []},
    template: {data: null},
  },
  reducers: {},
  extraReducers: (builder: any): any => {
    addBuilderCases(builder, fetchTemplate, 'template');
    addBuilderCases(builder, fetchTemplates, 'templates');
  },
});
