import React from 'react';

const AutoComplete = ({data, show, styleguide, onSearchResultClick}) => {
  const autoCompleteList = data.map((item, key) => {
    return (
      <div
        key={key}
        onClick={() => onSearchResultClick(item.id)}
        className="search-autocomplete-item">
        <span>{item.category}</span>
        {item.name}
      </div>
    );
  });

  const styles = {
    position: 'relative',
    top: 'auto',
  };

  return show ? (
    <div className="search-autocomplete-new" style={styleguide ? styles : null}>
      {autoCompleteList}
    </div>
  ) : null;
};

export default AutoComplete;
