/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import styled, {createGlobalStyle, css} from 'styled-components';
import {Link} from 'react-router-dom';
import {TablePaginationWrapper} from '../../components/common/Table/components/TablePagination/styled';
import {TooltipContainer} from '../../../src/components/common/Table/components/TooltipWrapper/styled';

export interface GlobalStylesProps {
  $withoutBorder?: boolean;
  $withoutBorderTop?: boolean;
  $withoutBorderRight?: boolean;
  $withoutBorderLeft?: boolean;
  $withoutBorderBottom?: boolean;
  columnHeight?: number;
  $withoutPadding?: boolean;
  $borderRadius?: string;

  theme: {
    borderTables: string;
    spaceNormal: string;
    primaryFontSize: string;
    textInputColor: string;
    primaryFontFamily: string;
    fontWeightLight: string;
  };
  align?: string;
  justify?: string;
  wrap?: string;
  marginBottom?: number | string;
  fontWeight?: number;
  marginTop?: number;
  width?: number | string;
  padding?: number;
  direction?: string;
  height?: number | string;
  disabled?: boolean;
  tipWidth?: number;
  active?: boolean;
  minHeight?: number;
  withArchived?: boolean;
  right?: boolean;
  responsiveWidth?: number;
  autoWidth?: number | string | boolean;
}

export const Button = styled.div`
  margin: 0 10px;
  button {
    padding: 5px 10px !important;
    min-height: 30px !important;
  }
`;
export const StylesBorderTable = css<GlobalStylesProps>`
  border: ${props =>
    props?.$withoutBorder
      ? 'none !important'
      : `solid 1px ${props.theme.borderTables}`};
  border-top: ${props =>
    props?.$withoutBorderTop
      ? 'none !important'
      : `1px solid ${props.theme.borderTables}`};
  border-right: ${props =>
    props?.$withoutBorderRight
      ? 'none !important'
      : `1px solid ${props.theme.borderTables}`};
  border-left: ${props =>
    props?.$withoutBorderLeft
      ? 'none !important'
      : `1px solid ${props.theme.borderTables}`};
  border-bottom: ${props =>
    props?.$withoutBorderBottom
      ? 'none !important'
      : `1px solid ${props.theme.borderTables}`};
`;
export const GlobalStyle = createGlobalStyle`
  
    *{
    font-family: ${props => props.theme.primaryFontFamily};
    box-sizing: border-box;
    }
    
    #root {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    
    html, body {
        height: 100%;
    }
    
    body {
        color: ${props => props.theme.textInputColor};
        font-size: ${props => props.theme.primaryFontSize};
        font-family: ${props => props.theme.primaryFontFamily} !important;
        font-weight: ${props => props.theme.fontWeightLight};
        margin: 0;
        padding: 0;
        background-color: #f7f7f7;
    }

    body::-webkit-scrollbar {
      display: none; /* Chrome, Safari and Opera */
    }
    
    img {
        max-width: 100%;
    } 
    .special-label {
        display: none;
    }
    // Tooltip 
    .rc-tooltip{
      opacity: 1;
      background-color: transparent;
    }
    p {
      margin-bottom: 1rem;
      margin-top: 0;
    }
`;

export const FlexBlockStyle = styled.div<GlobalStylesProps>`
  display: flex;
  align-items: ${props => props.align || 'center'};
  justify-content: ${props => props.justify || 'flex-start'};
  flex-wrap: ${props => props.wrap || 'wrap'};
  > * {
    flex: 1 1 auto;
    :not(:last-child) {
      margin-right: ${props => props.theme.spaceNormal};
    }
  }
  @media (max-width: 1200px) {
    font-size: 11px;
  }
`;

export const FlexBlockColumnStyle = styled.div<GlobalStylesProps>`
  display: flex;
  flex-wrap: ${props => props.wrap || 'wrap'};
  > * {
    flex: 1 1 150px;
    margin-bottom: ${props => props.theme.spaceNormal};
    :not(:last-child) {
      margin-right: ${props => props.theme.spaceNormal};
    }
  }
`;

export const FlexBlockHalfPartStyled = styled.div<GlobalStylesProps>`
  width: 50%;
  margin-right: 0 !important;
  &:first-child {
    padding-right: ${props => props.theme.spaceNormal};
  }
  &:last-child {
    padding-left: ${props => props.theme.spaceNormal};
  }
`;
export const LinkForm = styled(Link)<GlobalStylesProps>`
    margin: 0;
    color: ${props => props.theme.primaryColor};
    font-size: ${props => props.theme.primaryFontSize}
    cursor: pointer;
    text-decoration: underline;
    :hover{
        color: ${props => props.theme.primaryColor}
    }
`;
export const ValidateText = styled.p<GlobalStylesProps>`
  position: absolute;
  font-size: ${props => props.theme.primaryFontSize};
  color: ${props => props.theme.errorText};
`;
export const GrayText = styled.p<GlobalStylesProps>`
  margin: 0;
  margin-bottom: ${props => props.marginBottom || props.theme.spaceNormal};
  color: ${props => props.theme.textInputColor};
  font-size: ${props => props.theme.primaryFontSize};
  font-weight: ${props => props.fontWeight || 'normal'};
`;

export const TextAlignStyled = styled.div<GlobalStylesProps>`
  text-align: ${props => props.align || 'left'};
  margin-bottom: ${props => props.marginTop || '0'};
  margin-bottom: ${props => props.theme.spaceNormal};
`;

export const StyledModalWrapper = styled.div<GlobalStylesProps>`
  width: ${props => props.width || '800px'};
  padding: ${props => props.padding || props.theme.spaceNormal};
`;

export const StyledFormContent = styled.div<GlobalStylesProps>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background: ${props => props.theme.backgroundColor};
  padding: ${props => props.theme.spaceNormal};
  margin-bottom: ${props => props.theme.spaceNormal};
  height: 100vh;
`;

export const StyledFormContentPart = styled.div<GlobalStylesProps>`
  width: 50%;
  &:first-child {
    padding-right: ${props => props.theme.spaceNormal};
  }
  &:last-child {
    padding-left: ${props => props.theme.spaceNormal};
  }
  .field-wrapper {
    margin-bottom: ${props => props.theme.spaceNormal};
  }
`;

export const StyledOptionDropdown = styled.div<GlobalStylesProps>`
  display: flex;
  justify-content: ${props => props.justify || 'flex-end'};
`;

export const StyledFilterTopBlock = styled.div<GlobalStylesProps>`
  display: flex;
  justify-content: ${props => props.justify || 'space-between'};
  margin-bottom: ${props => props.theme.spaceNormal};
`;

export const StyledFilterInputs = styled.div<GlobalStylesProps>`
  display: flex;
  flex-direction: ${props => props.direction || 'row'};
  justify-content: ${props => props.justify || 'space-between'};
`;
export const StyledFilterInput = styled.div<GlobalStylesProps>`
  width: 300px;
  margin-right: ${props => props.theme.spaceNormal};
`;

export const StyledAddModalWrapper = styled.div<GlobalStylesProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow-y: auto;
  .content .dialog-scrollableContent {
    height: 100%;
  }
  .dialog-scrollableContent-height100 {
    height: 100%;
  }
  .modal-new-event-wr {
    height: initial !important;
  }
`;

export const StyledModalSectionWrapper = styled.div<GlobalStylesProps>`
  margin: 130px 120px;
`;

export const CommonProductTableWrapper = styled.div<GlobalStylesProps>`
  margin-bottom: ${props => props.theme.spaceNormal};
  height: 100%;
  .modal-new-event-wr {
    height: initial !important;
  }
  table th {
    &:nth-child(4) {
      width: 120px;
    }
  }
`;

export const StyledAddModalContent = styled.div<GlobalStylesProps>`
  margin: 40px 40px 20px 20px;
`;

export const StyledTabDescription = styled.div<GlobalStylesProps>`
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 800px;
`;

export const StyledLinkButton = styled.div<GlobalStylesProps>`
  button {
    cursor: pointer;
    text-decoration: underline;
    color: ${props => props.theme.primaryColor};
    background: none;
    border: none;
    outline: none;
    padding: 0;
    &:hover {
      text-decoration: none;
    }
  }
`;

export const FiltersRootRowStyled = styled.div<GlobalStylesProps>`
  margin-bottom: ${props => props.theme.spaceNormal};
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const FiltersSelectorsRowStyled = styled.div<GlobalStylesProps>`
  display: flex;
  flex-direction: row;
`;

export const FiltersSelectorStyled = styled.div<GlobalStylesProps>`
  width: 200px;
  margin-left: 10px;
`;

export const ButtonsContainerStyled = styled.div<GlobalStylesProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${props => props.theme.borderColor};
  padding: ${props => props.theme.spaceNormal};
`;

export const QRCodeWrStyled = styled.div<GlobalStylesProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TableStyle = styled.div<GlobalStylesProps>`
  margin-bottom: ${props => props.theme.spaceNormal};
  table tr td:last-child {
    text-align: right;
    .popup-wrapper {
      text-align: left;
    }
  }
`;

export const ModalWrapper = styled.main<GlobalStylesProps>`
  .dialog-window > div:first-child {
    background-color: transparent !important;
    border-bottom: solid 1px rgb(213, 213, 213);
    .dialog-titleText {
      font-size: 24px;
      font-weight: 400 !important;
    }
  }
`;
export const ModalContentStyled = styled.div<GlobalStylesProps>`
  margin-bottom: ${props => props.theme.spaceNormal};
`;

export const StyledWrapper = styled.div<GlobalStylesProps>`
  display: flex;
  flex-direction: row;
  background: white;
  border-radius: 5px;
  box-shadow: 0 8px 20px #0000000d;
`;

export const StyledWr = styled.div<GlobalStylesProps>`
  display: flex;
  flex-direction: row;
  margin: 0 -20px;
`;

export const StyledBlock = styled.div<GlobalStylesProps>`
  padding: 20px 20px 0 20px;
  width: 50%;
  margin-bottom: ${props => props.theme.spaceNormal};
`;

export const StyledInputs = styled.div<GlobalStylesProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledInput = styled.div<GlobalStylesProps>`
  width: ${props => props.width || 300}px;
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
  @media (max-width: 1700px) {
    width: ${props => props.responsiveWidth || props.width || 300}px;
  }
  @media (max-width: 1600px) {
    width: 200px !important;
  }
`;

export const StyledButton = styled.div<GlobalStylesProps>`
  display: flex;
  justify-content: flex-end;
  padding: ${props => props.theme.spaceNormal} 0;
  ${props =>
    props.right ? `padding-right: ${props.theme.spaceNormal}` : null};
`;

export const ButtonAbsolute = styled.div<GlobalStylesProps>`
  position: absolute;
  top: ${props => props.theme.spaceNormal};
  right: ${props => props.theme.spaceNormal};
`;

export const GStyledInput = styled.div<GlobalStylesProps>`
  flex: 1;
`;

export const MapContent = styled.div<GlobalStylesProps>`
  background: ${props => props.theme.backgroundColor};
  padding-top: ${props => props.theme.spaceNormal};
  padding-bottom: ${props => props.theme.spaceNormal};
  margin-bottom: ${props => props.theme.spaceNormal};
  position: relative;
`;

export const MapSummary = styled.div<GlobalStylesProps>`
  border-color: ${props => props.theme.borderColor};
  border-radius: ${props => props.theme.borderRadiusClassic};
  border-width: 1px;
  border-style: solid;
`;

export const MapRow = styled.div<GlobalStylesProps>`
  display: flex;
  justify-content: space-between;
`;

export const MapSectorsList = styled.div<GlobalStylesProps>`
  min-width: 300px;
  max-height: 860px;
  border-right: 1px solid ${props => props.theme.lightGrayColor};
  overflow-y: auto;
`;

export const MapImage = styled.div<GlobalStylesProps>`
  padding: 10px;
  width: 100%;
  position: relative;
  img {
    width: 100%;
  }
`;

export const ColorPickerWrStyled = styled.div<GlobalStylesProps>`
  position: relative;
`;

export const ColorPickerStyled = styled.div<GlobalStylesProps>`
  min-width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: ${props => props.theme.borderRadiusClassic};
  background-color: ${props => (props.color ? props.color : '#000')};
  border: 1px solid lightgray;
`;

// export const ColorPickerWrapper = styled.div`
//     display: flex;
//     flex-direction: row;
//     column-gap: 10px;
// `;

export const ColorPickerModalStyled = styled.div<GlobalStylesProps>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

export const TableContainer = styled.div<GlobalStylesProps>`
  .table__container .table.with-selection table thead th:first-of-type {
    padding: 0;
  }

  .table__container .table table thead tr {
    th {
      padding: 0;
      ${props => props.theme.screenSize.lg} {
        font-size: 10px !important;
        padding: 0 5px;
      }
    }
    tr {
      text-align: left;
      vertical-align: inherit;
    }
  }

  .table__container {
    thead,
    tbody tr td:first-child,
    th:first-child {
      padding-left: 10px !important;
    }
  }

  .checkbox-wrapper {
    text-align: left !important;
  }

  .table__container .table table thead {
    height: 35px !important;
    border-bottom: ${props =>
      props.$withoutBorderBottom
        ? 'none !important'
        : `1px solid ${props.theme.borderTables} !important`};
    th {
      text-align: left !important;
    }
  }

  .table__container table tbody {
    tr {
      height: ${props => (props.columnHeight ? 70 : 40)}px !important;
      border-right: ${props =>
        props.$withoutBorderRight
          ? 'none !important'
          : `solid 1px ${props.theme.borderTables}`};
      td {
        vertical-align: inherit !important;
        text-align: left !important;
        padding-left: 0 !important;
      }
    }
  }

  background-color: #ffffff;
  border-radius: 5px;
  border: ${props =>
    props.$withoutBorder ? 'none' : `1px solid ${props.theme.borderTables}`};
  border-bottom: ${props =>
    props.$withoutBorderBottom
      ? 'none'
      : `1px solid ${props.theme.borderTables}`};
  .table__container .table table thead th,
  .table__container .table table thead tr {
    font-size: ${props => props.theme.fontSizeExtraSmall} !important;
  }
  .table__container .table table tbody {
    font-size: ${props => props.theme.fontSizeExtraSmall} !important;
  }
  .status-text {
    font-size: ${props => props.theme.fontSizeExtraSmall} !important;
  }
  table {
    table-layout: auto;
    thead {
      background: transparent !important;
      border-bottom: 1px solid #e0e0e1 !important;
      th {
        .rounded-select {
          display: none;
        }
      }
    }
  }
  img {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }
  ${TablePaginationWrapper} {
    .table-pagination {
      .page-btn {
        &.active .page-link,
        .page-link:hover,
        .break-link:hover {
          background-color: transparent;
          color: ${props => props.theme.primaryColor};
        }

        .page-link,
        .break-link {
          color: #1e1e1e;
        }
        &.next-button .page-link,
        &.prev-button .page-link {
          color: ${props => props.theme.primaryColor};
        }
      }

      .page-btn.active .page-link {
        background-color: transparent;
        color: ${props => props.theme.primaryColor};
      }
    }
  }

  .table__footer {
    padding-left: 10px !important;
    .results {
      padding: 0 !important;
    }
  }
`;

export const BtnActionWrapper = styled.div`
  button {
    padding: 10px 15px;
    line-height: unset;
    text-transform: uppercase !important;
  }
`;
export const TagStyle = styled.div<GlobalStylesProps>`
  background-color: #d5d5d5;
  border-radius: 5px;
  padding: 3px 10px;
`;

export const WithViewTooltip = styled.div<GlobalStylesProps>`
  display: flex;
  justify-content: flex-start;
  .tooltip-wrapper {
    padding-left: 10px;
  }
`;
export const ViewEye = styled.div<GlobalStylesProps>`
  color: #007bff;
`;

export const PageHeaderContainer = styled.div<GlobalStylesProps>`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
  padding: 0 20px;
`;

export const TabsWrapper = styled.div<GlobalStylesProps>`
    .tabs-wrapper {
      position: relative;
        margin-bottom: 0;
        border-bottom: 1px solid #e1e3e5;
        padding: 30px 0 7px 20px;
        
        .tab-item:first-child {
            padding-left: inherit;
        }
        .tab-item {
            color: #9e9ea6;
            font-size: ${props => props.theme.fontSizeExtraSmall};
            font-weight: ${props => props.theme.fontWeightSemiBold};
            &:before{
              bottom:-7px;
            }
        }
        .tab-item.current-tab {
            color: #1e1e1e;
        }
      .tab-item:last-child {
        ${({withArchived}) =>
          withArchived &&
          `position: absolute;
             right: 0;`}
    }
`;

export const Title = styled.h4<GlobalStylesProps>`
  margin: 0;
  font-weight: ${props => props.theme.fontWeightBold};
  font-size: ${props => props.theme.fontSizeMedium};
`;
export const RightSideBlock = styled.div<GlobalStylesProps>`
  div {
    font-size: ${props => props.theme.primaryFontSize};
    color: ${props => props.theme.primaryColor};
    font-weight: bold;
    width: ${props => (props.autoWidth ? 'auto' : '100px')};
  }
  align-items: baseline;
  justify-content: space-between;
  display: flex;
`;
// Filters Styled
export const ProductsFilterWrapper = styled.div<GlobalStylesProps>`
  display: flex;
  padding: 20px 10px 10px;
  justify-content: space-between;
`;

export const FiltersWrapper = styled.div<GlobalStylesProps>`
  display: flex;
  width: ${props => (props.width ? props.width : 'auto')};
  justify-content: space-between;
  cursor: pointer;
`;
export const ConnectedFilters = styled.div<GlobalStylesProps>`
  margin-right: 10px;
  position: relative;
  display: flex;
  height: 32px;
  border: 1px solid #e1e3e5;
  border-radius: 5px;
  font-size: ${props => props.theme.fontSizeExtraSmall} !important;
`;
export const SearchWrapper = styled.div<GlobalStylesProps>`
  //width: ${props => (props.width ? props.width : '100%')};
  margin-right: 20px;
  flex: 1;
  .search-field-wrapper {
    max-width: unset !important;
    input {
      border: 1px solid #e1e3e5 !important;
      height: 32px;
      padding: 0 !important;
      padding-left: 40px !important;
    }

    svg {
      height: 15px;
      width: 15px;
    }
  }
  .icon-search-wr {
    top: 7px !important;
    left: 15px;
    width: 12px;
    height: auto;
    right: inherit;
  }
`;

export const CountFilter = styled.div<GlobalStylesProps>`
  display: flex;
  padding: 10px;
  align-items: center;
  border-right: 1px solid #e1e3e5;
  &:last-child {
    border-right: none;
  }
  > svg {
    color: ${props => props.theme.primaryColor};
    margin-right: 5px;
  }
  .rounded-select {
    width: 60px !important;
    min-width: 60px !important;
    font-size: 12px;
    font-weight: 600;
    color: #1e1e1e;
  }
`;

export const DropdownWrapper = styled.div<GlobalStylesProps>`
  width: ${props => (props.width ? props.width : 'auto')};
  font-size: ${props => props.theme.fontSizeExtraSmall} !important;
  margin-right: 10px;
  .rounded-select {
    color: #1e1e1e !important;
    font-size: ${props => props.theme.fontSizeExtraSmall} !important;
    font-weight: 600 !important;
  }
  &:last-child {
    margin-right: 0;
  }
`;

export const DropdownWithFilters = styled.div<GlobalStylesProps>`
  position: absolute;
  z-index: 5;
  background-color: ${props => props.theme.whiteColor};
  border: 1px solid #e1e3e5;
  color: #9e9ea6;
  top: 35px;
  bottom: 0;
  width: ${props => props.width || '340px'};
  height: ${props => props.height || 'auto'};
  min-height: ${props => props.minHeight || 'auto'};
  border-radius: 5px;
  padding: 10px;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      border-bottom: 1px solid ${props => props.theme.borderColor};
      padding: 5px 10px;
      font-size: 13px;
      color: #1e1e1e;
    }
  }
`;

export const FilterItem = styled.div<GlobalStylesProps>`
  cursor: pointer;
  display: flex;
  padding: 10px;
  align-items: center;
  border-right: 1px solid #e1e3e5;
  gap: 5px;
  &:last-child {
    border-right: none;
  }

  ${DropdownWithFilters} {
    left: 0;
  }
  svg path {
    fill: ${props => props.theme.primaryColor};
  }
`;

export const FilterInnerWrapper = styled.div<GlobalStylesProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  span {
    font-size: 12px;
    font-weight: 600;
    color: #1e1e1e;
  }
  & > svg {
    color: ${props => props.theme.primaryColor};
    margin-right: 5px;
  }
`;

export const FilterCounterWrapper = styled.div<GlobalStylesProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  padding-right: 10px;
  cursor: pointer;
  span {
    font-size: 12px;
    color: #1e1e1e;
    font-weight: 600;
  }
  img {
    margin: 0;
  }
  svg {
    transform: rotate(${props => (props.active ? '180deg' : 0)});
    color: #d5d5d5;
    transition: 0.4s;
  }
`;

export const FilterCounterList = styled.div<GlobalStylesProps>`
  z-index: 5;
  position: absolute;
  right: 0;
  top: 35px;
  max-width: 74px;
  width: 100%;
  border-radius: 2px;
  border: 1px solid #e1e3e5;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px 10px;
  background: #ffffff;
  row-gap: 10px;
  div {
    font-size: 12px;
    color: black;
    font-weight: 600;
    cursor: pointer;
  }
`;

export const FilterTitle = styled.div<GlobalStylesProps>`
  margin-bottom: 10px;
  font-weight: ${props => props.theme.fontWeightSemiBold};
  display: flex;
  align-items: baseline;
`;
export const SelectedOptions = styled.ul<GlobalStylesProps>`
  display: flex;
  list-style: none;
  justify-content: flex-start;
  margin: 0;
  flex-wrap: wrap;
  padding: 0 0 0 10px;
  margin-bottom: 10px !important;
  li {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    border: 1px solid ${props => props.theme.borderColor};
    border-radius: 25px;
    padding: 2px 20px 2px 5px !important;
    margin: 0 0 5px 5px;
    &:hover {
      border: 1px solid ${props => props.theme.primaryColor};
    }

    svg {
      cursor: pointer;
      position: absolute;
      right: 5px;
    }
  }
`;

export const SubFilterContainer = styled.div<GlobalStylesProps>`
  font-weight: ${props => props.theme.fontWeightSemiBold};
  color: #1e1e1e;
  display: flex;
  position: relative;
  justify-content: space-between;
  column-gap: 20px;
  ${DropdownWrapper} {
    margin-right: 0;
  }
  svg {
    path {
      fill: ${props => props.theme.primaryColor};
    }
    color: ${props => props.theme.primaryColor};
  }
  .date-picker-input {
    span {
      font-size: 12px;
      padding: 0 5px !important;
    }
    svg {
      path {
        fill: ${props => props.theme.primaryColor};
      }
      color: ${props => props.theme.primaryColor};
    }
  }
`;
export const ActionWrapper = styled.div<GlobalStylesProps>`
  position: relative;
  ${DropdownWithFilters} {
    left: -150px;
  }
`;

export const ViewButton = styled.div<GlobalStylesProps>`
  cursor: pointer;
`;

export const ButtonsContainer = styled.div<GlobalStylesProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: fit-content;
  column-gap: 10px;
  ${Button} {
    margin: 0;
  }
`;
export const TipsWrapper = styled.div<GlobalStylesProps>`
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-right: 10px;
  width: 30px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};

  &:hover span {
    visibility: visible;
    opacity: 1;
  }
  span {
    width: fit-content;
    bottom: 120%;
    min-width: ${props => (props.tipWidth ? props.tipWidth : 60)}px;
    border-radius: 5px;
    text-align: center;
    border: 1px solid ${props => props.theme.borderColor};
    background: #fff;
    color: #222;
    font-size: 12px;
    font-weight: 600;
    position: absolute;
    z-index: 1;
    padding: 5px 7px;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
  }
  ${props => props.theme.screenSize.lg} {
    width: 25px;
  }
`;

export const NoDateWrapper = styled.div<GlobalStylesProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${props => (props.height ? props.height : 680)}px;
  width: 100%;
  padding-top: 100px;
  img {
    width: 158px;
    height: 158px;
  }
  h2 {
    margin-top: 20px;
    font-size: 24px !important;
    font-weight: 700 !important;
    text-align: center;
  }
  button {
    margin-top: 20px;
    padding: 10px 20px !important;
    min-height: unset !important;
    line-height: unset !important;
    display: flex;
    align-items: center;
  }
`;

export const ErrorBounderWrapper = styled.div<GlobalStylesProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  column-gap: 10px;
  padding-top: 100px;
`;

export const HeaderButtons = styled.div<GlobalStylesProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: ${props => props.theme.spaceNormal};
  margin-top: 10px;
  h4 {
    color: ${props => props.theme.primaryColor};
    font-weight: ${props => props.theme.fontWeightBold};
    font-size: ${props => props.theme.primaryFontSize};
    margin: 0;
    padding: 0;
    cursor: pointer;
  }
  margin-bottom: ${props => props.theme.spaceNormal};
  button {
    height: 40px;
    min-height: unset !important;
    line-height: unset;
    padding: 10px 20px !important;
    display: flex;
    align-items: center;
  }
`;

export const TextPopupDelete = styled.p<GlobalStylesProps>`
  padding: 10px;
  font-weight: 600;
`;

export const ResultCountWrapper = styled.div`
  color: ${props => props.theme.primaryColor};
  font-weight: bold;
  padding-top: 5px;
  font-size: 0.875rem;
`;

export const TableWrapper = styled.div`
  .MuiIconButton-root {
    color: ${props => props.theme.primaryColor};

    &:focus {
      outline: none;
    }
  }

  .MuiToolbar-root {
    overflow: unset;
    position: relative;
    z-index: 10;
  }

  .MuiTextField-root {
    path {
      color: ${props => props.theme.primaryColor};
    }
  }

  table thead {
    opacity: 1;
  }

  .MuiTableRow-root:hover {
    ${TooltipContainer} {
      opacity: 1 !important;
      visibility: visible !important;
    }
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: baseline;
  svg {
    font-size: 18px;
    color: ${props => props.theme.primaryColor};
  }
`;

// export const TooltipContainer = styled.div<{$isVisible: boolean}>`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   visibility: ${props => (props.$isVisible ? 'visible' : 'hidden')};
//   opacity: ${props => (props.$isVisible ? '1' : '0')};
//   transition: all ease-in-out 0.4s;
//
//   .tooltip-wrapper {
//     .tooltip {
//       border-radius: 7px;
//     }
//     .rc-tooltip-arrow-inner {
//       right: 3%;
//     }
//   }
// `;

export const ActionsTableWrapper = styled.div<{
  $isCheckBoxColumn?: boolean;
  $width?: string;
}>`
  .MuiTableContainer-root {
    max-width: calc(100vw - 122px);
  }

  .MuiBox-root {
    overflow: unset;
  }

  .MuiPaper-root {
    & > .MuiBox-root:last-child {
      & > .MuiBox-root {
        padding: 0 !important;
        border-bottom: none;
      }
    }
  }

  & > .MuiPaper-root:first-child {
    & > .MuiBox-root {
      & > .MuiBox-root:last-child {
        padding-bottom: 0;
        border-bottom: 1px solid #e1e3e5;
      }
    }
  }

  & > .MuiToolbar-root:last-child {
    background: unset;
    border-radius: 0;
    .MuiBox-root {
      padding: 0 !important;
      .MuiBox-root {
        padding: 0 !important;
      }
    }
  }

  .MuiPaper-root {
    box-shadow: none;
    max-width: ${props => props?.$width && props.$width};

    & > .MuiBox-root:last-child {
      padding: 0 !important;
    }
    ${ResultCountWrapper} {
      padding-top: 0;
      padding-left: 20px;
    }
    //& > .MuiToolbar-root:first-child {
    //  background: unset;
    //}

    & > .MuiToolbar-root:last-child {
      background: unset;
      border-radius: 0;
      .MuiBox-root {
        padding: 0 !important;
      }
    }

    ${props =>
      props.$isCheckBoxColumn
        ? css`
            .MuiTableBody-root {
              & > tr {
                & > td:nth-child(1) {
                  padding-left: 10px;
                }
              }
            }
            .MuiTableBody-root {
              & > tr {
                & > td:nth-child(1) {
                  padding-left: 8px;
                }
              }
            }
            //.MuiTableRow-root {
            //  & > .MuiTableCell-root:nth-child(1) {
            //    min-width: unset !important;
            //    width: 25px;
            //    padding-right: 0;
            //    & > .Mui-TableHeadCell-Content {
            //      width: 20px;
            //    }
            //  }
            //}
          `
        : css`
            thead {
              tr {
                & > th:nth-child(1) {
                  padding-left: 20px !important;
                }
              }
            }
            .MuiTableBody-root {
              & > tr {
                & > td:nth-child(1) {
                  padding-left: 20px;
                }
              }
            }
          `}

    & > .MuiToolbar-dense:nth-child(1) {
      & > .MuiCollapse-vertical {
        bottom: 5px;
      }
      & > .MuiBox-root {
        padding-bottom: 0;
        width: 100%;
        border-bottom: 1px solid #e1e3e5 !important;
        margin-bottom: 0;
      }
    }
    & > .MuiToolbar-root {
      border-bottom-right-radius: 5px !important;
      border-bottom-left-radius: 5px !important;
    }
  }

  .MuiTextField-root {
    margin-top: 5px;
    .MuiInputBase-input {
      font-size: 14px !important;

      &::placeholder {
        font-size: 14px !important;
      }
    }
  }
  .MuiTableBody-root {
    .MuiTableRow-root {
      & > .MuiTableCell-root {
        width: 40px;
      }
    }
  }

  .MuiTablePagination-selectLabel,
  .MuiTablePagination-displayedRows {
    font-size: 12px;
  }

  .MuiIconButton-root {
    color: ${props => props.theme.primaryColor};
    &:focus {
      outline: none;
    }
  }

  .MuiToolbar-root {
    overflow: unset;
    position: relative;
    z-index: 10;
  }

  .MuiTextField-root {
    path {
      color: ${props => props.theme.primaryColor};
    }
  }

  table thead {
    opacity: 1;
  }

  .MuiTableRow-root:hover {
    ${TooltipContainer} {
      opacity: 1 !important;
      visibility: visible !important;
    }
  }
`;

export const BreadcrumbsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 10;
  a {
    text-decoration: none;
    color: ${props => props.theme.primaryColor};
  }
  .MuiBreadcrumbs-li {
    font-size: 15px !important;
    font-weight: 500;
    p {
      font-size: 15px !important;
      font-weight: 500;
    }
  }
`;

export const TabsTableWrapper = styled.div`
  margin-left: -9px;
  ${TabsWrapper} {
    .tabs-wrapper {
      background: transparent;
      padding: 10px 0 7px 20px;
      border: none;
      margin-top: 5px;
    }
  }
`;

export const RequiredExplanation = styled.div`
  margin-left: 10px;
  margin-top: 10px;
  color: ${props => props.theme.blackColor};
`;
