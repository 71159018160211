import ApiV2 from '../ApiV2';
export const getPlatformServices = async (payload: any): Promise<any> => {
  const response = await ApiV2.cp.platform.services.get(payload);
  return response?.data;
};
export const getPlatformService = async (id: any): Promise<any> => {
  const response = await ApiV2.cp.platform.services.get(id);
  return response?.data;
};
export const updatePlatformService = async (
  id: any,
  data: any,
): Promise<any> => {
  const response = await ApiV2.cp.platform.services.patch(id, data);
  return response?.data;
};
export const getPlatformSubscriptionBundles = async (
  payload: any,
): Promise<any> => {
  const response = await ApiV2.cp.platform.subscriptionBundles.get(payload);
  return response?.data;
};
export const getPlatformSubscriptionBundle = async (
  bundle_id: number,
): Promise<any> => {
  const response = await ApiV2.cp.platform.subscriptionBundles.get(bundle_id);
  return response?.data;
};
export const updatePlatformSubscriptionBundle = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.platform.subscriptionBundles.patch(id, data);
  return response?.data;
};
export const deletePlatformSubscriptionBundle = async (
  id: number,
): Promise<any> => {
  const response = await ApiV2.cp.platform.subscriptionBundles.delete(id);
  return response?.data;
};
export const createPlatformSubscriptionBundle = async (
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.platform.subscriptionBundles.post(data);
  return response?.data;
};
export const getPlatformSubscriptionItems = async (
  payload: any,
): Promise<any> => {
  const response = await ApiV2.cp.platform.subscriptionItems.get(payload);
  return response?.data;
};
export const getPlatformSubscriptionItem = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.platform.subscriptionItems.get(id);
  return response?.data;
};
export const createPlatformSubscriptionItem = async (
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.platform.subscriptionItems.post(data);
  return response?.data;
};
export const deletePlatformSubscriptionItem = async (
  id: number,
): Promise<any> => {
  const response = await ApiV2.cp.platform.subscriptionItems.delete(id);
  return response?.data;
};
export const updatePlatformSubscriptionItem = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.platform.subscriptionItems.patch(id, data);
  return response?.data;
};
export const getPlatformSubscriptionPricingModels = async (
  payload: any,
): Promise<any> => {
  const response =
    await ApiV2.cp.platform.subscriptionPricingModels.get(payload);
  return response?.data;
};
export const getPlatformSubscriptionStatuses = async (
  payload: any,
): Promise<any> => {
  const response = await ApiV2.cp.platform.subscriptionStatuses.get(payload);
  return response?.data;
};
export const getPlatformSubscriptionItemTypes = async (
  payload: any,
): Promise<any> => {
  const response = await ApiV2.cp.platform.subscriptionItemTypes.get(payload);
  return response?.data;
};
export const deleteSubscriptionItemFromBundle = async (
  bundleid: number,
  itemid: number,
): Promise<any> => {
  const response =
    await ApiV2.cp.platform.subscriptionBundles.subscriptionItems.delete({
      bundleid,
      itemid,
    });
  return response?.data;
};
export const addSubscriptionItemToBundle = async (
  bundleid: number,
  data: object,
): Promise<any> => {
  const response =
    await ApiV2.cp.platform.subscriptionBundles.subscriptionItems.post(
      {
        bundleid,
      },
      data,
    );
  return response?.data;
};

export const getPermissions = async (data: any): Promise<any> => {
  const response = await ApiV2.cp.platform.permissions.get(data);
  return response?.data;
};

export const getRoles = async (payload: any): Promise<any> => {
  const response = await ApiV2.cp.platform.roles.get(payload);
  return response?.data;
};
export const getRole = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.platform.roles.get(id);
  return response?.data;
};
export const createRole = async (data: object): Promise<any> => {
  const response = await ApiV2.cp.platform.roles.post(data);
  return response?.data;
};

export const updateRole = async (id: number, data: object): Promise<any> => {
  const response = await ApiV2.cp.platform.roles.patch(id, data);
  return response?.data;
};

export const deleteRole = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.platform.roles.delete(id);
  return response?.data;
};

export const updateRolePermissions = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.platform.roles.permissions.put({id}, data);
  return response?.data;
};
export const getSubscriptionOptions = async (): Promise<any> => {
  const response = await ApiV2.cp.platform.subscriptionOptions.get();
  return response?.data;
};

export const createSubscriptionOption = async (data: any): Promise<any> => {
  const response = await ApiV2.cp.platform.subscriptionOptions.post(data);
  return response?.data;
};

export const getSubscriptionOption = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.platform.subscriptionOptions.get(id);
  return response?.data;
};
export const deleteSubscriptionOption = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.platform.subscriptionOptions.delete(id);
  return response?.data;
};
export const updateSubscriptionOption = async (
  id: number,
  data: any,
): Promise<any> => {
  const response = await ApiV2.cp.platform.subscriptionOptions.patch(id, data);
  return response?.data;
};
export const getSubscriptionOptionsToBundle = async (
  bundleid: number,
): Promise<any> => {
  const response =
    await ApiV2.cp.platform.subscriptionBundles.subscriptionOptions.get({
      bundleid,
    });
  return response?.data;
};
export const updateSubscriptionOptionsToBundle = async (
  bundleid: number,
  data: any,
): Promise<any> => {
  const response =
    await ApiV2.cp.platform.subscriptionBundles.subscriptionOptions.patch(
      {bundleid},
      data,
    );
  return response?.data;
};
export const getSubscriptionOptionCategories = async (): Promise<any> => {
  const response = await ApiV2.cp.platform.subscriptionOptionCategories.get();
  return response?.data;
};
