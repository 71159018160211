/* eslint-disable no-useless-escape */
import moment from 'moment';

export const getRandomInt = max => {
  return Math.floor(Math.random() * Math.floor(max));
};

export const getFormattedNumber = (value, decimal) =>
  Number(value).toFixed(decimal);

export const getFormattedMoney = (value, currency = '€', decimal = 2) =>
  `${currency} ${getFormattedNumber(value, decimal)}`;

export const getStringFromArrayByKey = (items, key = 'name') => {
  const tmp = items ? items.map(item => item[key]) : [];

  return tmp.join(', ');
};

export const getStringFromArrayByText = (items, key) => {
  const tmp = items ? items.map(item => item[key]) : [];
  return tmp[0];
};

export const slugify = (string, sign = '_') => {
  return (
    string &&
    string
      .toString()
      .trim()
      .toLowerCase()
      .replace(/\s+/g, sign)
      .replace(/[^\w\-]+/g, '')
      .replace(/\-\-+/g, sign)
      .replace(/^-+/, '')
      .replace(/-+$/, '')
  );
};

export const getMoneyNumberFromString = (string, decimal = 2) => {
  if (string) {
    const number = string
      .toString()
      .match(/(\d|,|\.)+/g)
      .pop();

    return Number(number.toString()).toFixed(decimal);
  }
  return null;
};

export const getMoneyFormatFromDecimal = string => string && string.toFixed(2);

export const toBase64 = file => {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

export const replaceOrPushObjectToArray = (items, data) => {
  let newData = [...items];

  const prevData = items.find(item => item.id === data.id);

  if (!prevData) {
    newData.push(data);
  } else {
    newData = newData.map(item => (item.id === data.id ? data : item));
  }

  return newData;
};

export const replaceOrPushObjectToArrayWithType = (items, data) => {
  let newData = [...items];

  const prevData = items.find(
    item => item.id === data.id && item.type.id === data.type.id,
  );

  if (!prevData) {
    newData.push(data);
  } else {
    newData = newData.map(item =>
      item.id === data.id && item.type.id === data.type.id ? data : item,
    );
  }

  return newData;
};

export const getMainImage = images =>
  images && images.find(image => image.is_main);

export const getImage = images => (images ? images[0] : null);

export const getDateTime = (date, time, id) => {
  let publish;
  if (id === 8) {
    publish = `T${time}:00`;
  } else {
    const publishingDate = moment(date);
    const publishing_date = publishingDate.format('YYYY-MM-DD{$}Z');
    publish = publishing_date.replace('{$}', `T${time}:00.00`);
  }
  return publish;
};

export const getCurrentDateTime = () => {
  return moment().format('YYYY-MM-DDTHH:mm:ss.00Z');
};

export const getHostnameFromUrl = url => (url ? new URL(url).hostname : null);

export const escapeSpecialChars = string =>
  string.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');

export const getTime = (date, format = 'HH:mm', empty = '- - : - -') => {
  return date ? moment(date).format(format) : empty;
};

export const formatTime = time => {
  const date = moment().format('YYYY-MM-DD');
  return time ? moment(`${date}T${time}`) : null;
};

export const isSameDay = date => moment(date).isSame(moment(), 'day');

export const arrayToObject = (array, defaultValue = '') => {
  return array.reduce((result, key) => {
    result[key] = defaultValue;
    return result;
  }, {});
};

export const getImageSource = image => {
  if (!image) return null;
  return image.includes('base64,') ? image : `data:image/png;base64,${image}`;
};

export const getFullName = (firstname = '', lastname = '') =>
  `${firstname} ${lastname}`.trim();

export const getAddressString = address => {
  const {address: addressInner, post_code, city, state, country} = address;
  let addressDef = null;
  if (addressInner || post_code || city || state?.name || country?.name) {
    const adres = addressInner ? `${addressInner},` : null;
    const postCode = post_code ? `${post_code},` : null;
    const cit = city ? `${city},` : null;
    const stat = state?.name ? `${state.name},` : null;
    const countr = country?.name ? `${country.name}` : null;
    addressDef = `${adres} ${postCode} ${cit} ${stat} ${countr}`;
  }
  return addressDef;
};

export const getImageWithoutPrefix = image => {
  return image.includes('base64,') ? image.split('base64,')[1] : image;
};

export const uuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    let r = (Math.random() * 16) | 0;
    let v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const onSetFilter = (data, localFieldName) => {
  localStorage.setItem(`${localFieldName}`, JSON.stringify(data));
};

export const onFindFieldByName = (data, findByField, value) => {
  return data?.length
    ? data?.find(item => item?.[findByField] === value)
    : null;
};

export const onFindByField = (data, findByField) => {
  return data?.length ? data?.find(item => item?.[findByField]) : null;
};

export const onDeleteFieldInArray = (data, findByField) => {
  return data?.length ? data?.filter(item => item?.[findByField]) : null;
};

export const onDeleteFieldByValue = (data, findByField, value) => {
  return data?.length
    ? data?.filter(item => item?.[findByField] === value)
    : null;
};

export const getCSVSource = csv => {
  if (!csv) return null;
  return csv.includes('base64,') ? csv : `data:csv;base64,${csv}`;
};
