import React from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfo} from '@fortawesome/free-solid-svg-icons';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import * as S from './styled';

const FieldWrapper = ({
  title,
  additionalText,
  style,
  children,
  info,
  titleLink,
  onLinkClick,
}) => {
  return (
    <S.Root style={style} className="field-wrapper">
      <div className="field-wrapper-title-outer-wr">
        <div className="field-wrapper-title-wrapper">
          <div className="field-wrapper-title-wr">
            <div className="field-wrapper-title">{title}</div>
            <div className="field-wrapper-additional">{additionalText}</div>
          </div>
          {titleLink && (
            <p
              className="field-wrapper-title-link"
              onClick={() => onLinkClick(titleLink)}>
              {titleLink.label}
            </p>
          )}
        </div>

        {info && (
          <TooltipWrapper text={info}>
            <div className="field-wrapper-info-wr">
              <FontAwesomeIcon
                className="field-wrapper-info-wr-icon"
                icon={faInfo}
              />
            </div>
          </TooltipWrapper>
        )}
      </div>
      <div>{children}</div>
    </S.Root>
  );
};

FieldWrapper.propTypes = {
  title: PropTypes.string,
  additionalText: PropTypes.any,
  info: PropTypes.string,
  titleLink: PropTypes.object,
  onLinkClick: PropTypes.func,
  style: PropTypes.object,
  children: PropTypes.any,
};

export default FieldWrapper;
