import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {getInvoices, getShippingMethods} from '../../api/helpers/integration';
import {showErrorNotificationV2} from '../../utils';
import {addBuilderCases} from '../utils';

export const fetchInvoices = createAsyncThunk(
  'fetchInvoices',
  async (payload: any) => {
    try {
      return await getInvoices(payload?.data);
    } catch (error) {
      showErrorNotificationV2(error, payload?.showNotification);
    }
  },
);
export const fetchShippingMethods = createAsyncThunk(
  'fetchShippingMethods',
  async (payload: any) => {
    try {
      return await getShippingMethods(payload?.data);
    } catch (error) {
      showErrorNotificationV2(error, payload?.showNotification);
    }
  },
);

export const integrationSlice = createSlice({
  name: 'integration',
  initialState: {
    invoices: {data: []},
    shippingMethods: {data: []},
  },
  reducers: {},
  extraReducers: builder => {
    addBuilderCases(builder, fetchInvoices, 'invoices');
    addBuilderCases(builder, fetchShippingMethods, 'shippingMethods');
  },
});
