import styled from 'styled-components';
export const Root = styled.div`
  .profile-menu-header {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 0 8px 0 20px;
    width: auto;
    max-width: 200px;
    height: 50px;
    padding-right: 30px;
    > svg {
      color: #6F6F6F;;
      font-size: 12px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }

    .profile-notification {
      position: absolute;
      top: 0;
      right: 0;
      width: 15px;
      height: 15px;
      background: #B12020;
      border-radius: 50%;
      text-align: center;
      color: #fff;
      font-size: 10px;
      line-height: 1.5;
    }

    .profile-avatar {
      display: inline-block;
      position: relative;
      vertical-align: middle;

      img {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        max-width: initial;
      }
    }

    .profile-menu-wrapper {
      position: absolute;
      width: 200px;
      top: 72px;
      left: 0;
      z-index: 1;
      padding: 20px;
      background: #fff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
      border-radius: 0px 0px 5px 5px;

      &:before {
        content: '';
        position: absolute;
        bottom: 100%;
        right: 7px;
        border-bottom: 15px solid #fff;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        filter: drop-shadow(0 -3px 3px rgba(0, 0, 0, 0.16))
      }

    }

    .profile-info {
      display: inline-block;
      vertical-align: middle;
      overflow: hidden;
      margin-left: 15px;

      .profile-name {
        font-size: 14px;
        color: #000;
        white-space: nowrap;
        max-width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .profile-company {
        font-weight: bold;
        font-size: 14px;
        color: #000;
        white-space: nowrap;
        max-width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .popup + .profile-menu-header{
    background-color: red;
  }
`;
