import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-start;
  //border-bottom: 1px solid $primaryColor;
  padding-bottom: 15px;

  .tab-item {
    padding: 0 30px;
    color: ${props => props.theme.blackColor};
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    position: relative;
    cursor: pointer;
    text-transform: capitalize;

    &:first-child {
      padding-left: 0;
    }

    &:before {
      position: absolute;
      bottom: -15px;
      left: 0;
      height: 2px;
      content: "";
      background-color: ${props => props.theme.primaryColor};
      transition: ${props => props.theme.transition};
      width: 0;
    }

    &.activated:before, &:hover:before {
      width: 100%;
    }

    &.current-tab {
      color: ${props => props.theme.primaryColor};
    }

    .tab-number {
      background: ${props => props.theme.lightGrayColor};
      border-radius: ${props => props.theme.borderRadiusCircle};
      color: #ffffff;
      display: inline-block;
      font-weight: bold;
      font-size: 14px;
      margin-right: 10px;
      text-align: center;
      width: 20px;
      height: 20px;
    }

    &.activated {
      .tab-number {
        background: ${props => props.theme.primaryColor};
      }
    }
  }
`;
