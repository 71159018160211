import styled from 'styled-components';
import {GlobalStylesProps, StylesBorderTable, Title} from '../../../../../layout/styled';

export const WindowContainer = styled.div`
  max-width: 1324px;
  width: 100%;
  margin: 20px auto 0;
  ${Title} {
    margin-bottom: 20px;
  }
`;
export const FormContainer = styled.div`
  //padding: 20px;
  background-color: white;
  border-radius: 10px;
  border: solid 1px ${props => props.theme.borderTables};

  .tabs-wrapper {
    margin-bottom: 0;
    padding: 20px 0 7px 40px;

    .tab-item {
      padding: 0 20px;

      :before {
        bottom: -7px;
      }

      &.current-tab {
        color: ${props => props.theme.blackColor};
      }
    }
  }
`;
export const StyledInput = styled.div`
  flex: 1;
`;
export const StyledInputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  > div:first-child {
    margin-right: 20px;
    width: 100%;
  }
`;

export const StyledAvatar = styled.div`
  margin-bottom: 40px;
`;

export const StyledButton = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;
`;

export const Row = styled.div<GlobalStylesProps>`
  ${StylesBorderTable};
  display: flex;
  flex-direction: row;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-right: 1px solid ${(props: any) => props.theme.borderColor};
  padding: 20px 40px;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px 40px;
`;

export const ButtonsArea = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
`;
export const SubscriptionItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const SubscriptionItem = styled.div`
  display: flex;
  align-items: baseline;
  padding-top: 5px;
  span {
    font-size: 16px;
  }
`;

export const CloseIcon = styled.div`
  cursor: pointer;
  margin-left: 20px;
  svg.icon {
    width: 20px;
    height: 20px;
    color: ${props => props.theme.primaryColor};
  }
`;
export const SaveButton = styled.div`
  margin-left: 20px;
  margin-right: 40px;
  margin-bottom: 20px;
`;
