import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import Select, {components} from 'react-select';
import {Checkbox} from '../Checkbox/Checkbox';
import {generalTheme as theme} from '../../../layout/theme';

const {primaryColor} = theme;

const Option = (props: any) => (
  <div>
    <components.Option {...props}>
      <Checkbox
        checked={props.isSelected}
        value={props.value}
        onChange={() => null}
        label={props.label}
      />
    </components.Option>
  </div>
);

// @ts-ignore
const MenuList = ({maxHeight, children}) => (
  <div className="menu-list" style={{overflowY: 'auto', maxHeight}}>
    {children}
  </div>
);

type DropdownTypes = {
  data: any;
  onChange: any;
  value: any;
  placeholder?: string;
  width?: number;
  height?: number;
  showAllLabel?: string;
  closeMenuOnSelect?: boolean;
  isMulti?: boolean;
  defaultValue?: any[] | object;
  hideSelectedOptions?: boolean;
  isSearchable?: boolean;
  backspaceRemovesValue?: boolean;
  controlShouldRenderValue?: boolean;
  maxMenuHeight?: number;
  containerStyle?: object;
  disabled?: boolean;
  getOptionLabel?: any;
  getOptionValue?: any;
  onInputChange?: Function;
  inputValue?: string;
  showItemsSelected?: any;
  onKeyDown?: any;
  fontSize?: number;
  withoutBorder?: string;
  filterOption?: any;
};

export const Dropdown: React.FC<DropdownTypes> = ({
  data,
  onChange,
  value,
  placeholder,
  width,
  height,
  showAllLabel,
  closeMenuOnSelect,
  isMulti,
  defaultValue = null,
  hideSelectedOptions = false,
  isSearchable = true,
  backspaceRemovesValue = false,
  controlShouldRenderValue = true,
  maxMenuHeight,
  containerStyle = {},
  disabled = false,
  getOptionLabel = (option: {name: string}) => option?.name,
  getOptionValue = (option: {id: number}) => option?.id,
  onInputChange,
  inputValue,
  showItemsSelected,
  onKeyDown,
  fontSize,
  withoutBorder,
  filterOption,
}) => {
  let isStringArray = false;
  if (data?.length && data?.[0] === String(data?.[0])) {
    data = data.map((i: any) => ({label: getOptionLabel(i), value: i}));
    value =
      value && Array.isArray(value)
        ? value.map(i => ({label: i, value: i}))
        : value
          ? {label: value, value: value}
          : value;
    getOptionLabel = (v: {label: any}) => v?.label;
    getOptionValue = (v: {value: any}) => v?.value;
    isStringArray = true;
  }
  const showAllItem = {name: showAllLabel, id: null};
  const currValue = useMemo(
    () => value || (showAllLabel ? showAllItem : null),
    [value],
  );
  const selected = useMemo(() => currValue, [currValue]);
  //const [selected, setSelected] = useState<any>(currValue);

  const MultiValue = (props: any) =>
    getOptionValue(props.data) ? (
      <components.MultiValue {...props}>
        <span>{getOptionLabel(props.data)}</span>
      </components.MultiValue>
    ) : null;

  const onSelect = (value: {length: number; map: Function; value: any}) => {
    //setSelected(value);
    onChange(
      isStringArray
        ? value?.length
          ? value.map((i: {value: any}) => i?.value)
          : value?.value
        : value,
    );
  };

  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      fontSize: fontSize || '16px',
      lineHeight: 1.5,
      color: state.isFocused ? primaryColor : '#1E1E1E',
      fontWeight: 600,
      backgroundColor: state.isFocused ? '#F7F7F7' : '#fff',
      cursor: 'pointer',
      padding: 5,
    }),
    control: (provided: any) => ({
      ...provided,
      cursor: 'pointer',
      minHeight: height || 38,
      height,
      borderColor: '#e6e6e6',
      backgroundColor: withoutBorder && 'transparent',
      border: withoutBorder && 'none',
      boxShadow: withoutBorder && 'none',
      fontSize: fontSize || '16px',
      '&:hover': {
        borderColor: withoutBorder && 'transparent',
      },
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: '#6f6f6f',
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      display: 'none',
      marginBottom: 0,
      marginTop: 0,
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      padding: height ? 5 : 10,
    }),
    menu: (provided: any) => ({
      ...provided,
      marginTop: 0,
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: '#dee8ff',
      borderRadius: 20,
      //border: `1px solid ${primaryColor}`,
      color: primaryColor,
      fontSize: fontSize || '16px',
    }),
    multiValueLabel: (provided: any[]) => ({
      ...provided,
      color: 'black',
    }),
    multiValueRemove: (provided: any[]) => ({
      ...provided,
      borderRadius: 13,
      fontSize: fontSize || '16px',
    }),
    menuList: (provided: any[]) => ({
      ...provided,
      fontSize: fontSize || '16px',
    }),
  };

  const options: any[] = showAllLabel ? [showAllItem, ...data] : data;

  return (
    <div
      className="rounded-select"
      style={{...containerStyle, width: width || '100%'}}>
      <Select
        value={selected}
        inputValue={inputValue}
        defaultValue={defaultValue}
        options={options}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        components={isMulti ? {Option, MultiValue, MenuList} : {MenuList}}
        placeholder={
          showItemsSelected
            ? `${selected.length} items selected`
            : selected || placeholder || 'Select ...'
        }
        openMenuOnFocus={false}
        cacheOptions
        //@ts-ignore
        styles={customStyles}
        onChange={onSelect}
        onInputChange={value => {
          if (onInputChange) {
            onInputChange(value);
          }
        }}
        closeMenuOnSelect={closeMenuOnSelect}
        isMulti={isMulti}
        hideSelectedOptions={hideSelectedOptions}
        backspaceRemovesValue={backspaceRemovesValue}
        controlShouldRenderValue={controlShouldRenderValue}
        maxMenuHeight={maxMenuHeight}
        isDisabled={disabled}
        isSearchable={isSearchable}
        autoComplete={isSearchable ? 'on' : 'off'}
        onKeyDown={onKeyDown}
        filterOption={filterOption}
      />
    </div>
  );
};
