import styled, {css, keyframes} from 'styled-components';
import {TipsWrapper} from '../../../layout/styled';

export const SidebarList = styled.div`
  height: 100%;
`;

export const iconsOpenedSidebar = css`
  background-color: unset;

  svg path {
    fill: ${props => props.theme.primaryColor};
  }
`;

export const iconsClosedSidebar = css`
  background-color: ${props => props.theme.primaryColor};
  svg path {
    fill: ${props => props.theme.whiteColor};
  }
`;

export const SidebarBtn = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${props => props.theme.primaryColor};
  border-radius: 50%;
  position: absolute;
  bottom: 30px;
  left: 20px;
  transition: all 0.4s;
  cursor: pointer;
  svg {
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
  }
`;

export const SidebarSubMenu = styled.div`
  visibility: hidden;
  opacity: 0;
  padding-left: 50px;
  height: 0;
`;

export const SubMenuItem = styled.div`
  position: relative;
  z-index: 5;

  a {
    font-weight: ${props => props.theme.fontWeightSemiBold};
    color: ${props =>
      props.selected ? props.theme.primaryColor : '#9e9ea6'} !important;
    text-decoration: none;
    line-height: 2;
    z-index: 5;

    &:hover {
      font-weight: ${props => props.theme.fontWeightBold};
      color: ${props => props.theme.primaryColor} !important;
    }
  }
  &:after {
    width: 100%;
    height: auto;
    left: -10px;
    position: absolute;
    z-index: 1;
    background-color: ${props => (props.selected ? '#EDEEEF' : 'transparent')};
  }
`;

export const SideBarItemTitle = styled.div`
  color: #000;
  font-size: 12px;
  line-height: 20px;
  width: 0;
  font-weight: bold;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
  white-space: nowrap;
`;

export const SideBarItemImg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: all 0.4s;
  width: 34px;
  height: 34px;
  position: relative;
  margin-bottom: 1px;

  svg {
    width: 20px;
    height: 20px;
    fill: ${props => props.theme.primaryColor};
  }

  img {
    width: 20px;
    height: 20px;
  }
`;
export const SidebarElemList = styled.div`
  width: 100%;
  &:first-child {
    padding-top: 20px;
  }
`;

export const SidebarItem = styled.div`
  transition: all ease-in-out 0.2s;
  ${props =>
    props.$isOpenedSidebar
      ? props.$active
        ? css`
            background-color: #edeeef;
            color: #2f80ec;
            border-left: solid 5px #2f80ec;
            transition: 0.4s;
          `
        : css`
            background-color: transparent;
            &:hover {
              background-color: #edeeef;
              color: #2f80ec;
              border-left: solid 5px #2f80ec;
              transition: 0.4s;
            }
          `
      : props.$active
        ? css`
            padding: 0 5px;
            ${SideBarItemImg} {
              ${iconsClosedSidebar}
            }
          `
        : css`
            :hover {
              background-color: transparent;
              border-left: none;
            }
            ${SideBarItemImg}:hover {
              ${iconsClosedSidebar}
            }
          `};

  ${TipsWrapper} {
    width: 40px;
    margin-right: 0;

    span {
      bottom: 25%;
      left: 120%;
      z-index: 5;

      &:hover {
        visibility: hidden;
        opacity: 0;
      }
    }
  }

  a {
    padding: ${props => (props?.$active ? '0 4px' : '0 10px')};
    display: flex;
    justify-content: ${props =>
      props.$isOpenedSidebar ? 'flex-start' : 'center'};
    align-items: center;
    gap: 4px;
    transition: 0.4s;
    text-decoration: none;

    ${SideBarItemTitle} {
      color: ${props => (props?.$active ? '#2F80EC' : '#000')};
    }

    &:hover {
      text-decoration: none;
      transition: 0.4s;
    }
  }
`;

export const SettingBlock = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;

export const changeWidthSideBar = keyframes`
    0%{
       width:80px;
    }
    100%{
        width:260px
    }
`;
export const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${props =>
    props.$activeSidebar &&
    css`
      border-right: solid 1px ${props => props.theme.primaryColor};
      box-shadow: 1px 0 3px #5e9aeb;
    `}

  ${SidebarList} {
    row-gap: 0;
  }

  ${props =>
    props.$isOpen
      ? css`
          animation: ${changeWidthSideBar} 0.3s linear;
          width: 260px;
          height: 100%;
          border-right: solid 1px #00000029;

          ${SidebarBtn} {
            transform: rotate(180deg);
          }
          ${SidebarList} {
            ${SidebarItem} {
              a {
                ${SideBarItemTitle} {
                  opacity: 1;
                  visibility: visible;
                  transition-delay: 0.4s;
                }
              }
              ${TipsWrapper}:hover span {
                visibility: hidden;
                opacity: 0;
              }
              ${SideBarItemImg}:hover {
                background-color: unset;
                svg path {
                  fill: ${props => props.theme.primaryColor};
                }
              }
            }
          }
          ${SidebarSubMenu} {
            height: fit-content;
            opacity: 1;
            visibility: visible;
            transition-delay: 0.4s;
          }
          ${SettingBlock} {
            ${SideBarItemTitle} {
              opacity: 1;
              visibility: visible;
              transition-delay: 0.4s;
            }
          }
        `
      : css`
          width: 80px;
          height: 100%;
          position: relative;
          transition: all 0.6s;
          border-right: solid 1px #00000029;
        `}
`;

export const IconOpenMenu = styled.figure`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: ${props => (props.$isOpened ? '-20px' : '-21px')};
  visibility: ${props =>
    props.$isOpened && !props.$activeSidebar ? 'hidden' : 'visible'};
  opacity: ${props => (props.$isOpened && !props.$activeSidebar ? '0' : '1')};
  z-index: 6;
  transform: translateY(-50%);
  margin: 0;
  padding: 10px;
  transition: all ease-in-out 0.4s;

  :hover {
    svg {
      background-color: ${props => props.theme.primaryColor};
      color: ${props => props.theme.whiteColor};
    }
  }

  svg {
    display: flex;
    align-items: center;
    transform: ${props => props.$isOpened && 'rotate(180deg)'};
    background-color: ${props => props.theme.whiteColor};
    color: ${props => props.theme.blackColor};
    border-radius: 20px;
    height: 10px;
    width: 10px;
    padding: 5px;
    border: solid 1px ${props => props.theme.borderTables};
    transition: all ease-in-out 0.4s;
  }
`;

/**************************************************************
                    Settings Sidebar
 **************************************************************/

export const HeaderTitleSettings = styled.h6`
  height: 100%;
  margin-bottom: 0;
`;

export const SideBarSettings = styled(SidebarItem)`
  padding: 0;
  margin: 0;

  a {
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    h6 {
      margin-left: 30px;
      margin-top: 0;
      padding-top: 0;
      color: ${props => props.theme.primaryColor};
      font-size: 14px;
      font-weight: bold;
    }
  }
`;

export const TitleSettingsSideBar = styled.h5`
  padding: 12% 0 12% 10%;
  font-weight: bold;
  font-size: 18px;
  margin: 0;
`;

export const TipsWrapperSettings = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 40px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};

  &:hover span {
    visibility: visible;
    opacity: 1;
  }
  span {
    width: 79px;
    bottom: -35px;
    left: -20px;
    min-width: ${props => (props.tipWidth ? props.tipWidth : 60)}px;
    border-radius: 5px;
    text-align: center;
    border: 1px solid ${props => props.theme.borderColor};
    background: #fff;
    color: #222;
    font-size: 11px;
    font-weight: 600;
    position: absolute;
    z-index: 5;
    padding: 5px;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
  }
  ${props => props.theme.screenSize.lg} {
    width: 25px;
  }
`;

export const SideBarSettingImg = styled(SideBarItemImg)`
  margin-right: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SideBarSetting = styled.li`
  list-style-type: none;
  background-color: #fff;
  border-top: ${props =>
    props.$isOpenedSidebar ? `solid 1px #e0e0e1` : 'none'};
  transition: 0.4s;
    // padding: ${props => (props.$isOpenedSidebar ? '0' : '6.5% 5%')};
  &:last-child {
    border-bottom: solid 1px #e0e0e1;
  }

  ${props =>
    props.$isOpenedSidebar
      ? css`
          width: 100%;
          transition: 0.4s;
          a {
            transition: 0.4s;
            padding: ${props => (props.$isOpenedSidebar ? '0% 5%' : '6.5% 5%')};
          }

          & > :hover {
            background-color: #e0e0e1;
            border-left: solid 4px ${props => props.theme.primaryColor};
            transition: 0.4s;
          }
          ${TipsWrapper}:hover span,
            ${TipsWrapperSettings}:hover span {
            visibility: hidden;
            opacity: 0;
            transition: 0.4s;
          }
        `
      : css`
          a > :hover {
            background: ${props => props.theme.primaryColor} !important;
            svg {
              background: none !important;
              fill: #e0e0e1 !important;
              color: #e0e0e1 !important;
            }
            path {
              color: #e0e0e1 !important;
              fill: #e0e0e1 !important;
            }
          }
        `};

  ${props =>
    props.$isOpenedSidebar &&
    props.$numberTab &&
    css`
      background-color: #e0e0e1;
      border-left: solid 4px #4181e5;
      a {
        border-left: none !important;
        padding: 0% 3% !important;
      }
    `}

  ${TipsWrapperSettings} span:hover{
    visibility: hidden;
    opacity: 0;
  }

  a {
    color: #000;
    display: flex;
    justify-content: ${props =>
      props.$isOpenedSidebar ? 'flex-start' : 'center'};
    align-items: center;
    text-decoration: none;
    :hover {
      text-decoration: none;
    }
  }

  ${SideBarSettingImg} {
    ${props =>
      props.$isOpenedSidebar
        ? css`
            :hover {
              ${iconsOpenedSidebar}
            }
          `
        : props.$numberTab
          ? css`
              ${iconsClosedSidebar};
            `
          : css`
              :hover {
                ${iconsClosedSidebar};
              }
            `}
  };
}
`;

export const SettingsSubListWr = styled.div`
  padding-left: 3px;
  //border-bottom: 1px solid ${props => props.theme.borderGray};
  padding-bottom: 5px;
`;

export const SettingsPopupWrapper = styled.aside`
  width: ${props => (props.$isOpenedSidebar ? '260px' : '79px')};
  height: 95vh;
  position: relative;
  transition: all 0.4s;
  background-color: #ffffff;
  overflow: hidden auto;
  padding-top: 20px;
  // padding-top: ${props => (props.$isOpenedSidebar ? '0' : '20px')};
  border: ${props =>
    props.$isOpenedSidebar && `solid 1px ${props.theme.borderGray}`};
  border-top: none;
  border-bottom: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
    appearance: none;
  }

  ul {
    padding: 0;
  }

  ${TitleSettingsSideBar} {
    display: ${props => (props.$isOpenedSidebar ? 'flex' : 'none')};
  }
`;

export const SideBarSettingTitle = styled.h5`
  margin-bottom: 0;
  margin-top: 0;
  text-decoration: none;
  font-size: 12px;
  font-weight: bold;
  display: ${props => (props.$isOpenedSidebar ? 'flex' : 'none')};
`;
