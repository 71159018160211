import profileIcon from '../../../assets/svg/header/Profile-icon.svg';
import helpIcon from '../../../assets/svg/header/help-icon.svg';
import logOutIcon from '../../../assets/svg/header/log-out.svg';
import support from '../../../assets/svg/header/support.svg';
import {PROFILE, SUPPORT} from '../../../router/routerUrl';

export const profileMenuList = [
  {
    id: 1,
    icon: profileIcon,
    link: PROFILE,
    title: 'Profile',
  },
  {
    id: 2,
    icon: helpIcon,
    link: SUPPORT,
    title: 'Help Center',
  },
  {
    id: 3,
    icon: logOutIcon,
    link: '',
    title: 'Log Out',
  },
];
