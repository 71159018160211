import React, {useState, useEffect, FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {ThemeProvider} from 'styled-components';
import './utils/i18n';
import {generalTheme} from './layout/theme';
import {GlobalStyle} from './layout/styled';
import {RouterComponent} from './router/Router';
import Login from './domains/Login/Login';
import ForgotPassword from './domains/Login/ForgotPassword';
import ResetPassword from './domains/Login/ResetPassword';
import {fetchCurrentAuthUser} from './store/auth/authSlice';
import {withNotification} from './hocs';
import {AppDispatch} from './store';

const defaultLocale = 'en_US';

const isAuthorized = () => {
  // eslint-disable-next-line no-undef
  const tokenString = localStorage.getItem('token');
  if (tokenString) {
    const token = JSON.parse(tokenString);
    return Boolean(token && token.access_token);
  }
  return false;
};

const App: FC<any> = props => {
  const {showNotification} = props;
  const [isAuth, setIsAuth] = useState(isAuthorized());
  // eslint-disable-next-line no-undef
  const [locale, setLocale] = useState<any>(localStorage.getItem('locale'));
  const {i18n} = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const {data: userProfile} = useSelector(
    (state: any) => state.auth.currentUser,
  );

  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (!localStorage.getItem('locale')) {
      // eslint-disable-next-line no-undef
      localStorage.setItem('locale', defaultLocale);
    }
  }, []);

  useEffect(() => {
    i18n.changeLanguage(locale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  useEffect(() => {
    if (isAuth && !userProfile) {
      dispatch(fetchCurrentAuthUser({showNotification}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  const layout = userProfile ? <RouterComponent /> : null;

  const checkUnauthenticatedScreen = () => {
    const loginDialog = (
      <Login
        onResult={(result: boolean) => {
          setIsAuth(result);
        }}
      />
    );
    const forgotPasswordPage = <ForgotPassword />;
    const resetPasswordPage = <ResetPassword />;
    const path = location.pathname;

    switch (path) {
      case '/':
        return loginDialog;
      case '/forgot-password':
        return forgotPasswordPage;
      case '/reset-password':
        return resetPasswordPage;
      default:
        return loginDialog;
    }
  };

  return (
    <ThemeProvider theme={generalTheme}>
      <GlobalStyle />
      {isAuth ? layout : checkUnauthenticatedScreen()}
    </ThemeProvider>
  );
};

export default withNotification(App);
