import React, {FunctionComponent} from 'react';
import * as S from './styled';

type DropdownWithRadioBtnProps = {
  data: any[];
  width: string | number;
  onChange?: Function;
  filterName?: string;
  selectValue?: Function;
};

export const DropdownWithRadioBtn: FunctionComponent<
  DropdownWithRadioBtnProps
> = ({data, width, onChange, filterName = 'sort_by', selectValue}) => {
  return (
    <S.RadioWrapper
      // @ts-ignore
      width={width}>
      <form>
        {data?.map(({id, name, checked, value}) => (
          <label key={id}>
            <input
              type="radio"
              name="options"
              value={id}
              checked={selectValue === value || checked}
              //@ts-ignore
              onChange={() => onChange(filterName, value)}
            />
            <span>{name}</span>
          </label>
        ))}
      </form>
    </S.RadioWrapper>
  );
};
