import defaultBackground from '../../assets/images/login/login.png';
import logoTixxio from '../../assets/images/logo_tixxio.png';

export const defaultImages = {
  background: defaultBackground,
  logo: logoTixxio,
};


export const images = (() => {
  switch (process.env.REACT_APP_APPSCHEME) {
    default:
      return defaultImages;
  }
})();
