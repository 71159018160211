import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  rootNotification = (
    <div style={{color: 'red', fontWeight: 'bold'}}>Something went wrong.</div>
  );

  static getDerivedStateFromError(error) {
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return this.props?.notification || this.rootNotification;
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
