import {showErrorNotificationV2} from '../utils';

export const addBuilderCases = (builder: any, fetchFunc: any, name: string) => {
  builder
    .addCase(fetchFunc.pending, (state: any, action: any) => {
      state[name] = {...state[name], loading: true};
    })
    .addCase(fetchFunc.fulfilled, (state: any, action: any) => {
      state[name] = {...action?.payload, loading: false};
    });
};

export const asyncThunkHelper = async (
  payload: any,
  type: string,
  actions: any,
) => {
  let data: any;
  try {
    data = actions?.[type] ? await actions[type]() : await actions?.default();
    if (payload?.onSuccess) {
      payload.onSuccess(data?.data || data || undefined);
    }
    if (payload?.onSuccessId) {
      payload.onSuccessId(data?.id || data?.data?.id);
    }
    return data;
  } catch (error) {
    if (payload?.onError) {
      payload.onError(error);
    }
    showErrorNotificationV2(error, payload?.showNotification);
    return {data: undefined};
  }
};
