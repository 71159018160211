import React, {FunctionComponent, useRef} from 'react';
import {useParams} from 'react-router-dom';
import {withNotification} from '../../../../hocs/withNotification';
import {ClientTypeForm} from '../components/forms/ClientTypeForm';

const ClientTypeView: FunctionComponent<any> = props => {
  const {showNotification} = props;
  const superUserFormRef = useRef(null);
  const {id} = useParams();

  return (
    <ClientTypeForm
      action="view"
      clientTypeId={id}
      ref={superUserFormRef}
      showNotification={showNotification}
      withSave
    />
  );
};

export default withNotification(ClientTypeView);
