import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {addBuilderCases, asyncThunkHelper} from '../utils';
import {
  createIssue,
  createIssueMessage,
  deleteIssue,
  getIssue,
  getIssueMessage,
  getIssuePriorities,
  getIssues,
  getIssueStatuses,
  getIssueTypes,
  updateIssueMessage,
  updateIssue,
  deleteIssueMessage,
} from '../../api/helpers/support';

export const fetchIssues = createAsyncThunk(
  'fetchIssues',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: []}),
      default: async () => getIssues(payload?.data),
    }),
);

export const fetchIssuePriorities = createAsyncThunk(
  'fetchIssuePriorities',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: []}),
      default: async () => getIssuePriorities(payload?.data),
    }),
);

export const fetchIssueTypes = createAsyncThunk(
  'fetchIssueTypes',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: []}),
      default: async () => getIssueTypes(payload?.data),
    }),
);

export const fetchIssueStatuses = createAsyncThunk(
  'fetchIssueStatuses',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: []}),
      default: async () => getIssueStatuses(payload?.data),
    }),
);

export const fetchIssue = createAsyncThunk(
  'fetchIssue',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: null}),
      default: async () => getIssue(payload?.id),
      create: async () => createIssue(payload?.data),
      update: async () => updateIssue(payload?.id, payload?.data),
      delete: async () => deleteIssue(payload?.id),
    }),
);

export const fetchIssueMessage = createAsyncThunk(
  'fetchIssueMessage',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: null}),
      default: async () =>
        getIssueMessage(payload?.issueId, payload?.messageId),
      create: async () => createIssueMessage(payload?.id, payload?.data),
      update: async () =>
        updateIssueMessage(payload?.issueId, payload?.messageId, payload?.data),
      delete: async () =>
        deleteIssueMessage(payload?.issueId, payload?.messageId),
    }),
);

export const supportSlice = createSlice({
  name: 'support',
  initialState: {
    issues: {data: []},
    issue: {data: null},
    messages: {data: []},
    message: {data: null},
    priorities: {data: []},
    statuses: {data: []},
    types: {data: []},
  },
  reducers: {},
  extraReducers: (builder: any): any => {
    addBuilderCases(builder, fetchIssues, 'issues');
    addBuilderCases(builder, fetchIssue, 'issue');
    addBuilderCases(builder, fetchIssuePriorities, 'priorities');
    addBuilderCases(builder, fetchIssueTypes, 'types');
    addBuilderCases(builder, fetchIssueStatuses, 'statuses');
    addBuilderCases(builder, fetchIssueMessage, 'message');
  },
});
