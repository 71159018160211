import React from 'react';
import { AiOutlineCalendar } from 'react-icons/ai';
import * as S from './styled';

export const DatePickerInput = React.forwardRef(
    ({ value, onClick, placeholder, withIcon }, ref) => {
        return (
            <S.DatePickerWrapper>
                <button
                    type="button"
                    className={`date-picker-input${
                        withIcon ? ' with-icon' : ''
                    }`}
                    onClick={onClick}
                >
                    <span>{value || placeholder}</span>
                    {withIcon && (
                        <div className="date-picker-input-icon">
                            <AiOutlineCalendar />
                        </div>
                    )}
                </button>
            </S.DatePickerWrapper>
        );
    }
);

DatePickerInput.propTypes = {};

export default DatePickerInput;
