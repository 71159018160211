import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {
  getClient,
  createClient,
  updateClient,
  getClients,
  deleteClient,
  getClientStatuses,
  getClientTypes,
  getUsers,
  suspendClient,
  unsuspendClient,
  createUser,
  deleteUser,
  updateUser,
  getUser,
  getShop,
  updateShop,
  createShop,
  getShops,
  deleteShop,
  getApplications,
  getApplication,
  createApplication,
  updateApplication,
  deleteApplication,
  getSettings,
  createSettings,
  updateSettings,
  getApplicationTypes,
  getClientAddresses,
  getAddressTypes,
  getClientSubscriptionBundles,
  addNewSubscriptionBundleToClient,
  getClientSubscriptionItems,
  addSubscriptionItemToClientBundle,
  updateUserPermissions,
  getUserPermissions,
  updateClientType,
  getClientType,
  deleteClientType,
  createClientType,
  getShopSettings,
  createShopSettings,
  updateShopSettings,
  updateSubscriptionItemToClientBundle,
  getClientLimitsStatus,
  updateClientLimitsStatus,
  getClientShipmentZones,
  updateClientShipmentZone,
  getClientShipmentRates,
  updateClientShipmentRate,
  getShopClientAddresses,
  assignShopClientAddress,
  removeShopClientAddress,
  getAddresses,
  createAddress,
  updateAddress,
  deleteAddress,
  getSuppliers,
  updateSuppliers,
} from '../../api/helpers/client';
import {showErrorNotificationV2} from '../../utils';
import {addBuilderCases, asyncThunkHelper} from '../utils';
import {updateSupplier} from '../../api/helpers/marketing';

export const fetchClients = createAsyncThunk(
  'fetchClients',
  async (payload: any) => {
    try {
      return await getClients(payload?.data);
    } catch (error) {
      showErrorNotificationV2(error, payload?.showNotification);
    }
  },
);

export const fetchClient = createAsyncThunk(
  'fetchClient',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: undefined}),
      default: async () => await getClient(payload?.id),
      create: async () => await createClient(payload?.data),
      update: async () => await updateClient(payload?.id, payload?.data),
      delete: async () => await deleteClient(payload?.id),
      suspend: async () => await suspendClient(payload?.id),
      unsuspend: async () => await unsuspendClient(payload?.id),
    }),
);

export const fetchClientStatuses = createAsyncThunk(
  'fetchClientStatuses',
  async (payload: any) => {
    try {
      return await getClientStatuses(payload?.data);
    } catch (error) {
      console.log('error', error);
      showErrorNotificationV2(error, payload?.showNotification);
    }
  },
);

export const fetchClientTypes = createAsyncThunk(
  'fetchClientTypes',
  async (payload: any) => {
    try {
      return await getClientTypes(payload?.data);
    } catch (error) {
      console.log('error', error);
      showErrorNotificationV2(error, payload?.showNotification);
    }
  },
);
export const fetchClientType = createAsyncThunk(
  'fetchClientType',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: undefined}),
      create: async () => await createClientType(payload?.data),
      update: async () => await updateClientType(payload?.id, payload?.data),
      delete: async () => await deleteClientType(payload?.id),
      default: async () => await getClientType(payload?.id),
    }),
);

export const fetchUsers = createAsyncThunk(
  'fetchUsers',
  async (payload: any) => {
    try {
      return await getUsers(payload);
    } catch (error) {
      showErrorNotificationV2(error, payload?.showNotification);
    }
  },
);
export const fetchUser = createAsyncThunk(
  'fetchUser',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: undefined}),
      create: async () => await createUser(payload?.id, payload?.data),
      update: async () =>
        await updateUser(payload?.id, payload?.uid, payload?.data),
      delete: async () => await deleteUser(payload?.id, payload?.uid),
      default: async () => await getUser(payload?.id, payload?.uid),
    }),
);

export const fetchShops = createAsyncThunk(
  'fetchShops',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: []}),
      default: async () => await getShops(payload?.data),
    }),
);

export const fetchShop = createAsyncThunk(
  'fetchShop',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: undefined}),
      default: async () => await getShop(payload?.id),
      create: async () => await createShop(payload?.data),
      update: async () => await updateShop(payload?.id, payload?.data),
      delete: async () => await deleteShop(payload?.id),
    }),
);

export const fetchApplications = createAsyncThunk(
  'fetchApplications',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: []}),
      default: async () => await getApplications(payload?.data),
    }),
);

export const fetchApplication = createAsyncThunk(
  'fetchApplication',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: undefined}),
      default: async () => await getApplication(payload?.id),
      create: async () => await createApplication(payload?.data),
      update: async () => await updateApplication(payload?.id, payload?.data),
      delete: async () => await deleteApplication(payload?.id),
    }),
);

export const fetchSettings = createAsyncThunk(
  'fetchSettings',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: []}),
      default: async () => await getSettings(payload?.id, payload?.data),
      create: async () => await createSettings(payload?.id, payload?.data),
      update: async () => await updateSettings(payload?.id, payload?.data),
    }),
);

export const fetchShopSettings = createAsyncThunk(
  'fetchShopSettings',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: []}),
      default: async () => await getShopSettings(payload?.id, payload?.data),
      create: async () => await createShopSettings(payload?.id, payload?.data),
      update: async () => await updateShopSettings(payload?.id, payload?.data),
    }),
);
export const fetchApplicationTypes = createAsyncThunk(
  'fetchApplicationTypes',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: []}),
      default: async () => await getApplicationTypes(payload?.data),
    }),
);

export const fetchAddressTypes = createAsyncThunk(
  'fetchAddressTypes',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: []}),
      default: async () => await getAddressTypes(payload?.data),
    }),
);

export const fetchUserAddresses = createAsyncThunk(
  'fetchUserAddresses',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: undefined}),
      default: async () => await getClientAddresses(payload?.id, payload.data),
    }),
);
export const fetchClientSubscriptionBundles = createAsyncThunk(
  'fetchClientSubscriptionBundles',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: undefined}),
      default: async () =>
        await getClientSubscriptionBundles(payload?.id, payload?.data),
      create: async () =>
        await addNewSubscriptionBundleToClient(payload?.id, payload?.data),
    }),
);
export const fetchClientSubscriptions = createAsyncThunk(
  'fetchClientSubscriptions',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: undefined}),
      default: async () =>
        await getClientSubscriptionItems(payload?.id, payload?.data),
      create: async () =>
        await addSubscriptionItemToClientBundle(payload?.id, payload?.data),
      update: async () =>
        await updateSubscriptionItemToClientBundle(
          payload?.id,
          payload?.subid,
          payload?.data,
        ),
    }),
);

export const fetchUserPermissions = createAsyncThunk(
  'fetchUserPermissions',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: null}),
      update: async () =>
        await updateUserPermissions(payload?.id, payload?.data),
      default: async () => await getUserPermissions(payload?.id),
    }),
);

export const fetchClientLimits = createAsyncThunk(
  'fetchClientLimits',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: null}),
      update: async () =>
        await updateClientLimitsStatus(payload?.id, payload?.data),
      default: async () => await getClientLimitsStatus(payload?.id),
    }),
);
export const fetchShippingZones = createAsyncThunk(
  'fetchShippingZones',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: null}),
      update: async () =>
        await updateClientShipmentZone(payload?.id, payload?.data),
      default: async () => await getClientShipmentZones(payload?.id),
    }),
);
export const fetchClientShipmentRates = createAsyncThunk(
  'fetchClientShipmentRates',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: null}),
      update: async () =>
        await updateClientShipmentRate(payload?.id, payload?.data),
      default: async () =>
        await getClientShipmentRates(payload?.id, payload?.data),
    }),
);
export const fetchSuppliers = createAsyncThunk(
  'fetchSuppliers',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: null}),
      update: async () => await updateSuppliers(payload?.id, payload?.data),
      default: async () => await getSuppliers(payload?.id),
    }),
);

export const fetchShopClientAddresses = createAsyncThunk(
  'fetchShopClientAddresses',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: []}),
      default: async () => await getShopClientAddresses(payload?.id),
      assign: async () =>
        await assignShopClientAddress(payload?.shopId, payload?.addressId),
      remove: async () =>
        await removeShopClientAddress(payload?.shopId, payload?.addressId),
    }),
);
export const fetchAddresses = createAsyncThunk(
  'fetchAddresses',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: []}),
      create: async () => await createAddress(payload?.data),
      update: async () => await updateAddress(payload?.id, payload?.data),
      delete: async () => await deleteAddress(payload?.id),
      default: async () => await getAddresses(payload.data),
    }),
);

export const clientSlice = createSlice({
  name: 'client',
  initialState: {
    client: {data: null},
    clients: {data: []},
    clientStatuses: {data: []},
    clientTypes: {data: []},
    clientType: {data: null},
    users: {data: []},
    user: {data: null},
    shops: {data: []},
    shop: {data: null},
    addresses: {data: []},
    applications: {data: []},
    application: {data: null},
    settings: {data: []},
    shopSettings: {data: []},
    shopAddresses: {data: []},
    applicationTypes: {data: []},
    userAddresses: {data: []},
    addressTypes: {data: []},
    subscriptionBundles: {data: []},
    subscriptionItems: {data: []},
    userPermissions: {data: null},
    limitsStatus: {data: null},
    shippingZones: {data: []},
    shippingRates: {data: []},
    clientPagination: {data: []},
    suppliers: {data: []},
  },
  reducers: {
    setPagination: (state, action) => {
      state.clientPagination.data = action.payload;
    },
  },
  extraReducers: (builder: any): any => {
    addBuilderCases(builder, fetchClients, 'clients');
    addBuilderCases(builder, fetchClient, 'client');
    addBuilderCases(builder, fetchClientStatuses, 'clientStatuses');
    addBuilderCases(builder, fetchClientTypes, 'clientTypes');
    addBuilderCases(builder, fetchClientType, 'clientType');
    addBuilderCases(builder, fetchUsers, 'users');
    addBuilderCases(builder, fetchUser, 'user');
    addBuilderCases(builder, fetchShops, 'shops');
    addBuilderCases(builder, fetchShop, 'shop');
    addBuilderCases(builder, fetchApplications, 'applications');
    addBuilderCases(builder, fetchApplication, 'application');
    addBuilderCases(builder, fetchSettings, 'settings');
    addBuilderCases(builder, fetchShopSettings, 'shopSettings');
    addBuilderCases(builder, fetchApplicationTypes, 'applicationTypes');
    addBuilderCases(builder, fetchUserAddresses, 'userAddresses');
    addBuilderCases(builder, fetchAddressTypes, 'addressTypes');
    addBuilderCases(
      builder,
      fetchClientSubscriptionBundles,
      'subscriptionBundles',
    );
    addBuilderCases(builder, fetchClientSubscriptions, 'subscriptionItems');
    addBuilderCases(builder, fetchUserPermissions, 'userPermissions');
    addBuilderCases(builder, fetchClientLimits, 'limitsStatus');
    addBuilderCases(builder, fetchShippingZones, 'shippingZones');
    addBuilderCases(builder, fetchClientShipmentRates, 'shippingRates');
    addBuilderCases(builder, fetchShopClientAddresses, 'shopAddresses');
    addBuilderCases(builder, fetchAddresses, 'addresses');
    addBuilderCases(builder, fetchSuppliers, 'suppliers');
  },
});

export const {setPagination} = clientSlice.actions;
