import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {defaultTheme} from '../../../layout/theme';
import * as S from './styled';

const {primaryColor} = defaultTheme;

export const NotificationPopup = ({message, notFixed, isError}) => {
  const styles = {
    position: !notFixed ? 'fixed' : 'relative',
    top: !notFixed ? '175px' : 'auto',
    right: !notFixed ? '0' : 'auto',
    zIndex: 110,
  };

  const iconStyles = {
    color: !isError ? primaryColor : '#b12020',
  };

  return (
    <S.Root style={styles}>
      <FontAwesomeIcon icon={faExclamationCircle} style={iconStyles} />
      {message}
    </S.Root>
  );
};
