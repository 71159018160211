import styled from 'styled-components';

export const ColorPickerWrStyled = styled.div`
  position: relative;
`;

export const ColorPickerStyled = styled.div`
  min-width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: ${props => props.theme.borderRadiusClassic};
  background-color: ${props => (props.color ? props.color : '#000')};
  border: 1px solid lightgray;
`;

export const ColorPickerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  width: 100%;
  ${props => props.theme.screenSize.md} {
    margin-top: 7px;
  }
`;

export const ColorPickerModalStyled = styled.div`
  position: absolute;
  bottom: 100%;
  z-index: 3;
`;
