import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;

  .checkbox-icon {
    width: 14px;
    height: 14px;
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    //padding: 1px;

    .inner {
      &.checked {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 2px;
        background-color: ${props => props.theme.primaryColor};
      }
    }
  }

  label.MuiFormControlLabel-root {
    margin: 0;

    span.MuiCheckbox-root {
      padding: 0;
    }

    span.checkbox-label {
      margin-left: 10px;
      font-size: ${props => props.theme.fontSizeSmall};
    }

    span.MuiIconButton-label {
      background-color: white;
    }
  }

  &.customized {
    color: ${props => props.theme.textInputColor};

    label.MuiFormControlLabel-root {
      position: relative;

      span.MuiButtonBase-root {
        position: absolute;
        top: 1px;
        left: 1px;
        right: 1px;
        bottom: 1px;
        visibility: hidden;
      }

      span.checkbox-label {
        border: 1px solid ${props => props.theme.borderColor};
        display: inline-block;
        border-radius: ${props => props.theme.borderRadiusClassic};
        min-width: 40px;
        line-height: 40px;
        margin-left: 0;
        text-align: center;
        transition: ${props => props.theme.transition};
        cursor: pointer;

        &.checked,
        &:hover {
          border-color: ${props => props.theme.primaryColor};
          background-color: ${props => props.theme.primaryColor};
          color: ${props => props.theme.whiteColor};
        }
      }
    }
  }
`;
