import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import Layout from '../layout/Layout';

export function withLayout(WrappedComponent) {
  return props => {
    const {t} = useTranslation(['translation']);
    const [title, setTitle] = useState(t('Some page'));
    const [showDescription, setShowDescription] = useState(false);
    const [uniquePath, setUniquePath] = useState(null);
    const [chatDisplay, setChatDisplay] = useState(false);
    const location = useLocation();
    const {data: profile} = useSelector(state => state.auth.currentUser);

    const getClient = () => {
      const name = `${profile?.name}`;
      return {
        name,
        companyName: profile?.account?.companyname,
        avatar: profile?.account?.mediafileinfo?.url,
        id: profile?.id,
      };
    };

    const wrappedComponent = (
      <WrappedComponent
        {...props}
        setTitle={setTitle}
        setShowDescription={setShowDescription}
        // setUser={setUser}
        setUniquePath={setUniquePath}
        setChatDisplay={setChatDisplay}
      />
    );

    return location.pathname === '/login' ? (
      wrappedComponent
    ) : (
      <Layout
        {...props}
        title={title}
        showDescription={showDescription}
        user={getClient()}
        uniquePath={uniquePath}
        chatDisplay={chatDisplay}
        key={getClient().id}>
        {wrappedComponent}
      </Layout>
    );
  };
}
