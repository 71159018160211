import React from 'react';
import {LayoutAuthPages, ForgotPasswordForm} from './components';
import {withNotification} from '../../hocs/withNotification';
import {apiUrl} from '../../utils';
import {handleError} from '../../utils';

const ForgotPassword = ({showNotification}) => {
  const forgotPasswordApiCall = async email => {
    let url = `${apiUrl}/v1/login/forgot`;
    let header = {};
    try {
      header['Content-type'] = 'application/json';
      header['Accept'] = 'application/json';
      let requestinfo = {
        method: 'POST',
        headers: header,
        body: JSON.stringify({email}),
      };
      return await fetch(url, requestinfo)
        .then(response => response.json())
        .then(json => json)
        .catch(error => {
          handleError('Fetch Error[BaseClass:', error);
        });
    } catch (l_error) {
      handleError(url, ' eroor: ', l_error, ' header: ', header);
    }
    return null;
  };

  const onSubmit = data => {
    const {email} = data;
    forgotPasswordApiCall(email)
      .then(response => {
        const {status, success, error} = response;
        const {message, is_error} = status || {};
        showNotification({
          message: message || success || error,
          isError: is_error || Boolean(error),
        });
      })
      .catch(error => {
        showNotification({
          message: String(error),
          isError: true,
        });
      });
  };
  return (
    <LayoutAuthPages>
      <ForgotPasswordForm onSubmitHandler={data => onSubmit(data)} />
    </LayoutAuthPages>
  );
};

export default withNotification(ForgotPassword);
