export const ERROR_REQUIRED_TEXT = 'This field is required';
export const EMAIL_IS_INVALID = 'Email field is invalid';
export const PASSWORD_DOES_NOT_MATCH = 'Password does not match';
export const PASSWORD_IS_INVALID = 'Password length must be more 8 symbols';
export const ERROR_SMALLER_THEN_TILL_TEXT =
  'This field must be smaller then till field';
// export const ERROR_SMALLER_THEN_END_DATE_TEXT =
//     'This field must be smaller then start date field';
export const PHONE_IS_INVALID = 'Phone number is invalid';
export const IBAN_IS_INAVLID = 'IBAN number is invalid';
// export const IP_IS_INVALID = 'IP is invalid';
export const ERROR_BIGGER_THEN_ZERO_TEXT = 'This field must be bigger then 0';
export const ERROR_BIGGER_THEN_ONE_TEXT = 'This field must be bigger then 1';
// export const SELECT_START_IS_REQUIRED = 'Select start is required';
// export const SELECT_END_IS_REQUIRED = 'Select end is required';
export const ERROR_SELECT_AT_LEAST_ONE_ITEM = 'Select at least one item';
export const ERROR_AT_LEAST_ONE_CHECKED_TEXT =
  'At least one checkbox must be selected';
export const ERROR_NUMERIC_TEXT = 'This field must be number';
export const ERROR_SLUG_TEXT = 'Slug is invalid';

export const ERROR_HOUSE_NUMBER_LENGTH =
  'House number length must be less 7 symbols';

export const ERROR_UPPERCASE_NUMBERS =
  'The value must be in lowercase and without numbers';
