import styled from 'styled-components';

export const Root = styled.div`
  .status {
    display: inline-block;
    width: auto;
    border-radius: 10px;
    background-color: ${props => props.theme.lightGrayColor};
    color: #6f6f6f;
    padding: 0 10px;
    max-height: 20px;
    &_new {
      background-color: ${props => props.theme.newBackground};
      color: ${props => props.theme.newText};
    }
    &_succeeded {
      background-color: ${props => props.theme.paidBackground};
      color: ${props => props.theme.paidText};
    }
    &_yes {
      background-color: ${props => props.theme.paidBackground};
      color: ${props => props.theme.paidText};
    }
    &_request {
      background-color: ${props => props.theme.cancelledBackground};
      color: ${props => props.theme.cancelledText};
    }
    &_scheduled {
      background-color: ${props => props.theme.scheduledBackground};
      color: ${props => props.theme.scheduledText};
    }
    &_inactive {
      background-color: ${props => props.theme.inactiveBackground};
      color: ${props => props.theme.inactiveText};
    }
    &_re-schedule {
      background-color: ${props => props.theme.reScheduleBackground};
      color: ${props => props.theme.reScheduleText};
    }
    &_published {
      background-color: ${props => props.theme.publishedBackground};
      color: ${props => props.theme.publishedText};
    }
    &_finished {
      background-color: ${props => props.theme.finishedBackground};
      color: ${props => props.theme.finishedText};
    }
    &_open {
      background-color: ${props => props.theme.openBackground};
      color: ${props => props.theme.openText};
    }
    &_active {
      background-color: ${props => props.theme.activeBackground};
      color: ${props => props.theme.activeText};
    }
    &_paid {
      background-color: ${props => props.theme.paidBackground};
      color: ${props => props.theme.paidText};
    }
    &_verified {
      background-color: ${props => props.theme.paidBackground};
      color: ${props => props.theme.paidText};
    }
    &_valid {
      background-color: ${props => props.theme.validBackground};
      color: ${props => props.theme.validText};
    }
    &_unpaid {
      background-color: ${props => props.theme.unpaidBackground};
      color: ${props => props.theme.unpaidText};
    }
    &_draft {
      background-color: ${props => props.theme.draftBackground};
      color: ${props => props.theme.draftText};
    }
    &_cancelled {
      background-color: ${props => props.theme.cancelledBackground};
      color: ${props => props.theme.cancelledText};
    }
    &_over_due {
      background-color: ${props => props.theme.cancelledBackground};
      color: ${props => props.theme.cancelledText};
    }
    //add 402 new statuses
    &_pending {
      background-color: ${props => props.theme.pendingBackground};
      color: ${props => props.theme.pendingText};
    }
    &_awaiting_payment {
      background-color: ${props => props.theme.awaitingBackground};
      color: ${props => props.theme.awaitingText};
    }
    &_payment_received {
      background-color: ${props => props.theme.receivedBackground};
      color: ${props => props.theme.receivedText};
    }
    &_completed {
      background-color: ${props => props.theme.completedBackground};
      color: ${props => props.theme.completedText};
    }
    &_canceled {
      background-color: ${props => props.theme.canceledBackground};
      color: ${props => props.theme.canceledText};
    }
    &_expired {
      background-color: ${props => props.theme.expiredBackground};
      color: ${props => props.theme.expiredText};
    }
    &_refunded {
      background-color: ${props => props.theme.refundedBackground};
      color: ${props => props.theme.refundedText};
    }
    &_failed {
      background-color: ${props => props.theme.failedBackground};
      color: ${props => props.theme.failedText};
    }
    &_deleted {
      background-color: ${props => props.theme.deletedBackground};
      color: ${props => props.theme.deletedText};
    }

    &-text {
      height: 100%;
      line-height: 20px;
      font-size: 14px;
      margin: 0;
      color: inherit;
      user-select: none;
      white-space: nowrap;
    }
  }
`;
