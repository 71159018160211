import React, {useState, FunctionComponent, useEffect, useRef} from 'react';
import {debounce} from 'lodash';
import * as S from './styled';
import {ViewEye} from './styled';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';

export interface IInputField {
  placeholder?: string;
  className?: string;
  type?: string;
  disabled?: boolean;
  value?: any;
  onChangeHandler?: Function;
  onChangeText?: Function;
  onChangeTextDebounce?: Function;
  size?: number;
  min?: string | number | undefined;
  max?: string | number | undefined;
  autoComplete?: string;
  valid?: boolean;
  defaultValue?: string | number;
  step?: number;
  maxLength?: number;
  onSubmitEditing?: Function;
  inputRef?: React.RefObject<HTMLInputElement> | string;
  regex?: RegExp;
}

export const InputField: FunctionComponent<IInputField> = ({
  onChangeHandler,
  onChangeText,
  onChangeTextDebounce,
  valid,
  placeholder,
  defaultValue,
  className,
  type,
  disabled,
  value,
  size,
  step,
  max,
  min,
  maxLength,
  onSubmitEditing,
  inputRef: propsRef,
  autoComplete,
  regex,
}) => {
  const inputRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false);

  const emitChangeDebounced = () =>
    // @ts-ignore
    debounce((value: any) => onChangeTextDebounce(value), 500);

  const handleChange = (e: any) => {
    const re = regex ? regex : /^[a-zA-Z ]*$/;
    if (onChangeHandler) {
      if (valid) {
        if (!e.target.value || re.test(e.target.value)) {
          onChangeHandler(e);
        }
      } else {
        onChangeHandler(e);
      }
    }

    if (onChangeText) {
      onChangeText(e.target.value);
    }
    if (onChangeTextDebounce) {
      emitChangeDebounced();
    }
  };

  return (
    <S.InputField>
      <input
        ref={propsRef || inputRef}
        placeholder={placeholder}
        defaultValue={defaultValue}
        className={className}
        type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
        disabled={disabled}
        value={value}
        onChange={event => handleChange(event)}
        onBlur={event => {
          if (onSubmitEditing) {
            onSubmitEditing(event.target.value);
          }
        }}
        onKeyPress={(event: any) => {
          if (event.key === 'Enter') {
            if (onSubmitEditing) {
              onSubmitEditing(event.target.value);
            }
          }
        }}
        size={size}
        step={step}
        // @ts-ignore
        {...(max !== undefined ? (max = {max}) : {})}
        // @ts-ignore
        {...(min !== undefined ? (min = {min}) : {})}
        maxLength={maxLength}
        autoComplete={autoComplete}
      />
      <S.ViewEye
        hidden={type !== 'password'}
        onClick={() => setShowPassword(prevState => !prevState)}>
        {showPassword ? (
          <FontAwesomeIcon icon={faEyeSlash} />
        ) : (
          <FontAwesomeIcon icon={faEye} />
        )}
      </S.ViewEye>
    </S.InputField>
  );
};
