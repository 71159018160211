import styled from 'styled-components';
export const Root = styled.div`
  .menu-item {
    transition: all 0.4s;

    .menu-item-inner {
      font-size: 14px;
      color: ${props => props.theme.primaryColor};
      text-decoration: none;
      line-height: 20px;
      padding: 15px 20px;
      display: block;
      cursor: pointer;
    }

    &.active, &:hover {
      background-color: ${props => props.theme.extraLightGreyColor};
    }
  }
`;
