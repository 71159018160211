import React from 'react'; // eslint-disable-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';

import * as S from './styled';

const Tabs = ({
  data,
  currentTab,
  onChangeTab,
  showCount,
  notAllowed = false,
}) => {

  const tabs = data.map((tab, key ) => (
    <div
      className={`tab-item  ${
        tab?.name === currentTab ||
        (typeof currentTab === 'number' && key + 1 === currentTab)
          ? 'current-tab activated'
          : ''
      }`}
      key={key}
      onClick={
        !notAllowed ? () => onChangeTab(tab.id, tab.name, tab.basicName) : null
      }>
      {showCount ? (
        <span className={`tab-number ${key < currentTab && 'selected'}`}>
          {key + 1}
        </span>
      ) : null}
      {tab.name}
    </div>
  ));
  return <S.Root className="tabs-wrapper">{tabs}</S.Root>;
};
export default Tabs;

Tabs.propTypes = {
  data: PropTypes.array.isRequired,
  onChangeTab: PropTypes.func.isRequired,
  // currentTab: PropTypes.number.isRequired,
  showCount: PropTypes.bool,
  notAllowed: PropTypes.bool,
};
