import ApiV2 from '../ApiV2';

export const getInvoices = async (payload: any, params?: any): Promise<any> => {
  const response = await ApiV2.cp.integration.sendcloud.invoices.get(
    payload,
    undefined,
    params,
  );
  return response?.data;
};
export const postInvoices = async (payload: any): Promise<any> => {
  const response = await ApiV2.cp.integration.sendcloud.invoices.post(payload);
  return response?.data;
};
export const getShippingMethods = async (payload: any): Promise<any> => {
  const response = await ApiV2.cp.integration.sendcloud.methods.get(payload);
  return response?.data;
};
