import ApiV2 from '../ApiV2';

export const getConditionRateTypes = async (): Promise<any> => {
  const response = await ApiV2.cp.shipment.rateConditionTypes.get();
  return response?.data;
};

export const getShipmentZones = async (): Promise<any> => {
  const response = await ApiV2.cp.shipment.zones.get();
  return response?.data;
};

export const createShipmentZone = async (data: any): Promise<any> => {
  const response = await ApiV2.cp.shipment.zones.post(data);
  return response?.data;
};
export const getShipmentZone = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.shipment.zones.get(id);
  return response?.data;
};

export const updateShipmentZone = async (
  id: number,
  data: any,
): Promise<any> => {
  const response = await ApiV2.cp.shipment.zones.patch(id, data);
  return response?.data;
};

export const getShipmentZoneRates = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.shipment.zones.rates.get(id);
  return response?.data;
};
export const createShipmentZoneRate = async (
  id: number,
  data: any,
): Promise<any> => {
  const response = await ApiV2.cp.shipment.zones.rates.post(id, data);
  return response?.data;
};
export const getShipmentZoneRateDetails = async (
  id: number,
  rid: number,
): Promise<any> => {
  const response = await ApiV2.cp.shipment.zones.rates.get(id, rid);
  return response?.data;
};

export const updateShipmentZoneRateDetails = async (
  id: number,
  rid: number,
  data: any,
): Promise<any> => {
  const response = await ApiV2.cp.shipment.zones.rates.patch({id, rid}, data);
  return response?.data;
};
export const deleteShipmentZone = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.shipment.zones.delete(id);
  return response?.data;
};
export const deleteShipmentZoneRate = async (
  id: number,
  rid: number,
): Promise<any> => {
  const response = await ApiV2.cp.shipment.zones.rates.delete({id, rid});
  return response?.data;
};

export const getRateConditionTypes = async (): Promise<any> => {
  const response = await ApiV2.cp.shipment.rateConditionTypes.get();
  return response?.data;
};
