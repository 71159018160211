export const numberOfResults = [10, 25, 50];

export const dateFormat = 'DD-MM-YYYY';
export const dateFormatReversed = 'YYYY-MM-DD';

export const timeFormat = 'LT';

export const fullDateFormat = 'YYYY-MM-DD HH:mm:ssZ';

export const weekDays = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export const shopStatuses = [
  {id: 1, name: 'Open'},
  {id: 2, name: 'Closed'},
];

export const tableLabels = t => ({
  results: t('Results'),
  noResults: t('No Results'),
  prev: t('Previous'),
  next: t('Next'),
});

export const modalLabels = t => ({
  confirm: t('Confirm'),
  cancel: t('Cancel'),
});
