import styled from 'styled-components';

export const TooltipContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    visibility:${props => props.isVisible ? 'visible' : 'hidden'} ;
    opacity: ${props => props.isVisible ? '1' : '0'};
    transition: all ease-in-out .4s;
  
  
    .tooltip-wrapper {
        .tooltip {
            border-radius: 7px;
        };
    }
`;
