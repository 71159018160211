import {isArray} from 'lodash';
import moment from 'moment';

const getClientTypeCompanyId = Number(
  process.env.REACT_APP_DEFAULT_ACCOUNT_TYPE_COMPANY,
);

export const extractNewProfileData = item => {
  if (item) {
    const {
      id,
      account,
      companyname,
      firstname,
      lastname,
      gender,
      birthdate,
      main_email,
      is_email_verified,
      middlenames,
      main_phone_number: main_phonenumber,
      notes,
      homepage,
      other_phonenumbers,
    } = item;

    const {
      addresses,
      media_file_info,
      mediafileinfo,
      type,
      perskamer,
      sbl,
      shome,
      veld,
      code,
      send_newsletter: contactnewsletter,
      language,
      status,
      company,
    } = account || {};

    return {
      id,
      account,
      avatarSrc: media_file_info?.url || mediafileinfo?.url,
      clientType: {
        id: type?.id,
        name: type?.name,
      },
      companyName: companyname,
      firstName: firstname,
      lastName: lastname,
      middleNames: middlenames?.join(' '),
      homepage,
      gender: {
        id: gender?.id,
        name: gender?.name,
      },
      birthday: birthdate
        ? new Date(Date.parse(birthdate))
        : '1980-01-01 00:00:00+00',
      email: main_email,
      phoneNumber: main_phonenumber,
      addresses: addresses || [],
      additionalPhoneNumbers: isArray(other_phonenumbers)
        ? other_phonenumbers
        : [],
      notes,
      language,
      status,
      is_email_verified,
      chamberOfCommerce: company?.coc,
      vat: company?.vat,
      iban: company?.iban, //NL8282.34.4549.601
      twoStepAuth: 'google', // need info what means this field
      clientNo: code || '',
      contactnewsletter,
      perskamer,
      sbl,
      shome,
      veld,
    };
  }
};

export const formatRequestProfile = (data, phoneNumbersList) => {
  return {
    country_id: data?.country?.id,
    gender_id: data.gender?.id,
    state_id: data?.state?.id,
    firstname: data.firstName,
    lastname: data.lastName,
    middlenames: data.middleNames ? data.middleNames.split(' ') : [],
    ...(data.clientType?.id === getClientTypeCompanyId
      ? {companyname: data.companyName}
      : {}),
    birthdate: data.birthday && moment(data.birthday).format('YYYY-MM-DD'),
    main_email: data.email,
    main_phone_number: data.phoneNumber,
    other_phonenumbers: phoneNumbersList,
    notes: data.notes,
    account: {
      account_statuses_id: data.status?.id,
      send_newsletter: Number(data.contactnewsletter),
      veld: Number(data.veld),
      shome: Number(data.shome),
      sbl: Number(data.sbl),
      perskamer: Number(data.perskamer),
      language_id: data.language?.id,
      ...(data.clientType?.id === getClientTypeCompanyId
        ? {
            company: {
              name: data.companyName,
              coc: data.chamberOfCommerce,
              vat: data.vat,
              iban: data.iban,
              notes: data.notes,
              phonenumber: data.phonenumber,
            },
          }
        : {}),
    },
  };
};
