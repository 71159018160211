import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {
  getAuthCurrentUser,
  getAuthUser,
  getAuthUsers,
  updateAuthUser,
  createAuthUser,
  deleteAuthUser,
} from '../../api/helpers/auth';
import {showErrorNotificationV2} from '../../utils';
import {addBuilderCases, asyncThunkHelper} from '../utils';

export const fetchAuthUser = createAsyncThunk(
  'fetchAuthUser',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: undefined}),
      create: async () => await createAuthUser(payload?.data),
      update: async () => await updateAuthUser(payload?.id, payload?.data),
      delete: async () => await deleteAuthUser(payload?.id),
      default: async () => await getAuthUser(payload?.id),
    }),
);

export const fetchAuthUsers = createAsyncThunk(
  'fetchAuthUsers',
  async (payload: any) => {
    try {
      return await getAuthUsers(payload?.data);
    } catch (error) {
      console.log('error', error);
      showErrorNotificationV2(error, payload?.showNotification);
      return {data: undefined};
    }
  },
);

export const fetchCurrentAuthUser = createAsyncThunk(
  'fetchCurrentAuthUser',
  async (payload: any) => {
    try {
      return await getAuthCurrentUser();
    } catch (error) {
      console.log('error', error);
      showErrorNotificationV2(error, payload?.showNotification);
      return {data: undefined};
    }
  },
);

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: {data: null},
    currentUser: {data: null},
    users: {data: []},
  },
  reducers: {},
  extraReducers: builder => {
    addBuilderCases(builder, fetchAuthUser, 'user');
    addBuilderCases(builder, fetchAuthUsers, 'users');
    addBuilderCases(builder, fetchCurrentAuthUser, 'currentUser');
  },
});
