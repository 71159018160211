import React from 'react';
import PropTypes from 'prop-types';
import {generalTheme} from '../../../layout/theme';
import {ModalWrapper} from '../modalWrapper';
import Button from '../button/Button';
import * as S from './styled';

export const TwoButtonsModal = props => {
  const defaultLabels = {confirm: 'Confirm', cancel: 'Cancel'};
  const {
    popupRef,
    title,
    isNotFixed,
    isScrolled = true,
    children,
    onCancelClick,
    onConfirmClick,
    labels = defaultLabels,
    disabled = false,
    zIndex = 99,
    withCross,
    buttonsContainer: propsButtonsContainer,
  } = props;
  const cancelButton = (
    <Button
      onClick={onCancelClick}
      bgColor={generalTheme?.primaryColor}
      inverted>
      {labels.cancel}
    </Button>
  );
  const confirmButton = (
    <div style={{marginLeft: 20, marginRight: 20}}>
      <Button
        onClick={onConfirmClick}
        bgColor={generalTheme?.primaryColor}
        disabled={disabled}>
        {labels.confirm}
      </Button>
    </div>
  );

  const buttonsContainer = propsButtonsContainer || (
    <S.ButtonContainer>
      {cancelButton}
      {confirmButton}
    </S.ButtonContainer>
  );
  return (
    <S.Root>
      <ModalWrapper
        popupRef={popupRef}
        title={title}
        isNotFixed={isNotFixed}
        buttonsContainer={buttonsContainer}
        isScrolled={isScrolled}
        zIndex={zIndex}
        withCross={withCross}
        onCancelClick={onCancelClick}>
        {children}
      </ModalWrapper>
    </S.Root>
  );
};

ModalWrapper.propTypes = {
  title: PropTypes.string,
  showLogoHeader: PropTypes.bool,
  headerLabel: PropTypes.string,
  isScrolled: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  labels: PropTypes.string,
  onCancelClick: PropTypes.func,
  onConfirmClick: PropTypes.func,
  disabled: PropTypes.bool,
};
