export const HOME = '/';
export const LOGIN = '/login';
export const PROFILE = '/profile';
export const DASHBOARD = '/dashboard';
export const CLIENTS = '/clients';
export const BILLING = '/billing';
export const REPORTS = '/reports';
export const PRODUCTS = '/products';
export const VIEWTEMPLATE = '/designer/:id';
export const VIEWPRODUCT = '/products/:id';
export const DESIGNER = '/designer';
export const COLLECTIONS = '/collections';
export const CATEGORIES = '/categories';
export const BRANDS = '/brands';
export const SETTINGS = '/settings';
export const SUPERUSERS = '/settings/superusers';
export const COUNTRIES = '/settings/countries';
export const PLATFORM = '/settings/platform';
export const SHIPPING = '/settings/shipping';
export const SUPPLIERS = '/settings/suppliers';
export const SHIPPING_RATES = '/settings/shipping-rates';
export const ROLES = '/settings/roles';
export const SETTINGS_TEMPLATES = '/settings/templates';
export const SETTINGS_PAYMENT_METHODS = '/settings/payment-methods';
export const CLIENT_SETTINGS = '/settings/clientsettings';
//export const ACCOUNTING_PROGRAM = '/settings/accounting-program';
export const ACCOUNTING_PROGRAM = '/settings/eboekhouden';
export const INVOICE_TYPES = '/settings/invoice_types';
export const SUB_COLLECTIONS = '/collections/:id/subcollections';
export const VIEW_COLLECTION = '/collections/:id';
export const SUB_CATEGORIES = '/categories/:id/subcategories';
export const VIEW_CATEGORY = '/categories/:id';
export const SUPPORT = '/helpcenter';
