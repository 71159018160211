import React from 'react';
import PropTypes from 'prop-types';
import {Checkbox as CheckBox, FormControlLabel} from '@mui/material';
import * as S from './styled';

const Icon = ({checked}: any) => (
  <span className="checkbox-icon">
    <span className={`inner${checked && ' checked'}`} />
  </span>
);

type CheckboxType = {
  checked: any;
  value?: string | number | boolean;
  onChange: Function;
  label?: any;
  inputRef?: any;
  customized?: boolean;
  disabled?: boolean;
};

export const Checkbox: React.FC<CheckboxType> = ({
  checked,
  value,
  onChange,
  label,
  inputRef,
  customized,
  disabled,
}) => {
  return (
    <S.Root className={`checkbox-wrapper ${customized ? 'customized' : ''}`}>
      <FormControlLabel
        control={
          <CheckBox
            inputRef={inputRef}
            checked={checked}
            onChange={e => onChange(e.target.checked, value, e)}
            value={value}
            color="primary"
            checkedIcon={
              <Icon
                checked={checked}
                //@ts-ignore
                valueOf={undefined}
              />
            }
            icon={
              <Icon
                //@ts-ignore
                valueOf={undefined}
              />
            }
            disabled={disabled}
          />
        }
        label={
          <span className={`checkbox-label ${checked ? 'checked' : ''}`}>
            {label}
          </span>
        }
      />
    </S.Root>
  );
};
