import styled from 'styled-components';

export const SearchWrapper = styled.div`
  height: 100%;
  width: 100%;
  .search-field-wrapper {
    position: relative;
    margin-right: 10px;
    width: 100%;
    min-width: 150px;
    max-width: 300px;

    input {
      width: 100%;
      border-radius: 5px;
      border: 1px solid #d5d5d5;
      outline: none;
      font-size: 14px;
      line-height: 24px;
      padding: 8px 50px 8px 15px;
      color: #454545;
    }

    .icon-search-wr {
      position: absolute;
      top: 8px;
      right: 15px;
      path {
        fill: ;
      }
    }
  }
`;
