import React from 'react';
import {useDropzone} from 'react-dropzone';
import classNames from 'classnames';

interface DropZoneWrapperProps {
  onDrop: any;
  onBlur?: Function;
  disabled?: boolean;
  multiple?: boolean;
  className?: string;
  children: React.ReactNode;
}

export const DropZoneWrapper: React.FC<DropZoneWrapperProps> = ({
  onDrop,
  onBlur,
  disabled,
  multiple,
  className,
  children,
}) => {
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    disabled,
    multiple,
  });

  return (
    <div
      {...getRootProps()}
      className={classNames('dropzone', className, disabled && 'disabled', {
        'dropzone--isActive': isDragActive,
      })}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
};
