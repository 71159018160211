import React, {useRef} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {FooterForm} from '../styled';
import {
  PASSWORD_IS_INVALID,
  EMAIL_IS_INVALID,
  ERROR_REQUIRED_TEXT,
} from '../../../constants';
import FieldWrapper from '../../../components/common/fieldwrapper/FieldWrapper';
import {InputField} from '../../../components/common/inputField';
import Button from '../../../components/common/button/Button';
import {LinkForm, ValidateText} from '../../../layout/styled';
import {EMAIL_EXPRESSION} from '../../../regexp/regexp';

export const LoginForm = ({onSubmitHandler, value = 'some text'}) => {
  const {handleSubmit, control, formState: { errors }} = useForm();
  const inputPasswordRef = useRef(null);
  const {t} = useTranslation(['login']);

  const onSubmit = data => {
    onSubmitHandler(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FieldWrapper title={t('Email address')}>
        <Controller
          control={control}
          render={({field: {onChange, value, name, ref}}) => (
            <InputField
              type="email"
              className="form-control"
              value={value}
              onChangeHandler={value => {
                onChange(value);
              }}
              onSubmitEditing={() => inputPasswordRef.current.focus()}
            />
          )}
          name="email"
          rules={{
            required: {
              value: true,
              message: ERROR_REQUIRED_TEXT,
            },
            pattern: {
              value: EMAIL_EXPRESSION,
              message: EMAIL_IS_INVALID,
            },
          }}
          defaultValue={value || ''}
        />
        {errors?.email && <ValidateText>{errors.email?.message}</ValidateText>}
      </FieldWrapper>
      <FieldWrapper title={t('Password')}>
        <Controller
          control={control}
          render={({field: {onChange, value, name, ref}}) => (
            <InputField
              inputRef={inputPasswordRef}
              type="password"
              className="form-control"
              value={value}
              onChangeHandler={value => {
                onChange(value);
              }}
            />
          )}
          name="password"
          rules={{
            required: {
              value: true,
              message: ERROR_REQUIRED_TEXT,
            },
            minLength: {
              value: 1,
              message: PASSWORD_IS_INVALID,
            },
          }}
          defaultValue={value || ''}
        />
        {errors?.password && (
          <ValidateText>{errors.password?.message}</ValidateText>
        )}
      </FieldWrapper>
      <FooterForm>
        <LinkForm to="/forgot-password">{t('Forgot password')}?</LinkForm>
        <Button btnClass="btn-classic" onClick={() => true}>
          {t('Login')}
        </Button>
      </FooterForm>
    </form>
  );
};
