import ApiV2 from '../ApiV2';

export const getShippingInvoices = async (
  payload: any,
  params?: any,
): Promise<any> => {
  const response = await ApiV2.cp.reporting.shippingInvoices.get(
    payload,
    undefined,
    params,
  );
  return response?.data;
};
