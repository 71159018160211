import Api from '../ApiV2';

export const getIssuePriorities = async (data: any): Promise<any> => {
  const response = await Api.cp.support.issuePriorities.get(data);
  return response?.data;
};

export const getIssueStatuses = async (data: any): Promise<any> => {
  const response = await Api.cp.support.issueStatuses.get(data);
  return response?.data;
};

export const getIssueTypes = async (data: any): Promise<any> => {
  const response = await Api.cp.support.issueTypes.get(data);
  return response?.data;
};

export const getIssue = async (id: number): Promise<any> => {
  const response = await Api.cp.support.issues.get(id);
  return response?.data;
};

export const getIssueMessage = async (
  issueId: number,
  messageId: number,
): Promise<any> => {
  const response = await Api.cp.support.issues.messages.get({
    iid: issueId,
    id: messageId,
  });
  return response?.data;
};

export const getIssues = async (data: any): Promise<any> => {
  const response = await Api.cp.support.issues.get(data);
  return response?.data;
};

export const createIssue = async (data: any): Promise<any> => {
  const response = await Api.cp.support.issues.post(data);
  return response?.data;
};

export const createIssueMessage = async (
  id: number,
  data: any,
): Promise<any> => {
  const response = await Api.cp.support.issues.messages.post({iid: id}, data);
  return response?.data;
};

export const deleteIssue = async (id: number): Promise<any> => {
  const response = await Api.cp.support.issues.delete(id);
  return response?.data;
};

export const updateIssue = async (id: number, data: any): Promise<any> => {
  const response = await Api.cp.support.issues.patch(id, data);
  return response?.data;
};

export const deleteIssueMessage = async (
  issueId: number,
  messageId: number,
): Promise<any> => {
  const response = await Api.cp.support.issues.messages.delete({
    iid: issueId,
    id: messageId,
  });
  return response?.data;
};

export const updateIssueMessage = async (
  issueId: number,
  messageId: number,
  data: any,
): Promise<any> => {
  const response = await Api.cp.support.issues.messages.patch(
    {
      iid: issueId,
      id: messageId,
    },
    data,
  );
  return response?.data;
};

export const uploadIssueFile = async (id: number, data: any): Promise<any> => {
  const response = await Api.cp.support.issues.post(id, data);
  return response?.data;
};
