import React, {useState} from 'react';

export function withFilters(WrappedComponent, Filters) {
  return props => {
    const [filters, setFilters] = useState(undefined);

    return (
      <div>
        <Filters
          setFilters={setFilters}
          onAddClick={props.onAddClick}
          {...props.filterProps}
        />
        <WrappedComponent {...props} filters={filters} />
      </div>
    );
  };
}
