import React, {useEffect, useState} from 'react';
import moment from 'moment';
import DP from 'react-datepicker';
import {DatePickerInput} from './components';
import 'react-datepicker/dist/react-datepicker.css';
import * as S from './styled';

export const DatePicker = ({
  fromDate,
  toDate,
  onFromDateChange,
  onToDateChange,
  showTimeSelect,
  showTimeSelectOnly,
  dateFormat,
  timeFormat = 'HH:mm',
  className,
  placeholderFrom,
  placeholderTo,
  titleTo,
  titleFrom,
  range,
  disabled,
  readOnly,
  withIcon = true,
  timeIntervals = 30,
  customHeader,
  isClearable,
  maxDate,
  minDate,
  locale,
  listMonths,
}) => {
  const [startDate, setStartDate] = useState(fromDate);
  const [endDate, setEndDate] = useState(toDate);

  useEffect(() => {
    setStartDate(fromDate);
  }, [fromDate]);

  useEffect(() => {
    if (toDate) {
      setEndDate(toDate);
    }
  }, [toDate]);

  const onStartDateChange = date => {
    if (!range || startDate === null || (range && endDate - date >= 0)) {
      setStartDate(date);
      onFromDateChange(date);
    }
  };

  const onEndDateChange = date => {
    setEndDate(date);
    onToDateChange(date);
  };

  const datepickerInput = <DatePickerInput withIcon={withIcon} />;

  const currentYear = new Date().getFullYear();
  const yearsRange = (start, stop, step) =>
    Array.from({length: (stop - start) / step + 1}, (_, i) => start + i * step);
  const years = yearsRange(currentYear, -100, -1);

  const months = listMonths || [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return (
    <S.DatePickerWrapper>
      <div
        className={`date-picker-wrapper ${
          showTimeSelectOnly ? 'timepicker' : ''
        }`}>
        <S.DatePickerBlockWrapper>
          {titleFrom && <h4>{titleFrom}</h4>}
          <DP
            className={className}
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            onChange={onStartDateChange}
            showPopperArrow={false}
            showTimeSelect={showTimeSelect}
            showTimeSelectOnly={showTimeSelectOnly}
            dateFormat={dateFormat}
            timeFormat={timeFormat}
            placeholderText={placeholderFrom}
            disabled={disabled}
            readOnly={readOnly}
            customInput={datepickerInput}
            timeIntervals={timeIntervals}
            isClearable={isClearable}
            maxDate={maxDate || null}
            minDate={minDate || null}
            renderCustomHeader={
              customHeader
                ? ({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => (
                    <div
                      style={{
                        margin: 10,
                        display: 'flex',
                        justifyContent: 'center',
                      }}>
                      {/* eslint-disable-next-line react/button-has-type */}
                      <button
                        onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}>
                        {'<'}
                      </button>
                      <select
                        value={date.getFullYear()}
                        onChange={({target: {value}}) => changeYear(value)}>
                        {years.map(option => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>

                      <select
                        value={moment(date).format('MMMM')}
                        onChange={({target: {value}}) =>
                          changeMonth(months.indexOf(value))
                        }>
                        {months.map(option => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      {/* eslint-disable-next-line react/button-has-type */}
                      <button
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}>
                        {'>'}
                      </button>
                    </div>
                  )
                : undefined
            }
          />{' '}
        </S.DatePickerBlockWrapper>
        <S.DatePickerBlockWrapper>
          {titleTo && <h4>{titleTo}</h4>}
          {range && (
            <DP
              locale={locale}
              className={className}
              selected={endDate}
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              onChange={onEndDateChange}
              showPopperArrow={false}
              showTimeSelect={showTimeSelect}
              showTimeSelectOnly={showTimeSelectOnly}
              dateFormat={dateFormat}
              timeFormat={timeFormat}
              placeholderText={placeholderTo}
              disabled={disabled}
              readOnly={readOnly}
              customInput={datepickerInput}
              isClearable={isClearable}
            />
          )}
        </S.DatePickerBlockWrapper>
      </div>
    </S.DatePickerWrapper>
  );
};

export default DatePicker;
