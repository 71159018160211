export const allParams: Array<{
  name: string;
  type: string;
  object: string[];
  value?: string | number | boolean | {x: number; y: number};
  values?: string[] | boolean[];
}> = [
  {
    name: 'x',
    type: 'Number',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'y',
    type: 'Number',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'width',
    type: 'Number',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'height',
    type: 'Number',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'visible',
    type: 'Boolean',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'opacity',
    type: 'Number',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'scale',
    type: 'Object',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'scaleX',
    type: 'Number',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'scaleY',
    type: 'Number',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'rotation',
    type: 'Number',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'offsetX',
    type: 'Object',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'offsetY',
    type: 'Number',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'fontFamily',
    type: 'String',
    value: 'Arial',
    values: [
      'Arial',
      'Open Sans',
      // 'Amatic SC',
      // 'Amatic SC Regular',
      // 'Amatic SC Bold',
      // 'Davish',
      // 'Northwell',
      // 'Olive Display',
      // 'Avocado Sans Bold',
      // 'Futura PT Light Italic',
      // 'Kalam Bold',
      // 'Montana',
      // 'Butler',
      // 'Amithen',
      // 'Gabriola',
      // 'Remachine Script Personal Use',
    ],
    object: ['Text'],
  },
  {
    name: 'fontSize',
    type: 'Number',
    value: 12,
    object: ['Text'],
  },
  {
    name: 'fontStyle',
    type: 'String',
    value: 'normal',
    values: ['normal', 'bold', 'italic', 'italic bold'],
    object: ['Text'],
  },
  {
    name: 'fontVariant',
    type: 'String',
    value: 'normal',
    values: ['normal', 'small-caps'],
    object: ['Text'],
  },
  {
    name: 'textDecoration',
    type: 'String',
    value: 'empty string',
    values: ['line-through', 'underline', 'empty string'],
    object: ['Text'],
  },
  {
    name: 'text',
    type: 'String',
    value: 'text',
    object: ['Text'],
  },
  {
    name: 'align',
    type: 'String',
    values: ['left', 'center', 'right'],
    object: ['Text'],
  },
  {
    name: 'verticalAlign',
    type: 'String',
    values: ['top', 'middle', 'bottom'],
    object: ['Text'],
  },
  {
    name: 'padding',
    type: 'Number',
    object: ['Text'],
  },
  {
    name: 'lineHeight',
    type: 'Number',
    value: 1,
    object: ['Text'],
  },
  {
    name: 'wrap',
    type: 'String',
    value: 'word',
    values: ['word', 'char', 'none'],
    object: ['Text'],
  },
  {
    name: 'ellipsis',
    type: 'Boolean',
    value: false,
    values: [true, false],
    object: ['Text'],
  },
  {
    name: 'image',
    type: 'Image',
    object: ['Image'],
  },
  {
    name: 'crop',
    type: 'Object',
    object: ['Image'],
  },
  {
    name: 'radius',
    type: 'Number',
    object: ['Circle'],
  },
  {
    name: 'cornerRadius',
    type: 'Number',
    object: ['Rect'],
  },
  {
    name: 'fill',
    type: 'String',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'fillPatternImage',
    type: 'Image',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'fillPatternX',
    type: 'Number',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'fillPatternY',
    type: 'Number',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'fillPatternOffset',
    type: 'Object',
    value: {x: 0, y: 0},
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'fillPatternOffsetX',
    type: 'Number',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'fillPatternOffsetY',
    type: 'Number',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'fillPatternScale',
    type: 'Object',
    value: {x: 0, y: 0},
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'fillPatternScaleX',
    type: 'Number',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'fillPatternScaleY',
    type: 'Number',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'fillPatternRotation',
    type: 'Number',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'fillPatternRepeat',
    type: 'String',
    value: 'no-repeat',
    values: ['repeat', 'repeat-x', 'repeat-y', 'no-repeat'],
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'fillLinearGradientStartPoint',
    type: 'Object',
    value: {x: 0, y: 0},
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'fillLinearGradientStartPointX',
    type: 'Number',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'fillLinearGradientStartPointY',
    type: 'Number',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'fillLinearGradientEndPoint',
    type: 'Object',
    value: {x: 0, y: 0},
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'fillLinearGradientEndPointX',
    type: 'Number',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'fillLinearGradientEndPointY',
    type: 'Number',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'fillLinearGradientColorStops',
    type: 'Array',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'fillRadialGradientStartPoint',
    type: 'Object',
    value: {x: 0, y: 0},
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'fillRadialGradientStartPointX',
    type: 'Number',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'fillRadialGradientStartPointY',
    type: 'Number',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'fillRadialGradientEndPoint',
    type: 'Object',
    value: {x: 0, y: 0},
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'fillRadialGradientEndPointX',
    type: 'Number',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'fillRadialGradientEndPointY',
    type: 'Number',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'fillRadialGradientStartRadius',
    type: 'Number',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'fillRadialGradientEndRadius',
    type: 'Number',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'fillRadialGradientColorStops',
    type: 'Array',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'fillEnabled',
    type: 'Boolean',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'fillPriority',
    type: 'String',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'stroke',
    type: 'String',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'strokeWidth',
    type: 'Number',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'fillAfterStrokeEnabled',
    type: 'Boolean',
    value: false,
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'hitStrokeWidth',
    type: 'Number',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'strokeHitEnabled',
    type: 'Boolean',
    value: true,
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'perfectDrawEnabled',
    type: 'Boolean',
    value: true,
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'shadowForStrokeEnabled',
    type: 'Boolean',
    value: true,
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'strokeScaleEnabled',
    type: 'Boolean',
    value: true,
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'strokeEnabled',
    type: 'Boolean',
    value: true,
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'lineJoin',
    type: 'String',
    value: 'miter',
    values: ['miter', 'round', 'bevel'],
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'lineCap',
    type: 'String',
    value: 'butt',
    values: ['butt', 'round', 'square'],
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'shadowColor',
    type: 'String',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'shadowBlur',
    type: 'Number',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'shadowOffset',
    type: 'Object',
    value: {x: 0, y: 0},
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'shadowOffsetX',
    type: 'Number',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'shadowOffsetY',
    type: 'Number',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'shadowOpacity',
    type: 'Number',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'shadowEnabled',
    type: 'Boolean',
    value: true,
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'dash',
    type: 'Array',
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'dashEnabled',
    type: 'Boolean',
    value: true,
    object: ['Text', 'Image', 'Rect', 'Circle'],
  },
  {
    name: 'repeat',
    type: 'String',
    value: 'no-repeat',
    values: [
      'repeat',
      'repeat-wave',
      'repeat-wave-vertical',
      'repeat-x',
      'repeat-y',
      'no-repeat',
    ],
    object: ['Text'],
  },
  {
    name: 'textTransform',
    type: 'String',
    value: 'none',
    values: ['none', 'uppercase', 'lowercase'],
    object: ['Text'],
  },
  {
    name: 'disableReturnKey',
    type: 'Boolean',
    value: false,
    object: ['Text'],
  },
  {
    name: 'maxRowsForWrap',
    type: 'Number',
    object: ['Text'],
  },
];
