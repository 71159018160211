import React, {lazy, Suspense} from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';
import {
  LOGIN,
  HOME,
  PROFILE,
  CLIENTS,
  SETTINGS,
  SUPERUSERS,
  BRANDS,
  COUNTRIES,
  REPORTS,
  PLATFORM,
  BILLING,
  ROLES,
  CLIENT_SETTINGS,
  PRODUCTS,
  DESIGNER,
  COLLECTIONS,
  CATEGORIES,
  VIEWTEMPLATE,
  VIEWPRODUCT,
  SUB_COLLECTIONS,
  VIEW_COLLECTION,
  SUB_CATEGORIES,
  VIEW_CATEGORY,
  ACCOUNTING_PROGRAM,
  SUPPORT,
  SHIPPING,
  SHIPPING_RATES,
  SETTINGS_TEMPLATES,
  SETTINGS_PAYMENT_METHODS,
  SUPPLIERS,
  BILLING_SETTINGS,
  INVOICE_TYPES,
} from './routerUrl';
import {withLayout} from '../hocs';
import Skeleton from '../components/skeleton/Skeleton';
import ViewClientType from '../domains/Settings/ClientTypes/view/ViewClientType';

const Profile = lazy(() => import('../domains/Profile/Profile'));
const Clients = lazy(() => import('../domains/Clients/Clients'));
const ClientShippingRates = lazy(
  () =>
    import(
      '../domains/Clients/create/components/tabs/Shipping/components/ClientShippingRates'
    ),
);
const Reports = lazy(() => import('../domains/Reports/Reports'));
const Products = lazy(() => import('../domains/Products/Products'));
const Designer = lazy(() => import('../domains/Designer/Designer'));
const Collections = lazy(() => import('../domains/Products/Collections'));
const Categories = lazy(
  () => import('../domains/Products/Categories/Categories'),
);
const ViewClient = lazy(() => import('../domains/Clients/view/ViewClient'));
const Login = lazy(() => import('../domains/Login/Login'));
const SuperUsers = lazy(
  () => import('../domains/Settings/SuperUsers/SuperUsers'),
);
const Suppliers = lazy(() => import('../domains/Settings/Suppliers/Suppliers'));
const ViewTemplate = lazy(
  () => import('../domains/Designer/create/viewTemplate'),
);
const SubCategories = lazy(
  () => import('../domains/Products/Categories/SubCategoriesTable'),
);
const ViewCategory = lazy(
  () => import('../domains/Products/view/ViewCategory'),
);
const SubCollections = lazy(
  () =>
    import(
      '../domains/Products/components/collectionsTab/subCollections/SubCollectionsTable'
    ),
);
const ViewCollection = lazy(
  () => import('../domains/Products/view/ViewCollections'),
);
const ViewProduct = lazy(() => import('../domains/Products/view/ViewProduct'));
const ViewSuperUser = lazy(
  () => import('../domains/Settings/SuperUsers/view/ViewSuperUser'),
);
const ViewBrand = lazy(
  () =>
    import('../domains/Clients/create/components/tabs/brands/view/ViewBrand'),
);
const Countries = lazy(() => import('../domains/Settings/Countries/Countries'));
const Platform = lazy(() => import('../domains/Settings/Platform/Platform'));
const Shipping = lazy(() => import('../domains/Settings/Shipping/Shipping'));
const ShippingRates = lazy(
  () => import('../domains/Settings/Shipping/ShippingRates'),
);
const Templates = lazy(() => import('../domains/Settings/Templates/Templates'));
const TemplateForm = lazy(
  () => import('../domains/Settings/Templates/TemplateForm'),
);
const AccountingProgram = lazy(
  () => import('../domains/Settings/BillingSettings/BankProgram/BankProgram'),
);
const ViewService = lazy(
  () =>
    import('../domains/Settings/Platform/components/Services/ServiceItemView'),
);
const ViewSubscriptionItem = lazy(
  () =>
    import(
      '../domains/Settings/Platform/components/SubscriptionItems/SubscriptionItemView'
    ),
);
const SubscriptionOptionView = lazy(
  () =>
    import(
      '../domains/Settings/Platform/components/Options/SubscriptionOptionView'
    ),
);
const ViewSubscriptionBundle = lazy(
  () =>
    import(
      '../domains/Settings/Platform/components/SubscriptionBundles/SubscriptionBundleView'
    ),
);
const ViewAdditionalItem = lazy(
  () =>
    import(
      '../domains/Settings/Platform/components/Additionals/AdditionalItemView'
    ),
);
const ViewClientSubscriptionBundle = lazy(
  () =>
    import(
      '../domains/Clients/create/components/tabs/Subscriptions/ViewClientSubscriptionBundle'
    ),
);
const Billing = lazy(() => import('../domains/Billing/Billing'));
const InvoiceItemTypes = lazy(
  () =>
    import(
      '../domains/Settings/BillingSettings/InvoiceItemTypes/InvoiceItemTypes'
    ),
);
const CreateInvoice = lazy(
  () => import('../domains/Billing/create/createInvoice'),
);
const Roles = lazy(() => import('../domains/Settings/Roles/Roles'));
const ClientTypes = lazy(
  () => import('../domains/Settings/ClientTypes/ClientTypes'),
);
const EditRole = lazy(
  () => import('../domains/Settings/Roles/editRole/EditRole'),
);
const AdminPermissions = lazy(
  () =>
    import('../domains/Clients/create/components/tabs/Admins/AdminPermissions'),
);
const Support = lazy(() => import('../domains/Support/Support'));
const IssueDetails = lazy(() => import('../domains/Support/view/IssueDetails'));
const PaymetMethods = lazy(
  () =>
    import('../domains/Settings/BillingSettings/PaymentMethods/PaymentMethods'),
);

export const RouterComponent = withLayout(() => {
  const routes = (
    <Routes>
      <Route path={LOGIN} exact element={<Login />} />
      <Route path={PROFILE} exact element={<Profile />} />
      <Route path={HOME} exact element={<Clients />} />
      <Route path={CLIENTS} exact element={<Clients />} />
      <Route path={REPORTS} exact element={<Reports />} />
      <Route path={PRODUCTS} exact element={<Products />} />
      <Route path={VIEWTEMPLATE} exact element={<ViewTemplate />} />
      <Route path={VIEWPRODUCT} exact element={<ViewProduct />} />
      <Route path={DESIGNER} exact element={<Designer />} />
      <Route path={CATEGORIES} exact element={<Categories />} />
      <Route path={COLLECTIONS} exact element={<Collections />} />
      <Route path={BILLING} exact element={<Billing />} />
      <Route path={`${BILLING}/invoice/:id`} element={<CreateInvoice />} />
      <Route path={`${CLIENTS}/:id`} exact element={<ViewClient />} />
      <Route
        path={`${CLIENTS}/:id/subscriptions/:subId`}
        exact
        element={<ViewClientSubscriptionBundle />}
      />
      <Route
        path={`${CLIENTS}/:id/shipping-rates/:zoneId`}
        exact
        element={<ClientShippingRates />}
      />
      <Route
        path={SETTINGS}
        exact
        element={<Navigate to={SUPERUSERS} replace={true} />}
      />
      <Route path={SUPERUSERS} exact element={<SuperUsers />} />
      <Route path={PLATFORM} exact element={<Platform />} />
      <Route path={SHIPPING} exact element={<Shipping />} />
      <Route path={`${SHIPPING_RATES}/:id`} exact element={<ShippingRates />} />
      <Route path={`${PLATFORM}/service/:id`} exact element={<ViewService />} />
      <Route
        path={`${PLATFORM}/subscription/:id`}
        exact
        element={<ViewSubscriptionItem />}
      />
      <Route
        path={`${PLATFORM}/subscription-bundle/:id`}
        exact
        element={<ViewSubscriptionBundle />}
      />
      <Route
        path={`${PLATFORM}/additionals/:id`}
        exact
        element={<ViewAdditionalItem />}
      />
      <Route
        path={`${PLATFORM}/options/:id`}
        exact
        element={<SubscriptionOptionView />}
      />
      <Route path={`${SUPERUSERS}/:id`} exact element={<ViewSuperUser />} />
      <Route path={`${BRANDS}/:id`} exact element={<ViewBrand />} />
      <Route path={COUNTRIES} exact element={<Countries />} />
      <Route path={ROLES} exact element={<Roles />} />
      <Route path={`${ROLES}/:id`} exact element={<EditRole />} />
      <Route path={CLIENT_SETTINGS} exact element={<ClientTypes />} />
      <Route path={SETTINGS_TEMPLATES} exact element={<Templates />} />
      <Route path={SUPPLIERS} exact element={<Suppliers />} />
      <Route
        path={`${SETTINGS_TEMPLATES}/:id`}
        exact
        element={<TemplateForm />}
      />
      <Route
        path={SETTINGS_PAYMENT_METHODS}
        exact
        element={<PaymetMethods />}
      />
      <Route
        path={`${CLIENT_SETTINGS}/:id`}
        exact
        element={<ViewClientType />}
      />
      <Route path={`${INVOICE_TYPES}`} exact element={<InvoiceItemTypes />} />
      <Route
        path={`${ACCOUNTING_PROGRAM}`}
        exact
        element={<AccountingProgram />}
      />
      <Route
        path={`${CLIENTS}/:id/users/:uid/permissions`}
        exact
        element={<AdminPermissions />}
      />
      <Route path={HOME} element={<Navigate to={HOME} />} />
      <Route path={VIEW_COLLECTION} exact element={<ViewCollection />} />
      <Route path={SUB_COLLECTIONS} exact element={<SubCollections />} />
      <Route path={SUB_CATEGORIES} exact element={<SubCategories />} />
      <Route path={VIEW_CATEGORY} exact element={<ViewCategory />} />
      <Route path={SUPPORT} exact element={<Support />} />
      <Route path={`${SUPPORT}/:id`} exact element={<IssueDetails />} />
    </Routes>
  );

  return <Suspense fallback={<Skeleton />}>{routes}</Suspense>;
});
