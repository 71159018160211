import ApiV2 from '../ApiV2';

export const getAuthCurrentUser = async (): Promise<any> => {
  const response = await ApiV2.cp.auth.users.current.get();
  return response?.data;
};

export const getAuthUsers = async (data: object): Promise<any> => {
  const response = await ApiV2.cp.auth.users.get(data);
  return response?.data;
};

export const getAuthUser = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.auth.users.get(id);
  return response?.data;
};

export const updateAuthUser = async (id: number, data: any): Promise<any> => {
  const response = await ApiV2.cp.auth.users.patch(id, data);
  return response?.data;
};

export const createAuthUser = async (data: any): Promise<any> => {
  const response = await ApiV2.cp.auth.users.post(data);
  return response?.data;
};

export const deleteAuthUser = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.auth.users.delete(id);
  return response?.data;
};
