import styled from 'styled-components';

export const Root = styled.div`
  margin-bottom: 20px;
  position: relative;
  width: 100%;

  .field-wrapper-title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }

  .field-wrapper-title-link {
    margin: 0;
    color: ${props => props.theme.primaryColor};
    text-decoration: underline;
    cursor: pointer;
  }

  .field-wrapper-title-outer-wr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;

    .field-wrapper-title-wr {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .field-wrapper-title {
        font-size: ${props => props.theme.fontSizeSmall};
        font-weight: bold;
        color: ${props => props.theme.blackColor};
        text-transform: capitalize;
      }

      .field-wrapper-additional {
        font-size: ${props => props.theme.fontSizeSmall};
        margin-left: 10px;
        color: ${props => props.theme.textInputColor};
        text-transform: capitalize;
      }
    }
  }

  .field-wrapper-info-inner {
    padding: 0 15px;
    color: #6e6e6e;
  }

  .field-wrapper-info-wr {
    margin-left: 15px;
    background-color: #d5d5d5;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: relative;
    cursor: pointer;

    .field-wrapper-info-wr-icon {
      font-size: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #ffffff;
    }
  }
`;
