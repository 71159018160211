import styled from 'styled-components';

export const Root = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    top: 0;
    width: 100%;
    padding: 15px 52px 15px 25px;
    box-shadow: 0 3px 6px #00000029;
    background-color: $whiteColor;
    .header-toolbar {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .header-icon {
      padding: 0 10px;
      cursor: pointer;
      &:hover {
        background-color: unset;
      }
      svg {
        path {
          fill: $primaryColor;
        }
      }
    }

    img {
      max-width: none;
    }
  }

  .header-button-wrapper {
    margin: 0 20px;
  }

  @media (max-width: 991px) {
    .header-button-wrapper {
      display: none;
    }
  }
`;
