import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {createToken} from '../../api/authApi';
import {LayoutAuthPages, LoginForm} from './components';
import {withNotification} from '../../hocs/withNotification';
import {showErrorNotificationV2} from '../../utils';

const Login = props => {
  const {onResult, showNotification} = props;
  const navigate = useNavigate();
  const {t} = useTranslation();

  const onSubmit = data => {
    const {email, password} = data;

    const authorization = {
      username: email,
      password,
    };
    createToken(authorization, showNotification)
      .then(response => {
        const {access_token, error, message} = response;
        if (access_token) {
          onResult(true);
          navigate.go(0);
        }
        if (error) {
          onResult(false);
          const errorMessage =
            error === 'invalid_grant'
              ? t('Invalid username or password')
              : message;
          showNotification({message: errorMessage, isError: true});
        }
      })
      .catch(error => showErrorNotificationV2(error, showNotification));
  };

  return (
    <LayoutAuthPages>
      <LoginForm onSubmitHandler={data => onSubmit(data)} value="" />
    </LayoutAuthPages>
  );
};

export default withNotification(Login);
