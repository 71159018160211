/* eslint-disable no-console */
export const handleError = e => console.log(e);

export const getErrorMessage = errors => {
  let result;
  if (errors) {
    result = Object.values(errors);
  }
  return result;
};

export const showErrorNotificationV2 = (value, showErrorMessage) => {
  const {response} = value || {};
  const {errors, error} = response?.data || {};

  if (errors && showErrorMessage) {
    showErrorMessage({
      message: getErrorMessage(errors),
      isError: true,
    });
  }
  if (error && showErrorMessage) {
    showErrorMessage({
      message: error,
      isError: true,
    });
  }
  if (showErrorMessage && !error && !errors) {
    showErrorMessage({
      message: String(value),
      isError: true,
    });
  }
};
