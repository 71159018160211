import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {showErrorNotificationV2} from '../../utils';
import {addBuilderCases, asyncThunkHelper} from '../utils';
import {
  createAdditional,
  createBillingInvoice,
  createInvoiceItemType,
  deleteAdditional,
  deleteBillingInvoice,
  deleteInvoiceItemType,
  getAdditional,
  getAdditionals,
  getAdministrations,
  getBillingInvoice,
  getBillingInvoices,
  getCostCenters,
  getInvoiceItemType,
  getInvoiceItemTypes,
  getPaymentMethod,
  getPaymentMethods,
  getPayments,
  getTransactions,
  importAdministration,
  importCostCenters,
  updateAdditional,
  updateBillingInvoice,
  updateInvoiceItemType,
  updatePaymentMethod,
} from '../../api/helpers/billing';

export const fetchBillingInvoices = createAsyncThunk(
  'fetchBillingInvoices',
  async (payload: any) => {
    try {
      return await getBillingInvoices(payload?.data);
    } catch (error) {
      showErrorNotificationV2(error, payload?.showNotification);
    }
  },
);

export const fetchBillingInvoice = createAsyncThunk(
  'fetchBillingInvoice',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: undefined}),
      default: async () => await getBillingInvoice(payload?.id),
      create: async () => await createBillingInvoice(payload?.data),
      update: async () =>
        await updateBillingInvoice(payload?.id, payload?.data),
      delete: async () => await deleteBillingInvoice(payload?.id),
    }),
);
export const fetchBillingPayments = createAsyncThunk(
  'fetchBillingPayments',
  async (payload: any) => {
    try {
      return await getPayments(payload?.data);
    } catch (error) {
      showErrorNotificationV2(error, payload?.showNotification);
    }
  },
);
export const fetchBillingTransactions = createAsyncThunk(
  'fetchBillingTransactions',
  async (payload: any) => {
    try {
      return await getTransactions(payload?.data);
    } catch (error) {
      showErrorNotificationV2(error, payload?.showNotification);
    }
  },
);
export const fetchBillingInvoiceTypes = createAsyncThunk(
  'fetchBillingInvoiceTypes',
  async (payload: any) => {
    try {
      return await getInvoiceItemTypes(payload?.data);
    } catch (error) {
      showErrorNotificationV2(error, payload?.showNotification);
    }
  },
);

export const fetchBillingInvoiceItemType = createAsyncThunk(
  'fetchBillingInvoiceItemType',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: undefined}),
      default: async () => await getInvoiceItemType(payload?.id),
      create: async () => await createInvoiceItemType(payload?.data),
      update: async () =>
        await updateInvoiceItemType(payload?.id, payload?.data),
      delete: async () => await deleteInvoiceItemType(payload?.id),
    }),
);
export const fetchBillingAdditionals = createAsyncThunk(
  'fetchBillingAdditionals',
  async (payload: any) => {
    try {
      return await getAdditionals(payload?.data);
    } catch (error) {
      showErrorNotificationV2(error, payload?.showNotification);
    }
  },
);
export const fetchBillingAdditional = createAsyncThunk(
  'fetchBillingAdditional',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: undefined}),
      default: async () => await getAdditional(payload?.id),
      create: async () => await createAdditional(payload?.data),
      update: async () => await updateAdditional(payload?.id, payload?.data),
      delete: async () => await deleteAdditional(payload?.id),
    }),
);
export const fetchBillingAdministrations = createAsyncThunk(
  'fetchBillingAdministrations',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: undefined}),
      default: async () => await getAdministrations(),
      create: async () => await importAdministration(payload?.data),
    }),
);
export const fetchBillingCostCenters = createAsyncThunk(
  'fetchBillingCostCenters',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: undefined}),
      default: async () => await getCostCenters(),
      create: async () => await importCostCenters(payload?.data),
    }),
);
export const fetchPaymentMethods = createAsyncThunk(
  'fetchPaymentMethods',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: undefined}),
      default: async () => await getPaymentMethods(payload?.data),
    }),
);
export const fetchPaymentMethod = createAsyncThunk(
  'fetchPaymentMethod',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: undefined}),
      update: async () => await updatePaymentMethod(payload?.id, payload?.data),
      default: async () => await getPaymentMethod(payload?.id),
    }),
);

export const billingSlice = createSlice({
  name: 'billing',
  initialState: {
    invoice: {data: null},
    invoices: {data: []},
    payments: {data: []},
    transactions: {data: []},
    invoiceTypes: {data: []},
    invoiceType: {data: null},
    additionals: {data: []},
    additional: {data: null},
    administrations: {data: []},
    costCenters: {data: []},
    invoicesPagination: {data: []},
    transactionsPagination: {data: []},
    paymentsPagination: {data: []},
    paymentMethods: {data: []},
    paymentMethod: {data: null},
  },
  reducers: {
    setInvoicePagination: (state, action) => {
      state.invoicesPagination.data = action.payload;
    },
    setTransactionsPagination: (state, action) => {
      state.transactionsPagination.data = action.payload;
    },
    setPaymentsPagination: (state, action) => {
      state.paymentsPagination.data = action.payload;
    },
  },
  extraReducers: (builder: any): any => {
    addBuilderCases(builder, fetchBillingInvoice, 'invoice');
    addBuilderCases(builder, fetchBillingInvoices, 'invoices');
    addBuilderCases(builder, fetchBillingPayments, 'payments');
    addBuilderCases(builder, fetchBillingTransactions, 'transactions');
    addBuilderCases(builder, fetchBillingInvoiceTypes, 'invoiceTypes');
    addBuilderCases(builder, fetchBillingInvoiceItemType, 'invoiceType');
    addBuilderCases(builder, fetchBillingAdditionals, 'additionals');
    addBuilderCases(builder, fetchBillingAdditional, 'additional');
    addBuilderCases(builder, fetchBillingAdministrations, 'administrations');
    addBuilderCases(builder, fetchBillingCostCenters, 'costCenters');
    addBuilderCases(builder, fetchPaymentMethods, 'paymentMethods');
    addBuilderCases(builder, fetchPaymentMethod, 'paymentMethod');
  },
});

export const {
  setInvoicePagination,
  setTransactionsPagination,
  setPaymentsPagination,
} = billingSlice.actions;
