import {getFullName, getImageWithoutPrefix} from './helperUtils';

export const refreshOnSelectAllState = (instance, list) => {
  const {checkedIds} = instance.state;
  const isCheckedAll = checkedIds.length === list.length;
  instance.setState({isCheckedAll});
};

export const selectAll = (instance, list) => {
  const {isCheckedAll} = instance.state;
  let checkedIds = [];
  if (!isCheckedAll) {
    list.forEach(item => checkedIds.push(item.id));
  }
  instance.setState({checkedIds}, () =>
    refreshOnSelectAllState(instance, list),
  );
};

export const selectAllVenues = (instance, list) => {
  const {isCheckedAll} = instance.state;
  let checkedIds = [];
  if (!isCheckedAll) {
    list.forEach(item => checkedIds.push(item));
  }
  instance.setState({checkedIds}, () =>
    refreshOnSelectAllState(instance, list),
  );
};

export const getSelectValue = item =>
  item ? {value: item.id, label: item.name} : null;

export const selectValueToObject = item =>
  item ? {id: item.value, name: item.label} : null;

export const getInputOptionsValue = items =>
  items.map(item => item.name).join(', ');

export const getAccountList = suppliers => {
  return suppliers && suppliers.length
    ? suppliers.map(item => ({
        value: item?.id,
        label: getFullName(
          item?.type_detail?.firstname,
          item?.type_detail?.lastname,
        ),
      }))
    : [];
};

export const uniqueById = arr => {
  return arr.reduce((acc, curr) => {
    const index = acc.findIndex(i => i.id === curr.id);
    if (index !== -1) {
      acc.splice(index, 1, curr);
      return acc;
    }
    return [...acc, curr];
  }, []);
};

export const uniqueByName = arr => {
  return arr.reduce((acc, curr) => {
    const index = acc.findIndex(i => i.name === curr.name);
    if (index !== -1) {
      acc.splice(index, 1, curr);
      return acc;
    }
    return [...acc, curr];
  }, []);
};

export const getImagesWithoutPrefix = images => {
  return images && images.length
    ? images.map(i => {
        i.bin = getImageWithoutPrefix(i?.bin);
        return i;
      })
    : null;
};

export const getLogoFromImages = images => {
  return images && images.find(item => item?.is_logo);
};

export const combineArrays = (arr1, arr2) => {
  return [...arr1, ...arr2].reduce((acc, curr) => {
    return acc.find(i => i.id === curr.id) ? acc : [...acc, curr];
  }, []);
};

export const uniq = (a, param) => {
  return a.filter(function (item, pos, array) {
    return (
      array
        .map(function (mapItem) {
          return mapItem[param];
        })
        .indexOf(item[param]) === pos
    );
  });
};

export const formattedAddresses = addresses => {
  return addresses
    ? addresses.map(item => ({
        ...item,
        id: item?.id,
        name: item?.address,
      }))
    : [];
};

export const getTypeValue = (typeId, types) => {
    return types.find(type => type.id === Number(typeId));
};