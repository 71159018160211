export const localePhone = {
    nl: {
        minLength: 10,
        maxLength: 12
    },
    be: {
        minLength: 10,
        maxLength: 12
    },
    de: {
        minLength: 6,
        maxLength: 15
    },
    lu: {
        minLength: 9,
        maxLength: 15
    },
    fr: {
        minLength: 11,
        maxLength: 12
    }
};
