import styled from 'styled-components';

export const InputField = styled.div`
  input {
    width: 100%;
    border: 1px solid ${props => props.theme.borderTables};
    color: #6e6e6e;
    font-size: 14px;
    line-height: 1.5;
    outline: none;
    border-radius: 5px;
    height: 40px;
    padding: 0 !important;
    text-indent: 10px;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    display: none;
  }
`;

export const ViewEye = styled.figure`
  display: ${props => props.hidden && 'none'};
  position: absolute;
  top: 35px;
  right: 20px;
  margin: 0;

  svg {
    color: ${props => props.theme.primaryColor};
  }
`;
