import styled from 'styled-components';

export const LogoutStyled = styled.div`
    display: flex;
    align-items: center;
    text-decoration: none;
    line-height: 1.5;
    color: ${props => props.theme.primaryColor}
    position: relative;
    font-size: ${props => props.theme.primaryFontSize};
    padding: ${props => props.theme.spaceNormal};
    cursor: pointer;
    transition: all 0.3s;
    &:after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        height: 1px;
        width: 100%;
        background-color: ${props => props.theme.borderGray};
    }
    svg.icon {
        margin-right: ${props => props.theme.spaceSmall};
        transition: all 0.4s;
    }
    &:hover {
        background-color: ${props => props.theme.extraLightGreyColor};
    }
`;
