import {useEffect} from 'react';

export const useOutsideClick = (ref, setShowPopup, notBool) => {
  const handleClickOutside = event => {
    if (ref?.current && !ref?.current.contains(event.target)) {
      document.body.style.overflow = 'unset';
      if (!notBool) {
        setShowPopup(false);
      } else {
        setShowPopup(null);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
};
