import styled, {css, keyframes} from 'styled-components';

export const Color = {
  BgColor: '#eaeaec',
  BorderColor: '#dcdcdc',
};

export const loadingSkeleton = keyframes`
  100% {
    transform: translateX(100%);
  }
`;

export const animationSkeleton = css`
  position: relative;
  overflow: hidden;
  z-index: 1;

  &:after {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-image: linear-gradient(90deg, #ebebeb, #f5f5f5, #ebebeb);
    transform: translateX(-100%);
    animation-name: ${loadingSkeleton};
    animation-direction: normal;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
`;

export const PageHeader = styled.header`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const HeaderTitle = styled.div`
  width: 120px;
  height: 25px;
  background-color: ${Color.BgColor};
`;
export const HeaderButton = styled.div`
  width: 131px;
  height: 41px;
  background-color: ${Color.BgColor};
  border-radius: 5px;
`;

export const PageTable = styled.article`
  width: 100%;
  height: fit-content;
  border: solid 1px ${Color.BorderColor};
`;
export const TableFilter = styled.section`
  display: flex;
  column-gap: 20px;
  padding: 20px 10px 10px;
`;
export const SearchPanel = styled.div`
  width: 100%;
  height: 32px;
  background-color: #eaeaec;
  border-radius: 5px;
`;
export const FilterPanel = styled.div`
  width: 219px;
  height: 32px;
  background-color: ${Color.BgColor};
  border-radius: 5px;
`;
export const Table = styled.article`
  width: 100%;
  height: fit-content;
`;
export const TableHeader = styled.header`
  height: 20px;
  margin-top: 20px;
  display: grid;
  grid-template-columns: 20px repeat(6, 1fr);
  column-gap: 10px;
  padding: 0 10px 10px;
  border-bottom: solid 1px ${Color.BorderColor};
`;
export const TableHeaderItem = styled.div`
  background-color: ${Color.BgColor};

  &:first-child {
    border-radius: 5px;
  }
`;
export const TableBody = styled.section`
  width: 100%;
  height: 420px;
`;

export const TableBodyRow = styled.div`
  display: grid;
  grid-template-columns: 20px repeat(6, 1fr);
  width: 100%;
  height: 20px;
  column-gap: 10px;
  margin-top: 20px;
  padding:0 10px;

  &:last-child {
    margin-bottom: 20px;
  }
`;
export const TableBodyItem = styled(TableHeaderItem)`
  width: 100%;
  height: 20px;
`;
export const TableFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  padding: 29px 10px;
  border-top: solid 1px ${Color.BorderColor};
`;

export const CountElements = styled.div`
  width: 116px;
  height: 21px;
  background-color: ${Color.BgColor};
`;
export const NumberPage = styled.div`
  width: 343px;
  height: 21px;
  background-color: ${Color.BgColor};
`;

export const ContainerPage = styled.main<{onlyTable: boolean}>`
  width: 100%;
  height: fit-content;
  ${HeaderTitle}, ${HeaderButton}, ${SearchPanel}, ${FilterPanel}, ${TableHeaderItem}, ${CountElements}, ${NumberPage} {
    ${animationSkeleton};
  }

  ${props =>
    props.onlyTable &&
    css`
      ${PageHeader},${TableFilter},${TableHeader}, ${TableFooter} {
        display: none;
      }
      ${TableBody} {
        position: absolute;
        top: 38px;
        left: 0;
        background-color:#f7f7f7;
      }
    `}
`;
