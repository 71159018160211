import styled, {css} from 'styled-components';

export const Layout = styled.div`
  .breadcrumbs .breadcrumb-item a {
    color: ${props => props.theme.primaryColor} !important;
  }
  .breadcrumbs .breadcrumb-item + .breadcrumb-item::before {
    color: ${props => props.theme.primaryColor};
  }
  .breadcrumbs .breadcrumb-item {
    color: ${props => props.theme.primaryColor};
  }
  .footer-linkWrapper {
    border-right: 1px solid ${props => props.theme.primaryColor};
    .footer-link {
      color: ${props => props.theme.primaryColor};
    }
  }
  .footer-linkWrapper:last-child {
    border-right: none;
  }
  //overflow-y: auto;
  //overflow-x: auto;
  .layout {
    .layout-content {
      transition: all ease-in-out 0.4s;
      padding: 0 23px;
      ${props =>
        props.$isOpenedSidebar
          ? css`
              max-width: ${props => props.$containerWidth - 260}px;
              margin: 30px 0 38px 260px;
              width: 100%;
            `
          : css`
              max-width: 100%;
              margin: 30px 0 38px 80px;
              width: 100%;
            `};
      ${props => props.theme.screenSize.lg} {
        max-width: none;
      }
      ${props => props.theme.screenSize.xl} {
        width: 100%;
        min-width: 1054px;
      }
    }
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 50px;

    b {
      font-weight: bold;
    }

    &-sidebar {
      position: fixed;
      top: 40px;
      bottom: 0;
      min-height: calc(100vh - 80px);
      background-color: #fff;
      z-index: 2;

      span {
        &:hover {
          path {
            fill: #fff;
          }
        }
      }
    }

    &-chatSidebar {
      position: fixed;
      width: 340px;
      margin-left: 80px;
      margin-right: -80px;
      align-self: stretch;
      background-color: white;
      margin-top: -60px;
      height: calc(100vh - 80px);
      overflow: auto;
      box-shadow: 3px 1px 5px 0 rgba(0, 0, 0, 0.25);

      + .layout-content {
        width: calc(100% - 420px);
        margin-left: 420px;
      }
    }

    &-chatFooter {
      display: block;
      margin: 0 -60px;
      padding: 20px 60px;
    }

    .sidebar-wr {
      position: absolute;
      top: 0;
      bottom: 0;
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: calc(100vh - 140px);
      //width: calc(100% - 60px);
      margin-left: 80px;
      padding: 0 60px;
    }

    &-titleBlock {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 50px;
    }

    &-pageName {
      font-size: 24px;
      font-weight: bold;
      color: #d5d5d5;
      line-height: 38px;
      margin-bottom: 0;
    }
  }
`;

export const SideBar = styled.div`
  height: 100%;
  .sidebar-item-img {
    &:hover {
      background-color: ${props => props.theme.primaryColor} !important;
    }
  }
  .sidebar-btn {
    background-color: ${props => props.theme.primaryColor};
  }
  .sidebar-list {
    display: flex;
    flex-direction: column;
  }
  ${props => props.theme.screenSize.xxl} {
    .sidebar-wr {
      padding-top: ${props => props.theme.spaceNormal};
      padding-bottom: ${props => props.theme.spaceLarge};
    }
    .sidebar-list {
      .sidebar-item {
        margin-bottom: 0;
      }
    }
  }
`;

export const LayoutContent = styled.div``;

export const HeaderStyled = styled.div`
  .menu-item .menu-item-inner {
    color: ${props => props.theme.primaryColor};
  }
`;
