import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {showErrorNotificationV2} from '../../utils';
import {addBuilderCases, asyncThunkHelper} from '../utils';
import {
  getPlatformServices,
  getPlatformSubscriptionBundles,
  getPlatformSubscriptionItems,
  getPlatformSubscriptionItemTypes,
  getPlatformSubscriptionPricingModels,
  getPlatformSubscriptionStatuses,
  getPlatformSubscriptionItem,
  createPlatformSubscriptionItem,
  updatePlatformSubscriptionItem,
  deletePlatformSubscriptionItem,
  updatePlatformSubscriptionBundle,
  deletePlatformSubscriptionBundle,
  createPlatformSubscriptionBundle,
  getPlatformSubscriptionBundle,
  getPlatformService,
  updatePlatformService,
  getRoles,
  getRole,
  createRole,
  updateRole,
  deleteRole,
  updateRolePermissions,
  getPermissions,
  getSubscriptionOption,
  deleteSubscriptionOption,
  updateSubscriptionOption,
  createSubscriptionOption,
  getSubscriptionOptions,
  getSubscriptionOptionCategories,
  getSubscriptionOptionsToBundle,
  updateSubscriptionOptionsToBundle,
} from '../../api/helpers/platform';

export const fetchPlatformServices = createAsyncThunk(
  'fetchPlatformService',
  async (payload: any) => {
    try {
      return await getPlatformServices(payload?.data);
    } catch (error) {
      showErrorNotificationV2(error, payload?.showNotification);
    }
  },
);

export const fetchPlatformServiceItem = createAsyncThunk(
  'fetchPlatformServiceItem',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: undefined}),
      update: async () =>
        await updatePlatformService(payload?.id, payload?.data),
      default: async () => await getPlatformService(payload?.id),
    }),
);
export const fetchPlatformSubscriptionBundles = createAsyncThunk(
  'fetchPlatformSubscriptionBundles',
  async (payload: any) => {
    try {
      return await getPlatformSubscriptionBundles(payload?.data);
    } catch (error) {
      showErrorNotificationV2(error, payload?.showNotification);
    }
  },
);
export const fetchPlatformSubscriptionItems = createAsyncThunk(
  'fetchPlatformSubscriptionItems',
  async (payload: any) => {
    try {
      return await getPlatformSubscriptionItems(payload?.data);
    } catch (error) {
      showErrorNotificationV2(error, payload?.showNotification);
    }
  },
);
export const fetchPlatformSubscriptionItem = createAsyncThunk(
  'fetchPlatformSubscriptionItem',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: undefined}),
      create: async () => await createPlatformSubscriptionItem(payload?.data),
      update: async () =>
        await updatePlatformSubscriptionItem(payload?.id, payload?.data),
      delete: async () => await deletePlatformSubscriptionItem(payload?.id),
      default: async () => await getPlatformSubscriptionItem(payload?.id),
    }),
);
export const fetchPlatformSubscriptionBundle = createAsyncThunk(
  'fetchPlatformSubscriptionBundle',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: undefined}),
      create: async () => await createPlatformSubscriptionBundle(payload?.data),
      update: async () =>
        await updatePlatformSubscriptionBundle(payload?.id, payload?.data),
      delete: async () => await deletePlatformSubscriptionBundle(payload?.id),
      default: async () => await getPlatformSubscriptionBundle(payload?.id),
    }),
);
export const fetchPlatformSubscriptionItemTypes = createAsyncThunk(
  'fetchPlatformSubscriptionItemTypes',
  async (payload: any) => {
    try {
      return await getPlatformSubscriptionItemTypes(payload?.data);
    } catch (error) {
      showErrorNotificationV2(error, payload?.showNotification);
    }
  },
);
export const fetchPlatformSubscriptionStatuses = createAsyncThunk(
  'fetchPlatformSubscriptionStatuses',
  async (payload: any) => {
    try {
      return await getPlatformSubscriptionStatuses(payload?.data);
    } catch (error) {
      showErrorNotificationV2(error, payload?.showNotification);
    }
  },
);
export const fetchPlatformSubscriptionPricingModels = createAsyncThunk(
  'fetchPlatformSubscriptionPricingModels',
  async (payload: any) => {
    try {
      return await getPlatformSubscriptionPricingModels(payload?.data);
    } catch (error) {
      showErrorNotificationV2(error, payload?.showNotification);
    }
  },
);

export const fetchRoles = createAsyncThunk(
  'fetchRoles',
  async (payload: any) => {
    try {
      return await getRoles(payload?.data);
    } catch (error) {
      showErrorNotificationV2(error, payload?.showNotification);
    }
  },
);

export const fetchRole = createAsyncThunk(
  'fetchRole',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: undefined}),
      default: async () => await getRole(payload?.id),
      create: async () => await createRole(payload?.data),
      update: async () => await updateRole(payload?.id, payload?.data),
      delete: async () => await deleteRole(payload?.id),
      updatePermissions: async () =>
        await updateRolePermissions(payload?.id, payload?.data),
    }),
);
export const fetchSubscriptionOptionCategories = createAsyncThunk(
  'fetchSubscriptionOptionCategories',
  async (payload: any) => {
    try {
      return await getSubscriptionOptionCategories();
    } catch (error) {
      showErrorNotificationV2(error, payload?.showNotification);
    }
  },
);
export const fetchOptions = createAsyncThunk(
  'fetchOptions',
  async (payload: any) => {
    try {
      return await getSubscriptionOptions();
    } catch (error) {
      showErrorNotificationV2(error, payload?.showNotification);
    }
  },
);
export const fetchOption = createAsyncThunk(
  'fetchOption',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: null}),
      default: async () => await getSubscriptionOption(payload?.id),
      create: async () => await createSubscriptionOption(payload?.data),
      update: async () =>
        await updateSubscriptionOption(payload?.id, payload?.data),
      delete: async () => await deleteSubscriptionOption(payload?.id),
    }),
);
export const fetchSubscriptionOptionToBundle = createAsyncThunk(
  'fetchSubscriptionOptionToBundle',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: null}),
      default: async () => await getSubscriptionOptionsToBundle(payload?.id),
      update: async () =>
        await updateSubscriptionOptionsToBundle(payload?.id, payload?.data),
    }),
);

export const fetchPermissions = createAsyncThunk(
  'fetchPermissions',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: []}),
      default: async () => await getPermissions(payload?.data),
    }),
);

export const platformSlice = createSlice({
  name: 'platform',
  initialState: {
    services: {data: []},
    service: {data: null},
    subscriptionBundles: {data: []},
    subscriptionBundle: {data: null},
    subscriptionItems: {data: []},
    subscriptionItem: {data: null},
    subscriptionItemTypes: {data: []},
    subscriptionStatuses: {data: []},
    subscriptionPricingModels: {data: []},
    roles: {data: []},
    role: {data: null},
    permissions: {data: []},
    subscriptionOptions: {data: []},
    subscriptionOption: {data: null},
    optionCategories: {data: []},
    subscriptionBundleOptions: {data: []},
  },
  reducers: {},
  extraReducers: builder => {
    addBuilderCases(builder, fetchPlatformServices, 'services');
    addBuilderCases(builder, fetchPlatformServiceItem, 'service');
    addBuilderCases(
      builder,
      fetchPlatformSubscriptionBundles,
      'subscriptionBundles',
    );
    addBuilderCases(
      builder,
      fetchPlatformSubscriptionBundle,
      'subscriptionBundle',
    );
    addBuilderCases(
      builder,
      fetchPlatformSubscriptionItems,
      'subscriptionItems',
    );
    addBuilderCases(
      builder,
      fetchPlatformSubscriptionItemTypes,
      'subscriptionItemTypes',
    );
    addBuilderCases(
      builder,
      fetchPlatformSubscriptionStatuses,
      'subscriptionStatuses',
    );
    addBuilderCases(
      builder,
      fetchPlatformSubscriptionPricingModels,
      'subscriptionPricingModels',
    );
    addBuilderCases(builder, fetchPlatformSubscriptionItem, 'subscriptionItem');
    addBuilderCases(builder, fetchRoles, 'roles');
    addBuilderCases(builder, fetchRole, 'role');
    addBuilderCases(builder, fetchPermissions, 'permissions');
    addBuilderCases(builder, fetchOption, 'subscriptionOption');
    addBuilderCases(builder, fetchOptions, 'subscriptionOptions');
    addBuilderCases(
      builder,
      fetchSubscriptionOptionCategories,
      'optionCategories',
    );
    addBuilderCases(
      builder,
      fetchSubscriptionOptionToBundle,
      'subscriptionBundleOptions',
    );
  },
});
