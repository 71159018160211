import styled from 'styled-components';
export const Root = styled.div`
  .profile-notification-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: ${props => props.theme.fontSizeSmall};
    color: ${props => props.theme.textInputColor};
    padding: 15px 20px;
    border-bottom: 1px solid ${props => props.theme.lightGrayColor};

    .profile-notification-item-title {
      margin-bottom: 5px;
    }

    .profile-notification-item-close {
      color: ${props => props.theme.primaryColor};
      cursor: pointer;
    }

    &:hover {
      background-color: ${props => props.theme.extraLightGreyColor};
    }
  }

  .header-icon {
    cursor: pointer;
    svg {
      path {
        fill: ${props => props.theme.primaryColor};
      }
    }
  }
`;
