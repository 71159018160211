import React from 'react';
import PropTypes from 'prop-types';
import {useForm, Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {FooterForm} from '../styled';
import FieldWrapper from '../../../components/common/fieldwrapper/FieldWrapper';
import {InputField} from '../../../components/common/inputField';
import Button from '../../../components/common/button/Button';
import {EMAIL_IS_INVALID, ERROR_REQUIRED_TEXT} from '../../../constants';
import {EMAIL_EXPRESSION} from '../../../regexp/regexp';
import {GrayText, LinkForm, ValidateText} from '../../../layout/styled';

export const ForgotPasswordForm = ({onSubmitHandler, value = ''}) => {
  const {handleSubmit, control, reset, formState: { errors }} = useForm();
  const {t} = useTranslation(['login']);
  const onSubmit = data => {
    onSubmitHandler(data);
    reset({email: '', password: ''});
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <GrayText marginBottom="45px">
        {/*Mozzarella chalk and cheese cow gouda fondue macaroni cheese*/}
        {/*chalk and cheese squirty cheese.*/}
      </GrayText>
      <FieldWrapper title={t('Email address')}>
        <Controller
          control={control}
          render={({field: {onChange, value, name, ref}}) => (
            <InputField
              type="text"
              className="form-control"
              value={value}
              onChangeHandler={value => {
                onChange(value);
              }}
            />
          )}
          name="email"
          rules={{
            required: {
              value: true,
              message: ERROR_REQUIRED_TEXT,
            },
            pattern: {
              value: EMAIL_EXPRESSION,
              message: EMAIL_IS_INVALID,
            },
          }}
          defaultValue={value || ''}
        />
        {errors?.email && <ValidateText>{errors.email?.message}</ValidateText>}
      </FieldWrapper>
      <FooterForm>
        <LinkForm to="/">{t('Back to login')}</LinkForm>
        <Button btnClass="btn-classic" onClick={() => true}>
          {t('Send')}
        </Button>
      </FooterForm>
    </form>
  );
};
ForgotPasswordForm.propTypes = {
  onSubmitHandler: PropTypes.func,
};
