import styled from 'styled-components';

export const DatePickerWrapper = styled.div`
    .date-picker-input {
        height: 40px;
        width: 100%;
        padding: 10px 0;
        background: #ffffff;
      border: 1px solid ${props => props.theme.borderTables};
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        color: inherit;
        font-size: 14px;
        line-height: 20px;
        box-sizing: border-box;
        position: relative;

        span {
            padding: 0 10px;
        }

        .date-picker-input-icon {
            width: 40px;
            text-align: center;
        }

        &.with-icon:before {
            position: absolute;
            content: '';
            top: 0;
            right: 40px;
            width: 1px;
            height: 100%;
            background-color: #e0e0e1;
        }
    }
`;
