import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import * as S from './styled';

const TooltipWrapper = ({
  text,
  children,
  placement = 'topRight',
  offset = [15, -10],
  trigger = 'hover',
}) => {
  return (
    <S.Root>
      <div className="tooltip-wrapper">
        <Tooltip
          overlayClassName="react-tooltip"
          trigger={trigger}
          placement={placement}
          align={{offset}}
          overlay={text}
          arrowContent={<div className="rc-tooltip-arrow-inner" />}>
          {children}
        </Tooltip>
      </div>
    </S.Root>
  );
};

TooltipWrapper.propTypes = {
  text: PropTypes.string,
  children: PropTypes.any,
  offset: PropTypes.arrayOf(PropTypes.number),
  placement: PropTypes.string,
  trigger: PropTypes.string,
};

export default TooltipWrapper;
