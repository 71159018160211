import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {InputField} from '../../components/common/inputField';
import Button from '../../components/common/button/Button';
import {LayoutAuthPages} from './components';
import {
  StyledResetPassword,
  StyledBlock,
  StyledInput,
  StyledPageInnerWrapper,
  StyledButton,
  StyledPageSpaceNormal,
} from './styled';
import {GrayText, ValidateText} from '../../layout/styled';
import {apiUrl} from '../../utils';
import {withNotification} from '../../hocs/withNotification';
import {ERROR_REQUIRED_TEXT, PASSWORD_DOES_NOT_MATCH} from '../../constants';
import {retrieveToken} from './helpers/loginHelpers';

let email;
let token = '';

const ResetPassword = ({showNotification}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {handleSubmit, control, errors, getValues} = useForm();
  const {t} = useTranslation();

  const submitToNext = (email, password) => {
    const requestTokenData = {
      email,
      password,
    };
    retrieveToken(requestTokenData, (result, message) => {
      if (result) {
        navigate('/');
      } else {
        showNotification({message, isError: true});
      }
    });
  };

  const getErrorMessage = errors => {
    let result;
    if (errors) {
      const values = Object.values(errors);
      result = values.map(value => t(value));
    }
    return result;
  };

  const changePasswordRequest = async data => {
    const url = '/v1/login/reset';
    const headers = {
      'Content-type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'nl-NL',
    };
    try {
      const response = await fetch(apiUrl + url, {
        headers,
        method: 'POST',
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .catch(error => {
          showNotification({
            message: String(error),
            is_error: true,
          });
        });
      const {success, error, message, errors} = response;
      if (success) {
        showNotification({message: t(success), is_error: false});
        submitToNext(data?.email, data?.password);
      } else if (error) {
        showNotification({message: t(error), is_error: true});
      } else if (message || errors) {
        showNotification({
          message: `${t(message)} ${getErrorMessage(errors || {})}`,
          is_error: true,
        });
      } else {
        showNotification({
          message: JSON.stringify(response),
          is_error: true,
        });
      }
    } catch (error) {
      showNotification({message: String(errors), is_error: true});
    }
  };

  const onSubmit = () => {
    handleSubmit(data => {
      data.email = email;
      data.token = token;
      const requestData = {...data};
      requestData.password_confirmation = data?.confirm;
      changePasswordRequest(requestData);
    })();
  };

  const getLocationPathKeyValue = key => {
    const query = location.search.split('&');
    const currentQuery = query.find(item => item.includes(key));
    if (currentQuery) {
      const value = currentQuery.substring(currentQuery.indexOf('=', 0) + 1);
      return value;
    }
    return null;
  };

  useEffect(() => {
    email = decodeURIComponent(getLocationPathKeyValue('email'));
    token = getLocationPathKeyValue('token');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutAuthPages>
      <StyledPageSpaceNormal>
        <StyledPageInnerWrapper>
          <GrayText marginBottom="45px">Uw wachtwoord instellen</GrayText>
          <StyledResetPassword>
            <StyledBlock>
              <StyledInput>
                <Controller
                  control={control}
                  name="password"
                  render={({onChange}) => (
                    <InputField
                      className="form-control"
                      type="password"
                      onChangeHandler={onChange}
                    />
                  )}
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: ERROR_REQUIRED_TEXT,
                    },
                  }}
                />
                {errors.password && (
                  <ValidateText>{errors.password?.message}</ValidateText>
                )}
              </StyledInput>
            </StyledBlock>
            <StyledBlock>
              <StyledInput>
                <Controller
                  control={control}
                  name="confirm"
                  render={({onChange}) => (
                    <InputField
                      className="form-control"
                      type="password"
                      onChangeHandler={onChange}
                    />
                  )}
                  defaultValue=""
                  rules={{
                    validate: {
                      notMatch: value => value === getValues('password'),
                    },
                  }}
                />
                {errors.confirm && errors.confirm.type === 'notMatch' && (
                  <ValidateText>{PASSWORD_DOES_NOT_MATCH}</ValidateText>
                )}
              </StyledInput>
            </StyledBlock>
          </StyledResetPassword>
          <StyledButton>
            <Button onClick={onSubmit}>Reset Wachtwoord</Button>
          </StyledButton>
        </StyledPageInnerWrapper>
      </StyledPageSpaceNormal>
    </LayoutAuthPages>
  );
};

export default withNotification(ResetPassword);
