import styled from 'styled-components';

export const Root = styled.div`
  .breadcrumb-item {
    color: ${props => props.theme.primaryColor};
    font-size: 12px;
    display: inline-block;

    a {
      color: ${props => props.theme.primaryColor} !important;
      outline: none;
      text-decoration: none !important;
    }

    & + .breadcrumb-item::before {
      content: '>';
      color: ${props => props.theme.primaryColor};
    }
  }
`;
