import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {showErrorNotificationV2} from '../../utils';
import {addBuilderCases} from '../utils';
import {getShippingInvoices} from '../../api/helpers/reporting';

export const fetchShippingInvoices = createAsyncThunk(
  'fetchShippingInvoices',
  async (payload: any) => {
    try {
      return await getShippingInvoices(payload?.data);
    } catch (error) {
      showErrorNotificationV2(error, payload?.showNotification);
    }
  },
);

export const reportingSlice = createSlice({
  name: 'reporting',
  initialState: {
    shippingInvoices: {data: []},
  },
  reducers: {},
  extraReducers: builder => {
    addBuilderCases(builder, fetchShippingInvoices, 'shippingInvoices');
  },
});
