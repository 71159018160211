import React, {FunctionComponent} from 'react';
import * as S from './styled';

const tableItems: Array<number> = [1, 2, 3, 4, 5, 6, 7];
const tableBodyRow: Array<number> = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

type SkeletonProps = {
  onlyTable: boolean;
};

const Skeleton: FunctionComponent<SkeletonProps> = ({onlyTable}) => {
  return (
    <S.ContainerPage onlyTable={onlyTable}>
      <S.PageHeader>
        <S.HeaderTitle> </S.HeaderTitle>
        <S.HeaderButton> </S.HeaderButton>
      </S.PageHeader>
      <S.PageTable>
        <S.TableFilter>
          <S.SearchPanel> </S.SearchPanel>
          <S.FilterPanel> </S.FilterPanel>
        </S.TableFilter>
        <S.Table>
          <S.TableHeader>
            {tableItems.map(headerItem => (
              <S.TableHeaderItem key={headerItem}> </S.TableHeaderItem>
            ))}
          </S.TableHeader>
          <S.TableBody>
            {tableBodyRow.map(row => (
              <S.TableBodyRow key={row}>
                {tableItems.map(bodyItem => (
                  <S.TableBodyItem key={bodyItem}> </S.TableBodyItem>
                ))}
              </S.TableBodyRow>
            ))}
          </S.TableBody>
          <S.TableFooter>
            <S.CountElements> </S.CountElements>
            <S.NumberPage> </S.NumberPage>
          </S.TableFooter>
        </S.Table>
      </S.PageTable>
    </S.ContainerPage>
  );
};

export default Skeleton;
