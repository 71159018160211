import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {
  getLanguages,
  getCountries,
  updateCountry,
  getCurrencies,
  getRegions,
  getStates,
  getUnits, uploadCountryImage, deleteCountryImages,
} from '../../api/helpers/common';
import {showErrorNotificationV2} from '../../utils';
import {addBuilderCases, asyncThunkHelper} from '../utils';

export const fetchLanguages = createAsyncThunk(
  'fetchLanguages',
  async (payload: any) => {
    try {
      return await getLanguages(payload?.data);
    } catch (error) {
      showErrorNotificationV2(error, payload?.showNotification);
    }
  },
);

export const fetchCountries = createAsyncThunk(
  'fetchCountries',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: []}),
      default: async () => await getCountries(payload?.data),
      update: async () => await updateCountry(payload?.id, payload?.data),
    }),
);

export const fetchCountry = createAsyncThunk(
  'fetchCountry',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: undefined}),
      update: async () => await updateCountry(payload?.id, payload?.data),
    }),
);

export const fetchCurrencies = createAsyncThunk(
  'fetchCurrencies',
  async (payload: any) => {
    try {
      return await getCurrencies(payload?.data);
    } catch (error) {
      showErrorNotificationV2(error, payload?.showNotification);
    }
  },
);

export const fetchRegions = createAsyncThunk(
  'fetchRegions',
  async (payload: any) => {
    try {
      return await getRegions(payload?.data);
    } catch (error) {
        showErrorNotificationV2(error, payload?.showNotification);
    }
  },
);

export const fetchStates = createAsyncThunk(
  'fetchStates',
  async (payload: any) => {
    try {
      return await getStates(payload?.id);
    } catch (error) {
      showErrorNotificationV2(error, payload?.showNotification);
    }
  },
);
export const fetchUnits = createAsyncThunk(
  'fetchUnits',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      default: async () => await getUnits(payload),
    }),
);

export const fetchCountryImage = createAsyncThunk(
  'fetchCountryImage',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: undefined}),
      upload: async () => await uploadCountryImage(payload?.id, payload?.data),
      delete: async () => await deleteCountryImages(payload?.id, payload?.data),
    }),
);

export const commonSlice = createSlice({
  name: 'common',
  initialState: {
    languages: {data: []},
    countries: {data: []},
    country: {data: null},
    currencies: {data: []},
    regions: {data: []},
    states: {data: []},
    units: {data: []},
    countryImage: {data: null},
  },
  reducers: {},
  extraReducers: builder => {
    addBuilderCases(builder, fetchLanguages, 'languages');
    addBuilderCases(builder, fetchCountries, 'countries');
    addBuilderCases(builder, fetchCountry, 'country');
    addBuilderCases(builder, fetchCurrencies, 'currencies');
    addBuilderCases(builder, fetchRegions, 'regions');
    addBuilderCases(builder, fetchStates, 'states');
    addBuilderCases(builder, fetchUnits, 'units');
    addBuilderCases(builder, fetchCountryImage, 'countryImage');
  },
});
