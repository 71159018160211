export const defaultTheme = {
  // Colors
  borderGray: '#E6E6E6',
  borderTables: '#e0e0e1',
  strongGrey: '#6E6E6E',
  primaryColor: process.env.REACT_APP_THEME_PRIMARY_COLOR || '#307feb',
  backgroundColor: '#ffffff',
  lightGrayColor: '#e5e5e5',
  borderColor: '#d5d5d5',
  textInputColor: '#454545',
  blackColor: '#000000',
  extraLightGreyColor: '#f7f7f7',
  whiteColor: '#ffffff',
  footerTextColor: '#8a8a8a',
  errorText: '#c10101',
  textColor: '#707070',
  statusSuccess: '#73C060',
  statusUnspecified: '#D5D5D5',
  disabledSeatsColor: 'gray',
  busySeatsColor: 'red',
  freeSeatsColor: 'green',
  orderedSeatsColor: 'orange',
  boxShadow: '0px 8px 20px #0000000d',
  boxShadowTabs: '0px 0px 20px #0000000d',

  //statuses
  newBackground: '#3587b9',
  newText: '#fff',

  scheduledBackground: '#e5e5e5',
  scheduledText: '#6E6E6E',

  unpaidBackground: '#E5E5E5',
  unpaidText: '#6E6E6E',

  inactiveBackground: '#E5E5E5',
  inactiveText: '#6E6E6E',

  reScheduleBackground: '#fdeaa7',
  reScheduleText: '#DE5600',

  publishedBackground: '#c2e6c4',
  publishedText: '#215024',

  finishedBackground: '#c2e6c4',
  finishedText: '#215024',

  activeBackground: '#c2e6c4',
  activeText: '#215024',

  paidBackground: '#c2e6c4',
  paidText: '#215024',

  validBackground: '#c2e6c4',
  validText: '#215024',

  openBackground: '#c2e6c4',
  openText: '#215024',

  draftBackground: '#fdeaa7',
  draftText: '#DE5600',

  cancelledBackground: '#f48989',
  cancelledText: '#7C0000',

  overDueBackground: '#FF8888',
  overDueText: '#7C0000',

  // 402 v2 status colors
  pendingBackground: '#F29339',
  pendingText: '#fff',

  awaitingBackground: '#F7CB73',
  awaitingText: '#6E6E6E',

  receivedBackground: '#35B449',
  receivedText: '#fff',

  completedBackground: '#077E8C',
  completedText: '#fff',

  canceledBackground: '#FF8888',
  canceledText: '#7C0000',

  expiredBackground: '#e5e5e5',
  expiredText: '#6F6F6F',

  refundedBackground: '#0287D6',
  refundedText: '#fff',

  failedBackground: '#c73f27',
  failedText: '#6E6E6E',

  deletedBackground: '#912926',
  deletedText: '#fff',

  //Spaces
  spaceExtraSmall: '5px',
  spaceSmall: '10px',
  spaceGeneral: '15px',
  spaceNormal: '20px',
  spaceMedium: '30px',
  spaceLarge: '40px',
  spaceExtraLarge: '60px',

  // Fonts
  primaryFontFamily: 'Open Sans',
  fontSizeExtraSmall: '12px',
  primaryFontSize: '13px',
  fontSizeNormal: '16px',
  fontSizeMedium: '18px',
  fontSizeLarge: '20px',
  fontSizeExtraLarge: '32px',
  fontWeightLight: '300',
  fontWeightRegular: '400',
  fontWeightSemiBold: '600',
  fontWeightBold: '700',
  fontWeightExtraBold: '800',

  // Borders
  borderRadiusClassic: '5px',
  borderRadiusRounded: '25px',
  borderRadiusCircle: '50%',

  // ScreenSize
  screenSize: {
    xxs: `@media(max-width:479px)`,
    sm: `@media(max-width:640px)`,
    md: `@media(max-width:768px)`,
    lg: `@media(max-width:1024px)`,
    xl: `@media(max-width:1280px)`,
    xxl: `@media(max-width:1620px)`,
  },
};


export const generalTheme = (() => {
  switch (process.env.REACT_APP_APPSCHEME) {
    default:
      return defaultTheme;
  }
})();
