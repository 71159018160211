import styled from 'styled-components';

export const Root = styled.div`
  .sidebar-wr {
    //height: 100vh;
    width: 80px;
    position: relative;
    box-shadow: 3px 1px 5px 0 rgba(0, 0, 0, 0.25);
    transition: all 0.4s;
    padding: 60px 0 80px 0;
    background-color: ${props => props.theme.backgroundColor};

    .sidebar-list {
      .sidebar-item {
        padding: 0 20px;
        margin-bottom: 20px;

        a {
          display: block;
          position: relative;

          .sidebar-item-img {
            display: block;
            border-radius: 50%;
            transition: all 0.4s;
            width: 40px;
            height: 40px;
            position: relative;

            &:hover {
              background-color: ${props => props.theme.primaryColor};

              svg {
                fill: ${props => props.theme.whiteColor};
              }
            }

            svg {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 20px;
              height: 20px;
              fill: ${props => props.theme.primaryColor};
            }
          }

          .sidebar-item-title {
            position: absolute;
            top: 10px;
            left: 40px;
            color: #000;
            font-size: 14px;
            line-height: 20px;
            font-weight: bold;
            opacity: 0;
            visibility: hidden;
            transition-delay: 0s;
          }
        }
      }
    }

    .sidebar-btn {
      width: 40px;
      height: 40px;
      background-color: ${props => props.theme.primaryColor};
      border-radius: 50%;
      position: absolute;
      bottom: 30px;
      left: 20px;
      transition: all 0.4s;
      cursor: pointer;

      svg {
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 12px;
      }
    }

    &.open {
      width: 200px;

      .sidebar-btn {
        transform: rotate(180deg);
      }

      .sidebar-list {
        .sidebar-item {
          a {
            .sidebar-item-title {
              opacity: 1;
              visibility: visible;
              transition-delay: 0.4s;
            }
            .sidebar-item-img {
              &:hover {
                background-color: unset;
              }
              svg {
                fill: ${props => props.theme.primaryColor};
              }
            }
          }

          &:hover {
            background-color: #f7f7f7;
          }
        }
      }
    }
  }
`;
