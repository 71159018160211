import ApiV2 from '../ApiV2';

export const getLanguages = async (payload: any): Promise<any> => {
  const response = await ApiV2.cp.common.languages.get(payload);
  return response?.data;
};

export const getCountries = async (payload: any): Promise<any> => {
  const response = await ApiV2.cp.common.countries.get(payload);
  return response?.data;
};

export const updateCountry = async (id: number, data: object): Promise<any> => {
  const response = await ApiV2.cp.common.countries.patch(id, data);
  return response?.data;
};

export const getCurrencies = async (payload: any): Promise<any> => {
  const response = await ApiV2.cp.common.currencies.get(payload);
  return response?.data;
};

export const getRegions = async (payload: any): Promise<any> => {
  const response = await ApiV2.cp.common.regions.get(payload);
  return response?.data;
};

export const getStates = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.common.countries.states.get(id);
  return response?.data;
};
export const getUnits = async (payload: any): Promise<any> => {
  const response = await ApiV2.cp.common.units.get(payload);
  return response?.data;
};

export const uploadCountryImage = async (id: number, data: any): Promise<any> => {
  const response = await ApiV2.cp.common.countries.images.post({id}, data);
  return response?.data;
};

export const deleteCountryImages = async (id: number, data: any): Promise<any> => {
  const response = await ApiV2.cp.common.countries.images.delete({id}, data);
  return response?.data;
};
