import styled from 'styled-components';

export const RootContainer = styled.div`
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  //min-height: 100vh;
  //background: $backgroundColor;
  overflow: scroll;
  //overflow-y: auto;
  height: 100%;
  background-color: #f8f8fa;
  min-width: 865px;
`;

export const Header = styled.div<{$minWidthHeader: string}>`
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  background-color: white;
  border-bottom: 1px solid ${props => props.theme.borderColor};
  min-width: ${props => props?.$minWidthHeader};
  max-height: 60px;
`;

export const Left = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  min-height: 30px;
  margin-left: 30px;
`;

export const Buttons = styled.div`
  display: flex;
  gap: 20px;
  margin-right: 30px;
`;

export const Save = styled.div`
  width: 100%;
  .btn {
    padding: 0;
    min-width: 73px;
    min-height: 40px;
  }
`;

export const Cancel = styled(Save)`
  .btn {
    background-color: rgb(255, 255, 255);
    color: rgb(48, 127, 235);
    border: solid 1px rgb(48, 127, 235);
  }
`;

export const Close = styled.div`
  color: ${props => props.theme.primaryColor};
  transform: rotate(180deg);
  svg.icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`;

export const Divider = styled.div`
  height: 30px;
  margin-right: 20px;
  margin-left: 20px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    width: 1px;
    height: 50px;
    background-color: ${props => props.theme.borderColor};
  }
`;

export const Logo = styled.img`
  width: 25px;
  height: 25px;
`;

export const LogoLabel = styled.h3`
  margin: 0;
  margin-left: 10px;
  font-size: 18px;
  font-weight: bold;
`;

export const Title = styled.h3`
  margin: 0;
  margin-left: 10px;
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.theme.primaryColor};
`;

export const Children = styled.div`
  background-color: #f8f8fa;
  width: 100%;
  height: 100%;
`;
