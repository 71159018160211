import styled from 'styled-components';

export const DatePickerWrapper = styled.div`
    .date-picker-wrapper {
        display: flex;
        justify-content: space-between;
        column-gap: 20px;
        width: 100% !important;
        .react-datepicker-wrapper {
            display: block;
            width: 100%;
            margin-left: 10px;
            &:first-child {
                margin-left: 0;
            }
            .react-datepicker__close-icon {
                position: absolute;
                right: 50px;

                &:after {
                    background-color: ${props => props.theme.primaryColor};
                }
            }
        }
        &.timepicker .react-datepicker-wrapper .react-datepicker__close-icon {
            right: 10px;
        }
    }
`;

export const DatePickerBlockWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    justify-content: left;
    align-items: flex-start;
    width: 100%;
    .react-datepicker-wrapper {
        margin-left: 0 !important;
    }
    h4 {
        font-size: 18px;
        line-height: unset;
        text-align: left;
    }
`;
