import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import {en_US, uk_UA, nl_NL, nl_BE, fr_FR, de_DE} from '../locales';

const languageDetector = {
  type: 'languageDetector',
  async: true,
  detect: cb => cb('en_US'),
  init: () => {},
  cacheUserLanguage: () => {},
};

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en_US', // the translations
    fallbackNS: 'translation',
    lng: 'en_US',
    resources: {
      en_US: {translation: en_US},
      uk_UA: {translation: uk_UA},
      nl_NL,
      nl_BE: {translation: nl_BE},
      fr_FR: {translation: fr_FR},
      de_DE: {translation: de_DE},
      ns: ['translation'],
    },
  });
// export default i18n;
