import React, {BaseSyntheticEvent} from 'react';
import PropTypes from 'prop-types';
import {defaultTheme} from '../../../layout/theme';
import * as S from './styled';

const {primaryColor} = defaultTheme;

type PropsType = {
  onClick: (
    e: (BaseSyntheticEvent<object, any, any> | undefined) | any,
  ) => void;
  bgColor?: string;
  disabled?: boolean;
  btnClass?: string;
  inverted?: boolean;
  width?: string | number;
  children: JSX.Element | string;
  entity?: unknown;
};

const Button: React.FC<PropsType> = props => {
  const {
    onClick,
    bgColor,
    disabled = false,
    btnClass,
    inverted,
    width,
    children,
    entity,
  } = props;
  const boundOnClick = () => onClick(entity);
  const style = {
    backgroundColor: inverted ? '#fff' : bgColor ? bgColor : '#307feb',
    color: inverted ? bgColor : '#fff',
    border: `1px solid ${bgColor ? bgColor : '#307feb'}`,
    width: width || 'auto',
    minHeight: 30,
    borderRadius: 5,
  };
  return (
    <S.Button
      onClick={boundOnClick}
      className={`btn ${btnClass}`}
      style={style}
      disabled={disabled}>
      {children}
    </S.Button>
  );
};
export default Button;
