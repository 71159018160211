export const paths = t => [
  {
    url: '/',
    name: t('Home'),
  },
  {
    url: '/events',
    name: t('Events'),
  },
  {
    url: '/crm',
    name: t('CRM'),
  },
  {
    url: '/venues',
    name: t('Venues'),
  },
  {
    url: '/cms',
    name: t('CMS'),
  },
  {
    url: '/products',
    name: t('Products'),
  },
  {
    url: '/shops',
    name: t('Shops'),
  },
  {
    url: '/scanner',
    name: t('Scanner'),
  },
  {
    url: '/finance',
    name: t('Finance'),
  },
  {
    url: '/support',
    name: t('Support'),
  },
  {
    url: '/settings',
    name: t('Settings'),
  },
  {
    url: '/profile',
    name: t('My profile'),
  },
  {
    url: '/profile/support',
    name: t('Support'),
  },
  {
    url: '/settings/company',
    name: 'Company',
  },
  {
    url: '/settings/team-members',
    name: 'Team members',
  },
];
