import styled from 'styled-components';
export const Root = styled.div`
  .popup-wrapper {
    position: relative;
    display: inline-block;
    .popup {
      background: #ffffff;
      border: 1px solid ${props => props.theme.borderColor};
      border-radius: 5px;
      position: absolute;
      right: -10px;
      top: calc(100% + 15px);
      z-index: 99;
      .content {
        min-height: 40px;
        max-height: 300px;
        overflow-y: auto;
      }
      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 100%;
        width: 0;
        height: 0;
      }
      &:before {
        right: 12px;
        border: 11px solid transparent;
        border-bottom-color: ${props => props.theme.borderGray};
      }
      &:after {
        right: 14px;
        border: 9px solid transparent;
        border-bottom-color: #fff;
      }

      &.triangle-left {
        right: unset;
        left: -10px;
        &:before {
          left: 12px;
        }
        &:after {
          left: 14px;
        }
      }
    }

    /* width */
    ::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #fff;
      border-radius: 5px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${props => props.theme.lightGrayColor};
      border-radius: 10px;
      border: 3px solid #fff;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: ${props => props.theme.lightGrayColor};
    }
  }
`;
