import React, {FunctionComponent, useEffect, useImperativeHandle} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {AppDispatch} from '../../../../../store';
import Loader from '../../../../../components/common/Loader/Loader';
import FieldWrapper from '../../../../../components/common/fieldwrapper/FieldWrapper';
import {Dropdown, InputField, Switch} from '../../../../../components/common';
import {ERROR_REQUIRED_TEXT} from '../../../../../constants';
import {ValidateText} from '../../../../../layout/styled';
import Button from '../../../../../components/common/button/Button';
import * as S from './styled';
import {fetchClientType} from '../../../../../store/client/clientSlice';
import {fetchPlatformSubscriptionBundles} from '../../../../../store/platform/platformSlice';
import {FormDataType, IClientType, IClientTypeForm} from '../../services';
import * as GS from '../../../../../layout/styled';
import {Breadcrumbs, Typography} from '@mui/material';
import {Link} from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import {CLIENT_SETTINGS, SETTINGS} from '../../../../../router/routerUrl';

type BreadcrumbsOnClick = (event: React.MouseEvent<HTMLAnchorElement>) => void;

export const ClientTypeForm: FunctionComponent<IClientTypeForm> =
  React.forwardRef((props, ref) => {
    const {showNotification, clientTypeId, withSave, onRefresh, onClose} =
      props;
    const dispatch = useDispatch<AppDispatch>();
    const {t} = useTranslation();
    const {data: clientType, loading: clientTypeLoading} = useSelector(
      (state: any) => state.client.clientType,
    );
    const {data: subscriptionBundles} = useSelector(
      (state: any) => state.platform.subscriptionBundles,
    );
    const loading = clientTypeLoading;

    const {
      name,
      subscription_bundles,
      is_active: status,
    }: IClientType = clientType || {};

    const defaultBundle: {} | undefined = subscription_bundles?.length
      ? subscription_bundles.find(
          (item: {is_default: boolean}) => item.is_default,
        )
      : {};

    const {
      control,
      handleSubmit,
      formState: {errors},
      reset,
      watch,
    } = useForm({
      defaultValues: {
        name,
        subscription_bundles,
        defaultBundle,
        status,
      },
    });

    const watchSubscriptionBundles = watch('subscription_bundles');

    const onSaveData: SubmitHandler<any> = (formData: FormDataType) => {
      const requestData = {
        name: formData?.name,
        is_active: formData?.status,
        ...(formData.subscription_bundles
          ? {
              subscription_bundles: formData.subscription_bundles.map(
                (item: {id: number}) => ({
                  id: item?.id,
                  is_default: item?.id === formData.defaultBundle?.id,
                }),
              ),
            }
          : {}),
      };

      dispatch(
        fetchClientType({
          type: clientType?.id ? 'update' : 'create',
          ...(clientType?.id ? {id: clientType?.id} : {}),
          data: requestData,
          showNotification,
          onSuccess: (data: any) => {
            if (data?.data?.id) {
              showNotification({
                message: clientType?.id
                  ? 'Client type was saved'
                  : 'Client was created',
                isError: false,
              });
              if (onRefresh) {
                onRefresh();
              }
            }
          },
          onError: (error: any) => {
            showNotification({
              message: error?.errors?.[0]?.messages?.[0],
            });
          },
        }),
      );
    };
    const retrieveClientType = (id: number) => {
      dispatch(fetchClientType({id}));
    };

    useEffect(() => {
      return () => {
        dispatch(fetchClientType({type: 'reset'}));
      };
    }, []);

    useEffect(() => {
      if (clientTypeId) {
        retrieveClientType(Number(clientTypeId));
      }
    }, [clientTypeId]);

    useEffect(() => {
      reset({
        name,
        subscription_bundles,
        status,
        defaultBundle,
      });
    }, [clientType]);

    useEffect(() => {
      dispatch(
        fetchPlatformSubscriptionBundles({
          showNotification,
        }),
      );
    }, []);

    useImperativeHandle(ref, () => ({
      handleSubmitRequest() {
        handleSubmit(onSaveData)();
      },
    }));

    const nameContainer = (
      <S.StyledInput>
        <FieldWrapper title={t('Name')}>
          <Controller
            control={control}
            name="name"
            render={({field: {onChange, value}}) => (
              <InputField
                type="text"
                onChangeHandler={onChange}
                value={value}
                maxLength={undefined}
                defaultValue={undefined}
                valid={undefined}
                step={undefined}
                onSubmitEditing={undefined}
                inputRef={undefined}
              />
            )}
            rules={{
              required: {
                value: true,
                message: t(ERROR_REQUIRED_TEXT),
              },
            }}
            defaultValue={name || ''}
          />
          {errors?.name && <ValidateText>{errors?.name?.message}</ValidateText>}
        </FieldWrapper>
      </S.StyledInput>
    );
    const statusContainer = (
      <S.StyledInput>
        <FieldWrapper title={t('Status')}>
          <Controller
            control={control}
            name="status"
            render={({field: {onChange, value}}) => (
              <>
                <span>Inactive</span>
                <Switch
                  fieldName="status"
                  onChange={onChange}
                  isChecked={value}
                />
                <span>Active</span>
              </>
            )}
            rules={{
              required: {
                value: false,
                message: t(ERROR_REQUIRED_TEXT),
              },
            }}
            defaultValue={status || false}
          />
          {errors?.status && (
            <ValidateText>{errors?.status?.message}</ValidateText>
          )}
        </FieldWrapper>
      </S.StyledInput>
    );

    const subscriptionBundleContainer = (
      <S.StyledInput>
        <FieldWrapper title={t('Subscription bundles')}>
          <Controller
            control={control}
            name="subscription_bundles"
            render={({field: {onChange, value}}) => (
              // @ts-ignore
              <Dropdown
                data={subscriptionBundles}
                value={value}
                onChange={onChange}
                closeMenuOnSelect
                isMulti={true}
              />
            )}
            rules={{
              required: {
                value: false,
                message: t(ERROR_REQUIRED_TEXT),
              },
            }}
            defaultValue={subscription_bundles}
          />
          {errors?.subscription_bundles && (
            <ValidateText>{errors?.subscription_bundles?.message}</ValidateText>
          )}
        </FieldWrapper>
      </S.StyledInput>
    );
    const defaultSubscriptionBundleContainer = (
      <S.StyledInput>
        <FieldWrapper title={t('Default subscription bundle')}>
          <Controller
            control={control}
            name="defaultBundle"
            render={({field: {onChange, value}}) => (
              // @ts-ignore
              <Dropdown
                data={watchSubscriptionBundles}
                onChange={onChange}
                closeMenuOnSelect
                value={value}
              />
            )}
            rules={{
              required: {
                value: false,
                message: t(ERROR_REQUIRED_TEXT),
              },
            }}
            defaultValue={defaultBundle}
          />
          {errors?.defaultBundle && (
            <ValidateText>{errors?.defaultBundle?.message}</ValidateText>
          )}
        </FieldWrapper>
      </S.StyledInput>
    );
    const saveButton = (
      <Button
        onClick={() => {
          handleSubmit(onSaveData)();
        }}>
        {t('Save')}
      </Button>
    );

    const loader = <Loader style={{zIndex: 99}} />;

    const breadcrumbsContainer = (
      <GS.BreadcrumbsWrapper>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            style={{display: 'flex', alignItems: 'center'}}
            color="inherit"
            to="/">
            <HomeIcon sx={{mr: 0.5}} fontSize="inherit" />
            {t('Home')}
          </Link>
          <Link
            style={{display: 'flex', alignItems: 'center'}}
            color="inherit"
            to={SETTINGS}>
            {t('Settings')}
          </Link>
          <Link
            onClick={onClose as BreadcrumbsOnClick}
            style={{display: 'flex', alignItems: 'center'}}
            color="inherit"
            to={CLIENT_SETTINGS}>
            {t('Client Types')}
          </Link>
          <Typography color="text.primary">
            {clientType?.id ? t('Edit Client Type') : t('Create Client Type')}
          </Typography>
        </Breadcrumbs>
      </GS.BreadcrumbsWrapper>
    );

    return (
      <>
        {loading && loader}
        <S.WindowContainer>
          <div
            style={{
              marginBottom: '10px',
            }}>
            {breadcrumbsContainer}
          </div>
          <S.FormContainer key={clientType}>
            <S.Row
              $withoutBorderTop
              $withoutBorderRight
              $withoutBorderLeft
              $withoutBorderBottom>
              <S.LeftContainer>
                {nameContainer}
                {/*{statusContainer}*/}
              </S.LeftContainer>
              <S.RightContainer>
                {subscriptionBundleContainer}
                {defaultSubscriptionBundleContainer}
              </S.RightContainer>
            </S.Row>
            {withSave && (
              <S.ButtonsArea>
                <S.SaveButton>{saveButton}</S.SaveButton>
              </S.ButtonsArea>
            )}
          </S.FormContainer>
        </S.WindowContainer>
      </>
    );
  });
