import ApiV2 from '../ApiV2';

export const getProductDesignerArtifacts = async (
  payload: any,
): Promise<any> => {
  const response = await ApiV2.cp.productDesigner.artifacts.get(payload);
  return response?.data;
};

export const uploadProductDesignerArtifact = async (
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.productDesigner.artifacts.post(data);
  return response?.data;
};

export const deleteProductDesignerArtifact = async (
  id: number,
): Promise<any> => {
  const response = await ApiV2.cp.productDesigner.artifacts.delete(id);
  return response?.data;
};

export const getProductDesignerTemplates = async (
  payload: any,
): Promise<any> => {
  const response = await ApiV2.cp.productDesigner.templates.get(payload);
  return response?.data;
};

export const getProductDesignerTemplate = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.productDesigner.templates.get(id);
  return response?.data;
};

export const createProductDesignerTemplate = async (
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.productDesigner.templates.post(data);
  return response?.data;
};

export const updateProductDesignerTemplate = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.productDesigner.templates.post(id, data);
  return response?.data;
};

export const deleteProductDesignerTemplate = async (
  id: number,
): Promise<any> => {
  const response = await ApiV2.cp.productDesigner.templates.delete(id);
  return response?.data;
};

export const createProductDesignerTemplateLayer = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.productDesigner.templates.layers.post(
    id,
    data,
  );
  return response?.data;
};

export const updateProductDesignerTemplateLayer = async (
  id: number,
  lid: any,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.productDesigner.templates.layers.post(
    // @ts-ignore
    {id, lid},
    data,
  );
  return response?.data;
};

export const deleteProductDesignerTemplateLayer = async (
  id: number,
  lid: any,
): Promise<any> => {
  const response = await ApiV2.cp.productDesigner.templates.layers.delete(
    id,
    lid,
  );
  return response?.data;
};

export const createProductDesignerTemplateOption = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.productDesigner.templates.options.post(
    id,
    data,
  );
  return response?.data;
};

export const updateProductDesignerTemplateOption = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.productDesigner.templates.options.patch(
    id,
    data,
  );
  return response?.data;
};

export const deleteProductDesignerTemplateOption = async (
  id: number,
  oid: any,
): Promise<any> => {
  const response = await ApiV2.cp.productDesigner.templates.options.patch(
    id,
    oid,
  );
  return response?.data;
};

export const cloneProductDesignerTemplate = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.productDesigner.templates.clone.post(
    id,
    data,
  );
  return response?.data;
};

export const assignProductDesignerTemplate = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.productDesigner.templates.assign.put(
    id,
    data,
  );
  return response?.data;
};

export const unAssignProductDesignerTemplate = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.productDesigner.templates.assign.delete(
    id,
    data,
  );
  return response?.data;
};
