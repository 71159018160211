import styled from 'styled-components';
export const AuthWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  > img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    filter: blur(5px);
  }
`;

export const AuthWindow = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  z-index: 1;
  background-color: ${props => props.theme.whiteColor};
`;

export const AuthLogo = styled.img`
  display: block;
  margin: 0 auto ${props => props.theme.spaceLarge} auto;
  max-width: 80px;
`;

export const AuthWindowImgWrapper = styled.div`
  display: block;
  flex: 45% 0;
  width: 100%;
  height: auto;
  min-height: 470px;
  position: relative;
`;

export const AuthWindowImg = styled.img`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const AuthForm = styled.div`
  display: block;
  flex: 55% 0;
  height: 100%;
  padding: 60px 60px 80px 60px;
`;

export const FooterForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const StyledResetPassword = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledBlock = styled.div`
  padding: 20px 20px 0 20px;
  width: 50%;
`;

export const StyledInput = styled.div`
  flex: 1;
  margin-bottom: ${props => props.theme.spaceSmall};
`;

export const StyledPageInnerWrapper = styled.div`
  max-width: 1120px;
  margin: 0 auto;
`;

export const StyledButton = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${props => props.theme.spaceNormal} 0;
  margin: 0 ${props => props.theme.spaceNormal};
  button {
    text-transform: uppercase;
  }
`;
export const StyledPageSpaceNormal = styled.div``;
