import styled from 'styled-components';

export const ButtonWrapper = styled.div``;

export const ToggleButtonCover = styled.div`
  display: table-cell;
  position: relative;
  width: 200px;
  height: 40px;
  box-sizing: border-box;
`;

export const ButtonCover = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const Knobs = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #fff;
  left: 10%;
  border-radius: 100px;
  color: #ff7f7f;
  font-size: 10px;
  transition: left ease-in-out 0.4s;
  font-weight: bold;
`;

export const Checkbox = styled.input`
  position: relative;
  cursor: pointer;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0;

  &:focus-visible {
    outline: none;
  }

  &:checked + ${Knobs} {
    left: 50%;
    color: ${props => props.theme.primaryColor};
  }
`;

export const Button = styled.div<{$active: boolean}>`
  position: relative;
  width: 50px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px ${props => props.theme.borderTables};
  border-radius: 10px;
  transition: background-color ease-in-out 0.4s;
  background-color: ${props => (props.$active ? '#7fbaff' : '#ff7f7fa3')};
`;
