import styled from 'styled-components';
export const Root = styled.div`
  .shortCut-wr {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    & > * {
      flex: 1 1 99px;
    }

    .shortCut-item {
      text-align: center;
      padding: 15px;
      font-size: ${props => props.theme.fontSizeSmall};
      color: ${props => props.theme.primaryColor};
      cursor: pointer;

      svg {
        path {
          fill: ${props => props.theme.primaryColor};
        }
      }

      &:hover {
        background-color: ${props => props.theme.extraLightGreyColor};
      }

      img {
        margin-bottom: 5px;
      }

      &:nth-child(odd) {
        border-right: 1px solid ${props => props.theme.lightGrayColor};
        border-top: 1px solid ${props => props.theme.lightGrayColor};
      }

      &:nth-child(even) {
        border-top: 1px solid ${props => props.theme.lightGrayColor};
      }

      &:first-child {
        border-top: none;
      }

      &:nth-child(2) {
        border-top: none;
      }
    }
  }

  .header-icon {
    svg {
      path {
        fill: ${props => props.theme.primaryColor};
      }
    }
  }
`;
