import styled from 'styled-components';

export const Root = styled.div``;
export const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px snow ${props => props.theme.borderColor};
    height: 70px;
    button{
      line-height: 15px !important;
      padding: 10px 20px !important;
    }
`;
