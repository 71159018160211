import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

function Status({name, data}) {
  let label = name || data.name;
  const createStatusClass = label => {
    return `status_${label.replace(/ /g, '_').toLowerCase()}`;
  };

  return (
    <S.Root>
      <div className={`status ${createStatusClass(label)}`}>
        <p className={`status-text ${createStatusClass(label)}`}>{label}</p>
      </div>
    </S.Root>
  );
}
Status.propTypes = {
  name: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default Status;
