import React from 'react';
import PropTypes from 'prop-types';
import {TailSpin} from 'react-loader-spinner';

export const defaultTheme = {
  primaryColor: process.env.REACT_APP_THEME_PRIMARY_COLOR || '#E30B13',
  backgroundColor: '#ffffff',
  lightGrayColor: '#e5e5e5',
  borderColor: '#d5d5d5',
  textInputColor: '#6e6e6e',
  fieldTitleColor: '#000000',
};

const ReactLoader = ({
  width = 100,
  height = 100,
  color = process.env.REACT_APP_THEME_PRIMARY_COLOR || '#E30B13',
  type = 'Puff',
  style,
}) => {
  const containerStyle = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(229, 229, 229, 0.5)',
    ...style,
  };
  return (
    <div style={containerStyle}>
      <div
        style={{
          width: 100,
          height: 100,
        }}>
        <TailSpin type={type} color={color} height={height} width={width} />
      </div>
    </div>
  );
};

ReactLoader.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.any,
};

export default ReactLoader;
