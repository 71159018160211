import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {addBuilderCases, asyncThunkHelper} from '../utils';
import {
  createShipmentZone,
  createShipmentZoneRate,
  deleteShipmentZone,
  deleteShipmentZoneRate,
  getRateConditionTypes,
  getShipmentZone,
  getShipmentZoneRateDetails,
  getShipmentZoneRates,
  getShipmentZones,
  updateShipmentZone,
  updateShipmentZoneRateDetails,
} from '../../api/helpers/shipment';

export const fetchZones = createAsyncThunk(
  'fetchZones',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: null}),
      default: async () => await getShipmentZones(),
    }),
);
export const fetchZone = createAsyncThunk(
  'fetchZone',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: null}),
      create: async () => await createShipmentZone(payload?.data),
      update: async () => await updateShipmentZone(payload?.id, payload?.data),
      delete: async () => await deleteShipmentZone(payload?.id),
      default: async () => await getShipmentZone(payload?.id),
    }),
);
export const fetchZoneRates = createAsyncThunk(
  'fetchZoneRates',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: null}),
      default: async () => await getShipmentZoneRates(payload?.id),
    }),
);
export const fetchZoneRate = createAsyncThunk(
  'fetchZoneRate',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: null}),
      create: async () =>
        await createShipmentZoneRate(payload?.id, payload?.data),
      update: async () =>
        await updateShipmentZoneRateDetails(
          payload?.id,
          payload?.rid,
          payload?.data,
        ),
      delete: async () =>
        await deleteShipmentZoneRate(payload?.id, payload?.rid),
      default: async () =>
        await getShipmentZoneRateDetails(payload?.id, payload?.rid),
    }),
);
export const fetchRateConditionTypes = createAsyncThunk(
  'fetchRateConditionTypes',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: null}),
      default: async () => await getRateConditionTypes(),
    }),
);

export const shipmentSlice = createSlice({
  name: 'shipment',
  initialState: {
    zones: {data: []},
    zone: {data: null},
    zoneRates: {data: []},
    zoneRate: {data: null},
    rateConditionTypes: {data: []},
  },
  reducers: {},
  extraReducers: builder => {
    addBuilderCases(builder, fetchZones, 'zones');
    addBuilderCases(builder, fetchZone, 'zone');
    addBuilderCases(builder, fetchZoneRates, 'zoneRates');
    addBuilderCases(builder, fetchZoneRate, 'zoneRate');
    addBuilderCases(builder, fetchRateConditionTypes, 'rateConditionTypes');
  },
});
