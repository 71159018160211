import React from 'react';
import {Link} from 'react-router-dom';
import * as S from './styled';

export const Breadcrumbs = ({data}) => {
  const list = data.map((item, index) => {
    const breadcrumbItem = item.url ? (
      <Link to={item.url}>{item.name}</Link>
    ) : (
      item.name
    );
    return (
      <div className="breadcrumb-item" key={index}>
        {breadcrumbItem}
      </div>
    );
  });
  return <S.Root className="breadcrumbs">{list}</S.Root>;
};
