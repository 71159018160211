import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {showErrorNotificationV2} from '../../utils';
import {addBuilderCases, asyncThunkHelper} from '../utils';
import {
  assignProductDesignerTemplate,
  cloneProductDesignerTemplate,
  createProductDesignerTemplate,
  createProductDesignerTemplateLayer,
  createProductDesignerTemplateOption,
  deleteProductDesignerArtifact,
  deleteProductDesignerTemplate,
  deleteProductDesignerTemplateLayer,
  deleteProductDesignerTemplateOption,
  getProductDesignerArtifacts,
  getProductDesignerTemplate,
  getProductDesignerTemplates,
  unAssignProductDesignerTemplate,
  updateProductDesignerTemplate,
  updateProductDesignerTemplateLayer,
  updateProductDesignerTemplateOption,
  uploadProductDesignerArtifact,
} from '../../api/helpers/productDesigner';
import {getProducts} from '../../api/helpers/marketing';
import {getLayerRequest} from '../../domains/Designer/helpers/templateHelper';

const updateLayerCall = async (data: any, canvasJson: any) => {
  const templateId = data?.data?.id;
  if (!templateId || !canvasJson) {
    return data;
  }
  const layers = data?.data?.layers || [];
  const defaultLayer = layers.find((i: any) => i?.name === 'Default');
  if (defaultLayer?.id) {
    return await updateProductDesignerTemplateLayer(
      templateId,
      defaultLayer?.id,
      getLayerRequest(defaultLayer, canvasJson),
    );
  } else {
    return data;
  }
};

export const fetchProductDesignerTemplates = createAsyncThunk(
  'fetchProductDesignerTemplates',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.action, {
      reset: () => ({data: []}),
      default: async () => await getProductDesignerTemplates(payload),
    }),
);

export const fetchProductDesignerTemplate = createAsyncThunk(
  'fetchProductDesignerTemplate',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.action, {
      reset: () => ({data: null}),
      default: async () => await getProductDesignerTemplate(payload?.id),
      create: async () => {
        const response = await createProductDesignerTemplate(payload?.data);
        return await updateLayerCall(response, payload?.canvasJson);
      },
      update: async () => {
        const response = await updateProductDesignerTemplate(
          payload?.id,
          payload?.data,
        );
        return await updateLayerCall(response, payload?.canvasJson);
      },
      delete: async () => await deleteProductDesignerTemplate(payload?.id),
      clone: async () =>
        await cloneProductDesignerTemplate(payload?.id, payload?.data),
      assign: async () =>
        await assignProductDesignerTemplate(payload?.id, payload?.data),
      unAssign: async () =>
        await unAssignProductDesignerTemplate(payload?.id, payload?.data),
      createLayer: async () =>
        await createProductDesignerTemplateLayer(payload?.id, payload?.data),
      updateLayer: async () =>
        await updateProductDesignerTemplateLayer(
          payload?.id,
          payload?.lid,
          payload?.data,
        ),
      deleteLayer: async () =>
        await deleteProductDesignerTemplateLayer(payload?.id, payload?.lid),
    }),
);

export const fetchProductDesignerArtifact = createAsyncThunk(
  'fetchProductDesignerArtifact',
  async (payload: any) => {
    await asyncThunkHelper(payload, payload?.action, {
      reset: () => ({data: null}),
      upload: async () => await uploadProductDesignerArtifact(payload?.data),
      delete: async () => await deleteProductDesignerArtifact(payload?.id),
    });
  },
);

export const fetchProductDesignerBackgroundArtifacts = createAsyncThunk(
  'fetchProductDesignerBackgroundArtifacts',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.action, {
      reset: () => ({data: []}),
      default: async () => await getProductDesignerArtifacts(payload?.data),
    }),
);

export const fetchProductDesignerImageArtifacts = createAsyncThunk(
  'fetchProductDesignerImageArtifacts',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.action, {
      reset: () => ({data: []}),
      default: async () => await getProductDesignerArtifacts(payload?.data),
    }),
);

export const fetchProductDesignerClipartArtifacts = createAsyncThunk(
  'fetchProductDesignerClipartArtifacts',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.action, {
      reset: () => ({data: []}),
      default: async () => await getProductDesignerArtifacts(payload?.data),
    }),
);

export const fetchProductDesignerFontArtifacts = createAsyncThunk(
  'fetchProductDesignerFontArtifacts',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.action, {
      reset: () => ({data: []}),
      default: async () => await getProductDesignerArtifacts(payload?.data),
    }),
);

export const fetchProductDesignerProducts = createAsyncThunk(
  'fetchProductDesignerProducts',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: []}),
      default: async () => await getProducts(payload?.data),
    }),
);

export const fetchProductDesignerTemplateOption = createAsyncThunk(
  'fetchProductDesignerTemplateOption',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: null}),
      upload: async () =>
        await createProductDesignerTemplateOption(payload?.id, payload?.data),
      update: async () =>
        await updateProductDesignerTemplateOption(payload?.id, payload?.data),
      delete: async () =>
        await deleteProductDesignerTemplateOption(payload?.id, payload?.oid),
    }),
);

export const productDesignerSlice = createSlice({
  name: 'productDesigner',
  initialState: {
    templates: {data: []},
    template: {data: null},
    artifacts: {data: []},
    artifact: {data: null},
    backgroundArtifacts: {data: []},
    imageArtifacts: {data: []},
    clipartArtifacts: {data: []},
    fontArtifacts: {data: []},
    products: {data: []},
    options: {data: []},
    designerPagination: {data: []},
  },
  reducers: {
    setPagination: (state, action) => {
      state.designerPagination.data = action.payload;
    },
  },
  extraReducers: (builder: any): any => {
    addBuilderCases(builder, fetchProductDesignerTemplates, 'templates');
    addBuilderCases(builder, fetchProductDesignerTemplate, 'template');
    addBuilderCases(builder, fetchProductDesignerArtifact, 'artifact');
    addBuilderCases(
      builder,
      fetchProductDesignerBackgroundArtifacts,
      'backgroundArtifacts',
    );
    addBuilderCases(
      builder,
      fetchProductDesignerImageArtifacts,
      'imageArtifacts',
    );
    addBuilderCases(builder, fetchProductDesignerProducts, 'products');
    addBuilderCases(
      builder,
      fetchProductDesignerClipartArtifacts,
      'clipartArtifacts',
    );
    addBuilderCases(builder, fetchProductDesignerTemplateOption, 'options');
    addBuilderCases(
      builder,
      fetchProductDesignerFontArtifacts,
      'fontArtifacts',
    );
  },
});

export const {setPagination} = productDesignerSlice.actions;
